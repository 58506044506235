/* eslint-disable @typescript-eslint/naming-convention */
export enum BeautyType {
	"保湿",
	"美白",
	"エイジングケア",
	"ニキビ・アクネケア",
	"敏感肌対策",
	"UV効果（SPFやPA付き）",
	"その他",
}
