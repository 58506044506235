import { SurveyChoice } from "../../../shared";
import { BeautyTypeType } from "../beauty-type";
import { EyeshaowTypeType } from "../eyeshaow-type";
import { FacewashFunctionType } from "../face-function/types";
import { FoundationTypeType } from "../fundation-type";
import { LipstickTypeType } from "../lipstick-type";
import { GypsyChoiceType } from "./choice";

export type Gypsy = {
	surveyChoice: SurveyChoice;
	beautyType?: BeautyTypeType;
	facewashFunction?: FacewashFunctionType;
	foundationType?: FoundationTypeType;
	gypsyChoices: GypsyChoiceType[];
	lipstickType?: LipstickTypeType;
	eyeshadowType?: EyeshaowTypeType;
};
