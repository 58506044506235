import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import type { AdCopyCP } from "../../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../../../server/types/error";
import { ConfirmButton } from "../../../../../../parts/confirm-dropdown-button";
import { TextRed } from "../../../../../../parts/font";
import { TextRow } from "../../../../../../parts/input-row";
import { AdCopyReportTitle } from "../../../parts/report-title";
import { AdCopyCpActualValueTableInput } from "./ad-copy-cp-actual-value-table-input";

type Props = {
	cp: Partial<AdCopyCP>;
	errors: ErrorObject | undefined;
	hasEditRole: boolean;
	job: AdCopyJob;
	onChange: <K extends keyof AdCopyCP>(key: K, value: AdCopyCP[K]) => void;
	onClear: () => void;
	onSubmit: () => void;
};

export const AdCopyCPSetting = React.memo(({ cp, errors, hasEditRole, job, onChange, onClear, onSubmit }: Props) => {
	const { actualValue, reason } = cp;

	const canSubmit = !!cp.actualValue && !!cp.reason;

	const disabled = !!cp?._id && !!cp.correctionValues?.result;

	return (
		<>
			<Row>
				<Col md={10}>
					<AdCopyReportTitle>現行品/類似品のCP実績設定</AdCopyReportTitle>
				</Col>
			</Row>

			{!!cp._id && (
				<Row style={{ marginBottom: "0.8rem" }}>
					<Col md={{ span: 2 }}>
						<ConfirmButton
							body="CP実績設定のクリアをします。よろしいでしょうか。"
							buttonText="クリア"
							onOk={onClear}
							title="設定値のクリア"
							variant="outline-secondary"
						>
							CP実績設定のクリア
						</ConfirmButton>
					</Col>
				</Row>
			)}

			<Row>
				<Col md={8}>
					{/* 現行品/類似品 の */}
					{/* 直近12か月の購入率(%) */}
					<AdCopyCpActualValueTableInput
						disabled={disabled}
						errors={errors}
						job={job}
						onChange={onChange}
						value={actualValue}
					/>

					<TextRow
						as="textarea"
						disabled={disabled}
						errors={errors}
						id="reason"
						name="reason"
						onChange={(value) => {
							onChange("reason", value);
						}}
						rowName="補整値の変更内容（期間や単位等変更した詳細を記載）"
						value={reason?.toString() ?? ""}
					/>

					<div
						className="text-end"
						style={{
							marginRight: 19.2,
						}}
					>
						<TextRed>初期値から変更した場合は入力必須</TextRed>
					</div>
				</Col>
			</Row>

			{hasEditRole && (
				<Row>
					{disabled ? (
						<Col md={{ offset: 2, span: 8 }}>
							<TextRed>値を変更する場合はクリアボタンを押して下さい。</TextRed>
						</Col>
					) : (
						<Col md={{ offset: 3, span: 2 }}>
							<Button disabled={!canSubmit} onClick={onSubmit} variant="secondary">
								CP値 更新
							</Button>
						</Col>
					)}
				</Row>
			)}
		</>
	);
});
