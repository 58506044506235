import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import type { AdCopyCP } from "../../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../../server/models/ad-copy/job";
import type { AdCopyCorrectionValue } from "../../../../../../../../../server/models/ad-copy/simulation-value";
import type { AdCopySimulationBrandData } from "../../../../../../../../../server/types/request/ad-copy/report/simulation";
import { AdCopyReportTitle, ReportTitleText } from "../../../parts/report-title";
import { AdCopyCpCorrectionValueSettingTable } from "../../correction-value/cp-correction-value-setting-table";
import { AdCopyUserTable } from "../../result/user-table";
import { AdCopyCPSalesVolumeDataTable } from "./sales-volume";

const LabelArea = styled.div`
	margin: 10px;
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 0.8rem;
`;

const SubLabelArea = styled.div`
	font-weight: bold;
`;

const TableArea = styled.div`
	table {
		margin: 13px 0;
	}
`;

type Props = {
	constValue: AdCopySimulationBrandData;
	cp: Partial<AdCopyCP>;
	hasEditRole: boolean;
	isExistingBrand: boolean;
	job: AdCopyJob;
	maxRecognition: number;
	onChangeRatio: <K extends "recognitionRate" | "recognitionRateReason">(
		name: K,
		value: AdCopyCorrectionValue[K],
	) => void;
	onSubmit: () => void;
};

export const AdCopyCPParameterSetting = React.memo(
	({ constValue, cp, hasEditRole, isExistingBrand, job, maxRecognition, onChangeRatio, onSubmit }: Props) => {
		return (
			<>
				<Row>
					<Col md={12}>
						<AdCopyReportTitle
							style={{
								marginBottom: "1rem",
							}}
						>
							現行品/類似品の商品認知率補整
						</AdCopyReportTitle>
					</Col>
				</Row>

				<Row
					style={{
						marginBottom: "30px",
					}}
				>
					<Col md={12}>
						<ReportTitleText
							className="border"
							style={{
								color: "#C00000",
								padding: "10px",
							}}
						>
							商品認知率のみ変更したい場合は、上段の＜購入率 補整値＞で「初期値から変更しない」選択して＜購入
							<br />率 更新ボタン＞を押してから、商品認知率の設定に進んでください。
						</ReportTitleText>
					</Col>
				</Row>

				{cp.cpSimulation ? (
					<>
						<Row style={{ marginBottom: "3rem" }}>
							<Col md={12}>
								<TableArea>
									<AdCopyCpCorrectionValueSettingTable
										correctionValue={cp.correctionValues ?? {}}
										hasEditRole={hasEditRole}
										initRecognition={job.currentProductRecognitionRate ?? 0}
										maxRecognition={maxRecognition}
										onChange={onChangeRatio}
									/>
								</TableArea>
							</Col>

							{hasEditRole && (
								<Col md={{ offset: 3, span: 2 }}>
									{/*block*/}
									<Button onClick={onSubmit} variant="secondary">
										推定値 算出
									</Button>
								</Col>
							)}
						</Row>

						{!!cp.correctionValues?.result && (
							<>
								<Row style={{ marginBottom: "2rem" }}>
									<Col md={12}>
										{/* category user table */}
										<AdCopyUserTable
											constValue={constValue}
											isExistingBrand={isExistingBrand}
											onsData={cp.correctionValues.ons}
											simulationReferenceData={cp.cpSimulation.test1.referenceData}
										/>
									</Col>
								</Row>

								{/* current product sales volume */}
								<Row>
									<Col md={3}>
										<SubLabelArea>テスト品1</SubLabelArea>

										<AdCopyCPSalesVolumeDataTable data={cp.correctionValues.result.test1} />
									</Col>

									{!!cp.correctionValues.result.test2 && (
										<Col md={3}>
											<SubLabelArea>テスト品2</SubLabelArea>

											<AdCopyCPSalesVolumeDataTable data={cp.correctionValues.result.test2} />
										</Col>
									)}

									{!!cp.correctionValues.result.test3 && (
										<Col md={3}>
											<SubLabelArea>テスト品3</SubLabelArea>

											<AdCopyCPSalesVolumeDataTable data={cp.correctionValues.result.test3} />
										</Col>
									)}
								</Row>
							</>
						)}
					</>
				) : (
					<Row>
						<Col md={10}>
							<LabelArea>未設定</LabelArea>
						</Col>
					</Row>
				)}
			</>
		);
	},
);
