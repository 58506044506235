import * as React from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { useDisclosure } from "../../../../../hooks/use-disclosure";
import { Title } from "../../../../parts/title";
import { CurrentProductAlertModal } from "./current-product-alert-modal";
import { EvaluationItemsForm } from "./evaluation-items-form";
import { JobForm } from "./job-form";

const IndentArea = styled.div`
	margin-left: 25px;
`;

type Props = {
	adCopyCanDemandForecastNeeded: boolean;
	cantEditMessage: string | undefined;
	errors: ErrorObject | undefined;
	evaluationItem: string[];
	job: Partial<AdCopyJob>;
	needCurrentProduct: boolean;
	onChangeEvaluationItem: (items: string[]) => void;
	onChangeJob: <T extends keyof AdCopyJob>(name: T, value: AdCopyJob[T]) => void;
	onSubmit: () => void;
};

export const AdCopyJobCreatePage = React.memo((props: Props) => {
	const {
		adCopyCanDemandForecastNeeded,
		cantEditMessage,
		errors,
		evaluationItem,
		job,
		needCurrentProduct,
		onChangeEvaluationItem,
		onChangeJob,
		onSubmit,
	} = props;

	const isRetest = !!job.retestJobId;

	const currentProductAlertModalDisclosure = useDisclosure();

	const handleSubmit = () => {
		// 需要予測あり＆現行品設定なし、で設定されたケースが発生した場合は、
		// システム側で「現行品が設定されていないため、現行品実績による補整は需要予測に反映されません。現行品は設定しなくてよいですか？」（アラート文は仮）
		// といったポップアップを入れるなど確認ステップを挟む
		if (!isRetest && job.isDemandForecastNeeded && job.hasCurrentProduct != null && !job.hasCurrentProduct) {
			currentProductAlertModalDisclosure.open();
		} else {
			onSubmit();
		}
	};

	return (
		<>
			<CurrentProductAlertModal
				onClose={currentProductAlertModalDisclosure.close}
				onSubmit={onSubmit}
				show={currentProductAlertModalDisclosure.isOpen}
			/>

			{!!cantEditMessage && (
				<div className="m-3">
					<Alert variant="danger">{cantEditMessage}</Alert>
				</div>
			)}

			<div className="m-3">
				<div className="h3">■{job._id ? "ジョブ更新" : isRetest ? "再調査登録" : "ジョブ新規登録"}</div>
			</div>

			<div className="m-3">
				<Row>
					<Col md={12}>
						<Title>基本設定</Title>
					</Col>
				</Row>

				<IndentArea>
					<Row>
						<Col md={12}>
							<JobForm
								adCopyCanDemandForecastNeeded={adCopyCanDemandForecastNeeded}
								errors={errors?.["job"] && typeof errors["job"] === "object" ? errors["job"] : undefined}
								job={job}
								needCurrentProduct={needCurrentProduct}
								onChange={onChangeJob}
							/>
						</Col>
					</Row>
				</IndentArea>

				<Row>
					<Col md={12}>
						<Title>カスタム設定</Title>
					</Col>
				</Row>

				<IndentArea>
					<Row>
						<Col md={12}>
							<EvaluationItemsForm
								//
								errors={errors}
								evaluationItem={evaluationItem}
								onChange={onChangeEvaluationItem}
							/>
						</Col>
					</Row>
				</IndentArea>

				<Row>
					<Col
						md={{
							offset: 4,
							span: 2,
						}}
					>
						{cantEditMessage ? (
							<Button className="w-100" disabled variant="secondary">
								{job._id ? "更新" : "登録"}できません
							</Button>
						) : (
							<Button className="w-100" onClick={handleSubmit} variant="secondary">
								{job._id ? "更新" : "登録"}
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</>
	);
});
