import { SkinAttributeV2 } from "./v2";

export const skinAttributeOptions = Object.entries(SkinAttributeV2)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const skinAttributeExcludeOtherOptions = skinAttributeOptions.filter(({ label }) => {
	return label !== "その他";
});
