import * as React from "react";

export const GroupPanel = React.memo((props: { label: string; value: string }) => {
	const { label, value } = props;
	return (
		<div>
			・{label}：{value}{" "}
		</div>
	);
});
