import * as React from "react";

import type { EvaluationValues } from "../../../../../../../../server/ad-copy-analytics/simulation/lib/evaluations";
import type { AdCopyConceptKey } from "../../../../../../../../server/models/ad-copy/concept";
import { AdCopyConceptTypeMap } from "../../../../../../../../server/models/ad-copy/concept";

type AdCopyNormResult = {
	differentiation: number | "-";
	ons: number | "-";
};

const fixed = (input: number | string, decimalPoint = 1) => {
	if (typeof input === "string") {
		return input;
	}

	return input.toFixed(decimalPoint);
};

type Props = {
	evaluationValues: EvaluationValues | undefined;
	targetConcept: AdCopyConceptKey;
};

export const AdCopyNormRaw = React.memo((props: Props) => {
	const { evaluationValues, targetConcept } = props;

	const strategyResult: AdCopyNormResult = {
		differentiation: evaluationValues?.differentiation ?? "-",
		ons: evaluationValues?.ons ?? "-",
	};

	return (
		<tr>
			<td className="label-area">{AdCopyConceptTypeMap[targetConcept]}</td>

			{/* 戦略ターゲット */}

			{/* n数 */}
			<td>{evaluationValues?.n ?? "-"}</td>

			{/* 購入意向ワンナンバースコア(%) */}
			<td>{fixed(strategyResult.ons)}</td>

			{/* リフト */}
			<td className={typeof strategyResult.ons !== "string" && strategyResult.ons < 0 ? "minus" : "plus"}>
				{fixed(evaluationValues?.lift ?? "-")}
			</td>

			{/* 差別性（他の商品とは違っている） */}
			<td>{fixed(strategyResult.differentiation, 2)}</td>

			{/* 情報検索意向（この商品についてもっと知りたい） */}
			<td>{fixed(evaluationValues?.informationSearchIntent ?? "-", 2)}</td>

			{/* 自分向け（自分向けの商品だと思う） */}
			<td>{fixed(evaluationValues?.forSelf ?? "-", 2)}</td>
		</tr>
	);
});
