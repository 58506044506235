import { Age } from "../../shared";
import { FragranceType } from "./fregrance";
import { FoundationTypeType } from "./fundation-type";
import { MarriedType } from "./married";
import { SensitiveSkinType } from "./sensitive-skin";
import { StrategyGroupCategory } from "./category";
import { SkinAttributeType } from "./skin-attribute";
import { ChannelType } from "./channel";
import { ChildrenType } from "./children";
import { OccupationType } from "./occupation";
import { CategoryOrAndType } from "./category-and-or";
import { FacewashFunctionType } from "./face-function/types";
import { SkinProblemFrequencyType } from "./skin-problem-frequency/types";
import { SensitiveSkinFrequencyRankType } from "./sensitive-skin-frequency-rank";
import { NonPurchaseCategoryConfig } from "./none-purchase-category";
import { LipstickTypeType } from "./lipstick-type";
import { EyeshaowTypeType } from "./eyeshaow-type";
import { Gypsy } from "./gipsy";
import { AestheticMedicineExperienceType } from "./aesthetic-medicine-experience";

export type StrategyGroup = {
	age: Age;
	married: MarriedType[]; //未既婚
	children: ChildrenType[]; //子ども有無
	occupation: OccupationType[]; //職業
	skinAttribute: SkinAttributeType[]; //肌悩み
	sensitiveSkin: SensitiveSkinType[]; //敏感肌

	categoryOrAnd: CategoryOrAndType;
	category: StrategyGroupCategory[];
	channel: ChannelType[];
	fragrance?: FragranceType[];
	foundationType?: FoundationTypeType[];
	facewashFunction?: FacewashFunctionType;

	/** 過去一年非購入カテゴリ設定 */
	nonPurchaseCategoryConfig?: NonPurchaseCategoryConfig;

	sensitiveSkinFrequency?: SensitiveSkinFrequencyRankType[]; // 敏感肌頻度（敏感肌/ときどき敏感肌のみ）
	skinProblemFrequency?: SkinProblemFrequencyType[]; // 肌トラブル

	/** 口紅・リップグロス使用カテゴリ */
	lipstickType?: LipstickTypeType[];
	/** アイシャドー使用カテゴリ */
	eyeshadowType?: EyeshaowTypeType[];
	/** ジプシー */
	gypsy?: Gypsy;
	/** 美容医療利用経験 */
	aestheticMedicineExperience?: AestheticMedicineExperienceType[];
};
