import * as React from "react";

import type { AdCopyCorrectionValue } from "../../../../../../../../server/models/ad-copy/simulation-value";
import { Help } from "../../../../../parts/help";
import { AdCopyCpRecognitionRateValueRow } from "./cp-recognition-rate-value-row";

type Props = {
	correctionValue: AdCopyCorrectionValue;
	hasEditRole: boolean;
	initRecognition: number;
	maxRecognition: number;
	onChange: <K extends "recognitionRate" | "recognitionRateReason">(name: K, value: AdCopyCorrectionValue[K]) => void;
};

// CP実績補整タブ
// 現行品/類似品の変数補整
// 補整変数テーブル
// 商品認知率のみを残す

export const AdCopyCpCorrectionValueSettingTable = React.memo((props: Props) => {
	const { correctionValue, hasEditRole, initRecognition, maxRecognition, onChange } = props;

	return (
		<table className="result-table simulation">
			<thead>
				<tr>
					<th className="subtitle">補整変数</th>

					<th>
						初期値
						<Help explain="初期値は、表示されていない小数点2位以下の数値も計算には参照しています。" id="initial" />
					</th>

					<th>
						補整値
						<Help
							explain="初期値から変更しない場合は、手入力ではなく、必ず「初期値から変更しない」ボタンを押してください。"
							id="correctionValue"
						/>
					</th>

					<th>補整率（％）</th>

					<th>補整根拠（初期値から変更した場合、入力必須）</th>
				</tr>
			</thead>

			<tbody>
				{/* 商品認知率(%) */}
				<AdCopyCpRecognitionRateValueRow
					hasEditRole={hasEditRole}
					init={initRecognition}
					maxRecognition={maxRecognition}
					onChangeReason={(v) => {
						onChange("recognitionRateReason", v);
					}}
					onChangeValue={(v) => {
						onChange("recognitionRate", v);
					}}
					reason={correctionValue.recognitionRateReason}
					value={correctionValue.recognitionRate}
				/>
			</tbody>
		</table>
	);
});
