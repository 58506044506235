import * as React from "react";

import { decimalCalc } from "../../../../../../../../../server/lib/ad-copy/aggregate/common";
import type { AdCopyCpCorrectedResult } from "../../../../../../../../../server/models/ad-copy/cp";

type Props = {
	data: AdCopyCpCorrectedResult;
};

export const AdCopyCPSalesVolumeDataTable = React.memo(({ data }: Props) => {
	return (
		<>
			<table className="result-table">
				<thead>
					<tr>
						<th className="subtitle">固定変数</th>

						<th>推定値(万個)</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td className="trial">トライアル</td>

						<td>{decimalCalc(data.trial, 10).toFixed(1)}</td>
					</tr>

					<tr>
						<td className="repeat">リピート</td>

						<td>{decimalCalc(data.repeat, 10).toFixed(1)}</td>
					</tr>

					<tr>
						<td className="localOther">ローカルその他</td>

						<td>{decimalCalc(data.localOther, 10).toFixed(1)}</td>
					</tr>

					<tr>
						<td className="inbound">インバウンド</td>

						<td>{decimalCalc(data.inbound, 10).toFixed(1)}</td>
					</tr>

					<tr>
						<td className="total">Total</td>

						<td>{decimalCalc(data.total, 10).toFixed(1)}</td>
					</tr>
				</tbody>
			</table>

			<table className="result-table">
				<tr>
					<td className="label-area">補正係数</td>

					<td>{decimalCalc(data.correctionValue, Math.pow(10, 3)).toFixed(3)}</td>
				</tr>
			</table>
		</>
	);
});
