/* eslint-disable @typescript-eslint/naming-convention */

/**
 * enum index
 *  0: 頻度高：週に1回以上
 *  1: 頻度中：月に1-2回
 * 2: 頻度低：それ以下
 */
export enum SensitiveSkinFrequencyRank {
	"頻度高：週に1回以上",
	"頻度中：月に1-2回",
	"頻度低：それ以下",
}
