/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

import { makeAdCopyStrategyGroupText } from "../../../../../../server/lib/ad-copy/enquete/quota/helpers";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyConcept } from "../../../../../../server/models/ad-copy/concept";
import { type AdCopyJob, AdCopySurveyMethod } from "../../../../../../server/models/ad-copy/job";
import { composeTargetCategoryBrandsString } from "../../../../lib/ad-copy/activity";
import { AdCopyContains } from "./contains";

const TextWrap = styled.div`
	white-space: pre-wrap;
`;

type Props = {
	activity: AdCopyActivityWithBrand;
	concepts: AdCopyConcept[];
	estimate: string | undefined;
	evaluationItem?: string[];
	job: AdCopyJob;
};

export const AdCopyJobDetail = React.memo((props: Props) => {
	const { activity, concepts, estimate, evaluationItem, job } = props;

	const { category, strategyTargetGroup } = activity;

	const targetCategoryBrandsString = React.useMemo(() => composeTargetCategoryBrandsString(activity), [activity]);

	return (
		<>
			<Row className="m-3">
				<Col md={12}>
					<div className="h4">■アクティビティ情報</div>

					<AdCopyContains field="ブランド：">{activity.brand.name}</AdCopyContains>

					{activity.brandName != null && <AdCopyContains field="ブランド名：">{activity.brandName}</AdCopyContains>}

					<AdCopyContains field="カテゴリ：">{category.name}</AdCopyContains>

					{targetCategoryBrandsString != null && (
						<AdCopyContains field="ターゲットカテゴリブランド：">{targetCategoryBrandsString}</AdCopyContains>
					)}

					<AdCopyContains field="戦略ターゲットグループ：">
						<TextWrap>{makeAdCopyStrategyGroupText(strategyTargetGroup, category.gender)}</TextWrap>
					</AdCopyContains>

					<AdCopyContains field="発売年月：">
						{activity.releaseYear}年 {activity.releaseMonth}月
					</AdCopyContains>

					<AdCopyContains
						field={
							<>
								日本人（当該性の）15～69歳における
								<br />
								想定商品認知率(%)：
							</>
						}
					>
						{activity.goalRecognitionRate}%
					</AdCopyContains>

					<AdCopyContains
						field={
							<>
								発売後12か月の目標お客さま購買
								<br />
								（万個）：
							</>
						}
					>
						{activity.goalSalesVolume}
					</AdCopyContains>
				</Col>
			</Row>

			<Row className="m-3">
				<Col md={12}>
					<div className="h4">■job情報</div>

					<AdCopyContains field="調査開始日時：">{job.startDatetime}</AdCopyContains>

					<AdCopyContains field="調査終了日時：">{job.endDatetime}</AdCopyContains>

					<AdCopyContains field="概算見積もり金額：">
						<div>
							{estimate?.split("\n").map((text, index) => (
								<div key={`estimate-${index}`} style={index >= 2 ? { color: "red", fontWeight: "bold" } : {}}>
									{text}
								</div>
							))}
						</div>
					</AdCopyContains>

					<AdCopyContains field="調査手法：">{AdCopySurveyMethod[job.method]}</AdCopyContains>

					<AdCopyContains field="現行品の有無：">{job.hasCurrentProduct ? "あり" : "なし"}</AdCopyContains>

					<AdCopyContains field="競合品の有無：">{job.hasBenchmark ? "あり" : "なし"}</AdCopyContains>

					<AdCopyContains field="キービジュアル（KV）の有無：">{job.hasKeyVisual ? "あり" : "なし"}</AdCopyContains>

					<AdCopyContains field="需要予測の有無：">{job.isDemandForecastNeeded ? "あり" : "なし"}</AdCopyContains>

					{job.currentProductRecognitionRate != null && (
						<AdCopyContains field="現行品の想定認知率：">{job.currentProductRecognitionRate}%</AdCopyContains>
					)}

					{job.currentProductPurchaseRate != null && (
						<AdCopyContains field="現行品の直近12か月の購入率：">{job.currentProductPurchaseRate}%</AdCopyContains>
					)}

					{job.currentProductPurchaseRateReason != null && (
						<AdCopyContains field="現行品の購入率(%)の定義：">{job.currentProductPurchaseRateReason}</AdCopyContains>
					)}

					<AdCopyContains field="要素評価設問項目：">
						{evaluationItem && evaluationItem.length
							? evaluationItem.map((item, index) => <div key={`evaluationItem-${index}`}>{item}</div>)
							: "設定なし"}
					</AdCopyContains>

					{concepts.map((concept) => (
						<React.Fragment key={`concept-${concept.type}`}>
							<AdCopyContains field="呈示素材画像：">
								<img className="border" src={concept.imageUrl} style={{ maxWidth: "500px" }} />
							</AdCopyContains>

							<AdCopyContains field="コピーの魅力度・印象度：">
								<AdCopyContains field="メインコピー：">
									<div style={{ whiteSpace: "pre" }}>{concept.catchCopy}</div>
								</AdCopyContains>

								{concept.bodyCopies
									?.filter((s) => !!s)
									.map((s, i) => (
										<AdCopyContains key={i} field={`その他コピー ${i + 1}：`}>
											<div style={{ whiteSpace: "pre" }}>{s}</div>
										</AdCopyContains>
									))}
							</AdCopyContains>

							<AdCopyContains field="SKU：">
								{concept.sku.map((sku, i) => (
									<AdCopyContains key={i} field={`${sku.name}：`}>
										<div style={{ whiteSpace: "pre" }}>
											{sku.price}円（税抜）／{sku.includeTaxPrice}円（税込）
										</div>
									</AdCopyContains>
								))}
							</AdCopyContains>
						</React.Fragment>
					))}
				</Col>
			</Row>
		</>
	);
});
