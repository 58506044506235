import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { replaceBreakAndSpace } from "../../../../../../server/lib/common";
import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { replaceEndpointUrl } from "../../../../../../server/router/endpoint";
import type { GetAdCopyJobResponse, UpsertAdCopyJobResponse } from "../../../../../../server/types/request/ad-copy/job";
import { AdCopyJobCreatePage } from "../../../../components/pages/ad-copy/job/create";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { Prompt } from "../../../../components/parts/prompt";
import type { SwitchConfirmEvent } from "../../../../components/parts/switch-confirm";
import { SwitchConfirm } from "../../../../components/parts/switch-confirm";
import { get, makeError, post, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/create";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";

export const AdCopyJobCreateContariner = () => {
	const { activityId, jobId, retestJobId } = useParams<{
		activityId: string;
		jobId: string;
		retestJobId: string;
	}>();

	const [state, dispatch] = React.useReducer(reducer, {
		...initState,
		job: {
			...initState.job,
			...(retestJobId
				? {
						hasBenchmark: false,
						hasCurrentProduct: false,
						hasKeyVisual: false,
						hasSimilarProduct: false,
						isDemandForecastNeeded: false,
						retestJobId,
				  }
				: {}),
		},
	});

	const {
		adCopyCanDemandForecastNeeded,
		cantEditMessage,
		evaluationItem,
		info,
		job,
		loading,
		needCurrentProduct,
		prompt,
	} = state;

	// TODO: 後で消す
	console.log({ state });

	const navigate = useNavigate();

	const onChangeJob = React.useCallback(<T extends keyof AdCopyJob>(name: T, value: AdCopyJob[T]) => {
		dispatch({
			payload: {
				name,
				value,
			},
			type: "changeJob",
		});
	}, []);

	const onChangeEvaluationItem = React.useCallback((items: string[]) => {
		dispatch({
			payload: items,
			type: "changeEvaluationItem",
		});
	}, []);

	React.useEffect(() => {
		get<GetAdCopyJobResponse>(
			`${replaceEndpointUrl(adCopyEndpoint.job, {
				activityId: activityId ?? "",
			})}/${jobId || "new"}`,
			retestJobId
				? {
						retestJobId,
				  }
				: undefined,
		)
			.then((response) => {
				dispatch({
					payload: response.data,
					type: "loadJob",
				});
			})
			.catch((error) => {
				dispatch({
					payload: makeError(error),
					type: "changeMessageInfo",
				});
			});
	}, [activityId, jobId, retestJobId]);

	const onSubmit = React.useCallback(() => {
		dispatch({
			payload: true,
			type: "changeLoading",
		});

		const data = {
			evaluationItem: evaluationItem.filter((item) => !!item && 0 < replaceBreakAndSpace(item).length),
			job,
		};

		// 作成後のURLもnewのままだが、activityがsetされると_idがつくので、参照するようにする。
		const id = job._id ? job._id.toString() : jobId;

		const request =
			id === "new" || !!retestJobId
				? post<UpsertAdCopyJobResponse>(
						`${replaceEndpointUrl(adCopyEndpoint.job, {
							activityId: activityId ?? "",
						})}`,
						data,
				  )
				: put<UpsertAdCopyJobResponse>(
						`${replaceEndpointUrl(adCopyEndpoint.job, {
							activityId: activityId ?? "",
						})}/${id}`,
						data,
				  );

		request
			.then((response) => {
				dispatch({
					payload: response.data,
					type: "updateJob",
				});
			})
			.catch((error) => {
				dispatch({
					payload: makeError(error),
					type: "changeMessageInfo",
				});
			});
	}, [activityId, evaluationItem, job, jobId, retestJobId]);

	const navigateJobPage = React.useCallback(() => {
		navigate(
			replaceEndpointUrl(adCopyClientEndpoint.job, {
				activityId: activityId ?? "",
			}),
		);
	}, [activityId, navigate]);

	const navigateConceptPage = React.useCallback(() => {
		navigate(
			replaceEndpointUrl(adCopyClientEndpoint.concept, {
				activityId: activityId ?? "",
				jobId: job._id?.toString() ?? "",
			}),
		);
	}, [activityId, job._id, navigate]);

	const events = React.useMemo<SwitchConfirmEvent[]>(
		() => [
			{
				func: navigateJobPage,
				label: "ジョブ一覧に戻る",
				variant: "outline-secondary",
			},
			{
				func: navigateConceptPage,
				label: "コピー設定へ",
				variant: "secondary",
			},
		],
		[navigateConceptPage, navigateJobPage],
	);

	return (
		<>
			<Prompt message="変更が保存されていません。ページを移動してよろしいですか？" when={prompt && !loading} />

			<Loading loading={loading} text="processing..." />

			{!info.isSuccess && <ConfirmBox info={info} />}

			<SwitchConfirm
				body={info.message}
				events={events}
				show={info.isSuccess && !!info.message}
				title={`ジョブの${!jobId || jobId === "new" ? "新規作成" : "更新"}`}
			/>

			<AdCopyJobCreatePage
				adCopyCanDemandForecastNeeded={adCopyCanDemandForecastNeeded}
				cantEditMessage={cantEditMessage}
				errors={info.errors}
				evaluationItem={evaluationItem}
				job={job}
				needCurrentProduct={needCurrentProduct}
				onChangeEvaluationItem={onChangeEvaluationItem}
				onChangeJob={onChangeJob}
				onSubmit={onSubmit}
			/>
		</>
	);
};
