import * as React from "react";
import { Table } from "react-bootstrap";

import type { AdCopyConceptWithInformation } from "../../../../../server/models/ad-copy/concept";
import type { ConceptNormUpdate, ConceptWithInformation } from "../../../../../server/models/concept";
import { NormFlagsTableRow } from "./norm-flags-table-row";

const thStyle = {
	backgroundColor: "#F2F2F2",
} as const satisfies React.CSSProperties;

type Props = {
	concepts: (AdCopyConceptWithInformation | ConceptWithInformation)[];
	onSave: ({ conceptId, data }: { conceptId: string; data: ConceptNormUpdate }) => void;
};

export const NormFlagsTable = React.memo((props: Props) => {
	const { concepts, onSave } = props;

	return (
		<Table bordered>
			<thead>
				<tr className="align-middle text-center">
					<th
						style={{
							...thStyle,
							minWidth: "4rem",
						}}
					>
						アクティビティNo.
					</th>

					<th style={thStyle}>ジョブNo.</th>

					<th style={thStyle}>調査終了日</th>

					<th
						style={{
							...thStyle,
							minWidth: "5rem",
						}}
					>
						再調査
					</th>

					<th
						style={{
							...thStyle,
							minWidth: "6rem",
						}}
					>
						商品
					</th>

					<th style={thStyle}>カテゴリ</th>

					<th style={thStyle}>ブランド</th>

					<th
						style={{
							...thStyle,
							minWidth: "5rem",
						}}
					>
						ノーム
						<br />
						対象
					</th>

					<th
						style={{
							...thStyle,
							minWidth: "5rem",
						}}
					>
						ノーム
						<br />
						対象外
					</th>

					<th
						style={{
							...thStyle,
							minWidth: "8rem",
						}}
					>
						理由
					</th>

					<th style={thStyle}>保存</th>
				</tr>
			</thead>

			<tbody>
				{concepts.map((concept) => (
					<NormFlagsTableRow
						//
						key={concept._id?.toString()}
						concept={concept}
						onSave={onSave}
					/>
				))}
			</tbody>
		</Table>
	);
});
