/* eslint-disable @typescript-eslint/naming-convention */

/**
 * releaseから利用している情報
 */
export enum SkinAttribute {
	"シミ・ソバカス",
	"くすみ",
	"透明感のなさ",
	"頬の赤み",
	"色むら",
	"目の下のくま",
	"しわ",
	"肌のたるみ",
	"毛穴の目立ち",
	"かさつき・うるおいのなさ",
	"つやのなさ",
	"きめの粗さ",
	"肌荒れ",
	"ニキビ・吹き出物",
	"脂っぽさ・べたつき",
	"アトピー/肌のアレルギー",
	"花粉 / PM2.5 / ハウスダストなどによる肌荒れ",
	"その他",
}
