import { AdCopyConceptKey } from "../concept";
import { AggregateTargetGroupForONS, AxisNameTypeForONS } from "./questionData";

export type OneNumberScoreData = {
	axisName: AxisNameTypeForONS;
	conceptType: AdCopyConceptKey;
	score: number;
	target: AggregateTargetGroupForONS;
	quename: string;
};
