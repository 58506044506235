export enum Grp {
	moreThan1000 = "1000GRP以上",
	lessThan1000 = "1000GRP未満",
	none = "なし",
	undecided = "未定",
}
export const grpOptions = Object.entries(Grp)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});
