import * as React from "react";
import styled from "styled-components";

export const AdCopySampleSizeTable = styled.table`
	tr td:not(:last-child),
	tr th:not(:last-child) {
		border-right: 1px solid #999;
	}
	td,
	th {
		padding: 2px 10px;
		border-bottom: 1px solid #999;
		text-align: right;
		min-width: 80px;
	}
	th.names-area {
		text-align: center;
	}
	td.names-area {
		text-align: left;
	}
	td.missing-sample {
		color: red;
		font-weight: bold;
	}
`;

export const AdCopyTargetPanelRow = React.memo((props: { label: React.ReactNode; value: React.ReactNode }) => {
	const { label, value } = props;

	return (
		<tr className="panel-category">
			<td>□</td>

			<td>{label}</td>

			<td className="colon">：</td>

			<td>{value}</td>
		</tr>
	);
});
