import * as React from "react";
import styled from "styled-components";

const EventButton = styled.div`
	padding: 5px;
	margin: 3px 18px 3px 3px;
	background-color: #ddd;
	border-radius: 10px;
	display: inline-block;
	width: 150px;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 0px;
	font-size: 12px;
	border-radius: 18px;
	width: 18px;
	height: 18px;
	color: white;
	background-color: black;
`;

type Event = {
	event: () => void;
	label: string;
};

type Props = {
	events: Event[];
	onClose: () => void;
};

export const AdCopySelectableButton = React.memo((props: Props) => {
	const { events, onClose } = props;

	return (
		<>
			<CloseButton onClick={onClose}>×</CloseButton>

			{events.map(({ event, label }, index) => (
				<EventButton key={`event-${index}`} onClick={event}>
					{label}
				</EventButton>
			))}
		</>
	);
});
