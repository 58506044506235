import * as React from "react";
import { FormControl } from "react-bootstrap";

import { decimalCalc } from "../../../../../../../../server/lib/ad-copy/aggregate/common";
import { calcDiffPercent } from "../common";
import { AdCopyEditableText } from "../editable-text";

const calc = (value: number | undefined, isPercent?: boolean) => {
	if (typeof value !== "number") {
		return;
	}

	if (isPercent) {
		return decimalCalc(value * 100, 10);
	}

	return decimalCalc(value, 10);
};

type Props = {
	hasEditRole: boolean;
	init: number;
	isPercent?: boolean;
	label: JSX.Element | string;
	min?: number;
	onChangeReason: (value: string) => void;
	onChangeValue: (value: number | undefined) => void;
	reason: string | undefined;
	step?: number | string;
	value: number | undefined;
};

export const AdCopyValueRow = React.memo((props: Props) => {
	const {
		hasEditRole,
		init,
		isPercent,
		label,
		min = 0,
		onChangeReason,
		onChangeValue,
		reason,
		step = "0.1",
		value,
	} = props;

	const onChangeReasonValue = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const value = e.target.value;

			onChangeReason(value.slice(0, 90));
		},
		[onChangeReason],
	);

	const diffPercent = React.useMemo(() => {
		if (value == null) {
			return "-";
		}

		return calcDiffPercent(init, value);
	}, [init, value]);

	return (
		<tr>
			<td className="label-area">{label}</td>

			{/* 初期値 */}
			<td>{calc(init, isPercent)?.toFixed(1)}</td>

			{/* 補整値 */}
			<td>
				{hasEditRole ? (
					<AdCopyEditableText
						initValue={init}
						isPercent={isPercent}
						min={min}
						onChange={onChangeValue}
						step={step}
						value={value}
					/>
				) : (
					calc(value, isPercent)?.toFixed(1) ?? "-"
				)}
			</td>

			{/* 補整率（％） */}
			<td className={diffPercent !== "-" && diffPercent < 0 ? "minus" : "plus"}>
				{diffPercent === "-" ? diffPercent : diffPercent.toFixed(1)}
			</td>

			{/* 補整根拠 */}
			<td className="reason-area">
				<FormControl as="textarea" onChange={onChangeReasonValue} readOnly={!hasEditRole} value={reason} />
			</td>
		</tr>
	);
});
