/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";

import { decimalCalc } from "../../../../../../../../server/lib/ad-copy/aggregate/common";
import { AdCopySelectableButton } from "./selectable-button";

const calc = (value: number, isPercent?: boolean) => {
	if (typeof value !== "number") {
		return;
	}

	if (isPercent) {
		return decimalCalc(value * 100, 10);
	}

	return decimalCalc(value, 10);
};

const WrapArea = styled.div`
	position: relative;
	text-align: center;
	padding: 2px;
	border: 1px solid #ddd;
`;

const ButtonArea = styled.div`
	position: absolute;
	background-color: white;
	padding: 2px;
	top: -20px;
	left: 90px;
	background-color: white;
	z-index: 20;
	border: 1px solid #ddd;
`;

type Props = {
	initValue: number;
	isPercent?: boolean;
	min?: number;
	onChange: (value: number | undefined) => void;
	step: number | string;
	value: number | undefined;
};

export const AdCopyEditableText = React.memo((props: Props) => {
	const { initValue, isPercent, min = 0, onChange, step, value } = props;

	const [focus, setFocus] = React.useState(false);
	const [target, setTarget] = React.useState(false);

	const [viewValue, setViewValue] = React.useState(value == null ? "-" : calc(value, isPercent)?.toFixed(1));

	const onChangeValue = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const vv = e.target.value;

			setViewValue(vv);
			const num = Number(vv);

			if (vv === "" || isNaN(num)) {
				onChange(undefined);

				return;
			}

			const roundValue = decimalCalc(num, 10);
			const v = isPercent ? roundValue / 100 : roundValue;

			onChange(v);
		},
		[isPercent, onChange],
	);

	const onBlur = React.useCallback(() => {
		const num = Number(viewValue);

		setViewValue(viewValue !== "" || isNaN(num) ? num.toFixed(1) : "-");
		setFocus(false);
	}, [viewValue]);

	const events = React.useMemo(
		() => [
			{
				event: () => {
					setTarget(false);
					setFocus(true);
				},
				label: "補整する",
			},
			{
				event: () => {
					setTarget(false);
					const v = isPercent ? initValue * 100 : initValue;

					setViewValue(v?.toFixed(1));
					onChange(initValue);
				},
				label: "初期値から変更しない",
			},
		],
		[onChange, initValue, isPercent],
	);

	if (!focus) {
		return (
			<WrapArea
				onClick={() => {
					if (!target) {
						setTarget(true);
					}
				}}
				style={
					viewValue === "-"
						? {
								backgroundColor: "#ddd",
						  }
						: {}
				}
			>
				{target && (
					<ButtonArea>
						<AdCopySelectableButton
							events={events}
							onClose={() => {
								setTarget(false);
							}}
						/>
					</ButtonArea>
				)}

				<>{viewValue}</>
			</WrapArea>
		);
	}

	return (
		<FormControl
			//
			min={min}
			onBlur={onBlur}
			onChange={onChangeValue}
			step={step}
			type="number"
			value={viewValue}
		/>
	);
});
