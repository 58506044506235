/* eslint-disable react/jsx-newline */

import * as React from "react";
import styled from "styled-components";

import type { TabSetting } from "../../../../../../../react-components/lu-component/src/index";
import { TabViewer } from "../../../../../../../react-components/lu-component/src/index";
import { AdCopyRole } from "../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyTestOnlyConceptKey } from "../../../../../../../server/models/ad-copy/concept";
import type { AdCopyCorrectionValue } from "../../../../../../../server/models/ad-copy/simulation-value";
import type { ErrorObject } from "../../../../../../../server/types/error";
import type { GetAdCopySimulationResponse } from "../../../../../../../server/types/request/ad-copy/report/simulation";
import { AdCopyReportBigTitle } from "../parts/report-title";
import type { AdCopyCPPageProps } from "./cp";
import { AdCopyCPPage } from "./cp";
import { AdCopySimulationOne } from "./simulation-one";

const AreaWrap = styled.div`
	width: 1500px;
	margin: 0px auto;
`;

const AlertRed = styled.span`
	display: inline-block;
	color: red;
	font-size: 12px;
`;

export type AdCopySimulationPageProps = GetAdCopySimulationResponse & {
	confirmMessage: string | undefined;
	errors: ErrorObject | undefined;
	loginAccount: AccountWithoutPassword;
	onChangeCP: AdCopyCPPageProps["onChange"];
	onChangeRatio: <K extends keyof AdCopyCorrectionValue>(
		target: AdCopyTestOnlyConceptKey,
		name: K,
		value: AdCopyCorrectionValue[K],
	) => void;
	onClearCP: () => void;
	onSimulation: (conceptType: AdCopyTestOnlyConceptKey) => void;
	onSimulationSave: (conceptType: AdCopyTestOnlyConceptKey) => void;
	onSubmitCP: AdCopyCPPageProps["onSubmit"];
	onSubmitCPCorrectionValues: AdCopyCPPageProps["onSubmitCPCorrectionValues"];
	refetch: () => void;
	setChanged: (changed: boolean) => void;
};

export const AdCopySimulationPage = React.memo((props: AdCopySimulationPageProps) => {
	const {
		activity,
		appearanceByStrategyTarget,
		confirmMessage,
		constValue,
		cp,
		errors,
		evaluation,
		goalSalesVolume,
		hasCurrentProduct,
		job,
		loginAccount,
		maxRecognition,
		onChangeCP,
		onChangeRatio,
		onClearCP,
		onSimulation,
		onSimulationSave,
		onSubmitCP,
		onSubmitCPCorrectionValues,
		onsByPurchaseIntent,
		refetch,
		setChanged,
		simulationPredictionData,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	} = props;

	const { test1, test2, test3 } = simulationPredictionData;

	const hasEditRole = React.useMemo(
		() =>
			// 管理者 or ブランドメンバー or ブランドマネージャーは対象
			AdCopyRole.isAdmin(loginAccount) ||
			AdCopyRole.isActivityMember(loginAccount, activity) ||
			AdCopyRole.isActivityBrandManager(loginAccount, activity),
		[activity, loginAccount],
	);

	const canChangeCP = job != null;

	const tabs = React.useMemo<TabSetting[]>(() => {
		const tmp: TabSetting[] = [];
		// 現行品がある場合のみ設定可能

		if (test1) {
			tmp.push({
				children: (
					<AdCopySimulationOne
						activity={activity}
						appearanceByStrategyTarget={appearanceByStrategyTarget}
						constValue={constValue}
						cp={cp}
						data={test1}
						evaluationResult={evaluation.test1}
						goalSalesVolume={goalSalesVolume}
						hasEditRole={hasEditRole}
						job={job}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => {
							onChangeRatio("test1", name, value);
						}}
						onSimulation={() => {
							onSimulation("test1");
						}}
						onSimulationSave={() => {
							onSimulationSave("test1");
						}}
						onsData={onsByPurchaseIntent.test1}
						targetConceptType="test1"
						trialSalesVolumeBeforeCorrectionByCurrentProduct={trialSalesVolumeBeforeCorrectionByCurrentProduct}
					/>
				),
				key: "test1",
				title: "テスト品1",
			});
		}

		if (test2 && evaluation.test2 && onsByPurchaseIntent.test2) {
			tmp.push({
				children: (
					<AdCopySimulationOne
						activity={activity}
						appearanceByStrategyTarget={appearanceByStrategyTarget}
						constValue={constValue}
						cp={cp}
						data={test2}
						evaluationResult={evaluation.test2}
						goalSalesVolume={goalSalesVolume}
						hasEditRole={hasEditRole}
						job={job}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => {
							onChangeRatio("test2", name, value);
						}}
						onSimulation={() => {
							onSimulation("test2");
						}}
						onSimulationSave={() => {
							onSimulationSave("test2");
						}}
						onsData={onsByPurchaseIntent.test2}
						targetConceptType="test2"
						trialSalesVolumeBeforeCorrectionByCurrentProduct={trialSalesVolumeBeforeCorrectionByCurrentProduct}
					/>
				),
				key: "test2",
				title: "テスト品2",
			});
		}

		if (test3 && evaluation.test3 && onsByPurchaseIntent.test3) {
			tmp.push({
				children: (
					<AdCopySimulationOne
						activity={activity}
						appearanceByStrategyTarget={appearanceByStrategyTarget}
						constValue={constValue}
						cp={cp}
						data={test3}
						evaluationResult={evaluation.test3}
						goalSalesVolume={goalSalesVolume}
						hasEditRole={hasEditRole}
						job={job}
						maxRecognition={maxRecognition}
						onChangeRatio={(name, value) => {
							onChangeRatio("test3", name, value);
						}}
						onSimulation={() => {
							onSimulation("test3");
						}}
						onSimulationSave={() => {
							onSimulationSave("test3");
						}}
						onsData={onsByPurchaseIntent.test3}
						targetConceptType="test3"
						trialSalesVolumeBeforeCorrectionByCurrentProduct={trialSalesVolumeBeforeCorrectionByCurrentProduct}
					/>
				),
				key: "test3",
				title: "テスト品3",
			});
		}

		if (hasCurrentProduct && canChangeCP) {
			tmp.push({
				children: (
					<AdCopyCPPage
						activity={activity}
						constValue={constValue}
						cp={cp ?? {}}
						errors={errors}
						hasEditRole={hasEditRole}
						job={job}
						maxRecognition={maxRecognition}
						onChange={onChangeCP}
						onClear={onClearCP}
						onSubmit={onSubmitCP}
						onSubmitCPCorrectionValues={onSubmitCPCorrectionValues}
					/>
				),
				key: "cp",
				title: "CP実績補整",
			});
		}

		return tmp;
	}, [
		activity,
		appearanceByStrategyTarget,
		canChangeCP,
		constValue,
		cp,
		errors,
		evaluation.test1,
		evaluation.test2,
		evaluation.test3,
		goalSalesVolume,
		hasCurrentProduct,
		hasEditRole,
		job,
		maxRecognition,
		onChangeCP,
		onChangeRatio,
		onClearCP,
		onSimulation,
		onSimulationSave,
		onSubmitCP,
		onSubmitCPCorrectionValues,
		onsByPurchaseIntent.test1,
		onsByPurchaseIntent.test2,
		onsByPurchaseIntent.test3,
		test1,
		test2,
		test3,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	]);

	return (
		<AreaWrap>
			<AdCopyReportBigTitle>
				補整＆提案フォーマット<AlertRed>（補整値は、アクティビティメンバーと承認者のみ入力可能です）</AlertRed>
				{job ? `（${job.jobNum}）` : ""}
			</AdCopyReportBigTitle>

			{tabs.length > 0 && (
				<TabViewer
					changed={!!confirmMessage}
					confirm={{
						body: confirmMessage ?? "",
						title: "保存確認",
					}}
					id="resultByTarget"
					onChangeTab={() => {
						setChanged(false);
						refetch();
					}}
					tabs={tabs}
				/>
			)}
		</AreaWrap>
	);
});
