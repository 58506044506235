import React, { CSSProperties, FunctionComponent } from "react";

import { Activity } from "../../../../../../server/models/activity";
import { TargetGroup } from "../../../../../../server/models/graph/target-group";
import { categoryTargetGroupToConditionProps } from "./categroy";
import { categoryToConditionProps } from "./general";
import { StrategyConditions } from "./strategy";

export * from "./general";
export * from "./categroy";
export * from "./strategy";

export const Conditions: React.FunctionComponent<{ target: TargetGroup; activity: Activity }> = ({
	target,
	activity,
}) => {
	switch (target) {
		case "general": {
			const props = categoryToConditionProps(activity.category);
			return <ConditionsTable tableProps={props} />;
		}
		case "category": {
			const props = categoryTargetGroupToConditionProps(activity.categoryTargetGroup);
			return <ConditionsTable tableProps={props} />;
		}
		case "strategy": {
			return <StrategyConditions version={activity.version} strategyTargetGroup={activity.strategyTargetGroup} />;
		}
	}
};

type TableProp = {
	label: string;
	value: string;
};

type Props = {
	tableProps: TableProp[];
	style?: CSSProperties;
};

export const ConditionsTable: FunctionComponent<Props> = ({ tableProps, style }) => {
	return (
		<table style={style}>
			<tbody>
				{tableProps.map(({ label, value }) => (
					<tr key={value}>
						<td>{label}</td>
						<td>{label == "" ? "" : ":"}</td>
						<td>{value}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
