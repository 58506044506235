export enum SalesScale {
	moreThanBillion = "10億円以上",
	moreThan300million = "3～10億円未満",
	lessThan300million = "3億円未満",
	undecided = "未定",
}
export const salesScaleOptions = Object.entries(SalesScale)
	.filter(([value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});
