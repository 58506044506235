import * as React from "react";
import { Col, FormGroup, FormLabel, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import styled from "styled-components";

import { MultipleText } from "../../../../../../../react-components/lu-component/src/index";
import { adCopyEvaluationItemMatrixChildQuestion } from "../../../../../../../server/lib/ad-copy/enquete/config";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../lib/error";
import { List } from "../../../../parts/list";

const AlertMessage = styled.div`
	color: red;
`;

type Props = {
	errors: ErrorObject | undefined;
	evaluationItem: string[];
	onChange: (items: string[]) => void;
};

export const EvaluationItemsForm = React.memo((props: Props) => {
	const { errors, evaluationItem, onChange } = props;

	return (
		<FormGroup
			style={{
				borderBottom: "1px solid #ddd",
				paddingBottom: "10px",
			}}
		>
			<FormLabel>印象項目設定</FormLabel>

			<Row>
				<Col md={4}>
					<div>
						標準項目 <AlertMessage>※変更や削除はできません</AlertMessage>
					</div>

					<List list={adCopyEvaluationItemMatrixChildQuestion.map((item) => item.quetitle)} />
				</Col>

				<Col md={8}>
					<div>
						追加項目（任意/5つまで）<AlertMessage>文字数を30文字以内としてください</AlertMessage>
					</div>

					<MultipleText
						hasAddEvent
						max={5}
						onChange={onChange}
						sentenceOptions={{
							max: 30,
						}}
						values={evaluationItem}
					/>

					{errors && "evaluationItem" in errors && (
						<Feedback
							style={{
								display: "block",
							}}
							type="invalid"
						>
							{getErrorMessage("evaluationItem", errors)}
						</Feedback>
					)}
				</Col>
			</Row>
		</FormGroup>
	);
});
