import * as React from "react";

import { decimalCalc } from "../../../../../../../../../server/lib/ad-copy/aggregate/common";
import type { SimulationReferenceData } from "../../../../../../../../../server/models/ad-copy/output";
import type { AdCopySimulationBrandData } from "../../../../../../../../../server/types/request/ad-copy/report/simulation";

type Props = {
	constValue: AdCopySimulationBrandData;
	isExistingBrand: boolean;
	onsData: AdCopySimulationBrandData;
	simulationReferenceData: SimulationReferenceData;
};

// 基人口(万人)
// 購入意向ワンナンバースコア(%)
// 定数
// のテーブル

const thStyle = {
	width: "128px",
} as const satisfies React.CSSProperties;

export const AdCopyUserTable = React.memo((props: Props) => {
	const { constValue, isExistingBrand, onsData, simulationReferenceData } = props;

	const userSizeData = isExistingBrand
		? simulationReferenceData.brandUserSizeData
		: simulationReferenceData.categoryUserSizeData;

	return (
		<table className="result-table">
			<thead>
				<tr>
					<th colSpan={2} />

					<th style={thStyle}>基人口(万人)</th>

					<th style={thStyle}>購入意向ワンナンバースコア(%)</th>

					<th style={thStyle}>定数</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td
						className="label-area"
						rowSpan={2}
						style={{
							verticalAlign: "top",
						}}
					>
						カテゴリユーザー
					</td>

					<td className="label-area">ブランドユーザー</td>

					{/* 基人口(万人) */}
					<td>
						{userSizeData != null && "gSize_BrandUser" in userSizeData
							? decimalCalc(userSizeData.gSize_BrandUser * 100).toFixed(1)
							: "-"}
					</td>

					{/* 購入意向ワンナンバースコア(%) */}
					{/* NOTE: 新規ブランドの場合は、ゼロが返ってくるはず */}
					<td>{decimalCalc(onsData.brandUser).toFixed(1)}</td>

					{/* 定数 */}
					<td>{constValue.brandUser.toFixed(2)}</td>
				</tr>

				<tr>
					<td className="label-area">ノンユーザー</td>

					{/* 基人口(万人) */}
					<td>
						{userSizeData?.gSize_BrandNonuser == null
							? "-"
							: decimalCalc(userSizeData.gSize_BrandNonuser * 100).toFixed(1)}
					</td>

					{/* 購入意向ワンナンバースコア(%) */}
					<td>{decimalCalc(onsData.brandNonUser).toFixed(1)}</td>

					{/* 定数 */}
					<td>{constValue.brandNonUser.toFixed(2)}</td>
				</tr>

				<tr>
					<td className="label-area" colSpan={2}>
						カテゴリノンユーザー
					</td>

					{/* 基人口(万人) */}
					<td>
						{userSizeData?.gSize_CategoryNonuser == null
							? "-"
							: decimalCalc(userSizeData.gSize_CategoryNonuser * 100).toFixed(1)}
					</td>

					{/* 購入意向ワンナンバースコア(%) */}
					<td>{decimalCalc(onsData.categoryNonUser).toFixed(1)}</td>

					{/* 定数 */}
					<td>{constValue.categoryNonUser.toFixed(2)}</td>
				</tr>
			</tbody>
		</table>
	);
});
