import dayjs from "dayjs";
import * as React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import type { NormFlagsSearchValues } from "../../../reducers/norm-flags";
import { NormSearchDatePicker } from "./date-picker";

type Option = {
	label: string;
	value: string;
};

type Props = {
	brandOptions: Option[];
	categoryOptions: Option[];
	onChangeSearchValue: <T extends keyof NormFlagsSearchValues>(name: T, value: NormFlagsSearchValues[T]) => void;
	onReset: () => void;
	onSearch: () => void;
	searchValues: NormFlagsSearchValues;
};

export const NormSearchForm = React.memo((props: Props) => {
	const { brandOptions, categoryOptions, onChangeSearchValue, onReset, onSearch, searchValues } = props;

	return (
		<Card>
			<Card.Body>
				<Row>
					{/* アクティビティNo. */}
					<Col md={2}>
						<Form.Group>
							<Form.Label>アクティビティNo.</Form.Label>

							<Form.Control
								onChange={(e) => {
									onChangeSearchValue("activityNo", e.target.value);
								}}
								type="search"
								value={searchValues.activityNo}
							/>
						</Form.Group>
					</Col>

					{/* ジョブNo. */}
					<Col md={2}>
						<Form.Group>
							<Form.Label>ジョブNo.</Form.Label>

							<Form.Control
								onChange={(e) => {
									onChangeSearchValue("jobNum", e.target.value);
								}}
								type="search"
								value={searchValues.jobNum}
							/>
						</Form.Group>
					</Col>

					{/* カテゴリ */}
					<Col md={4}>
						<Form.Group>
							<Form.Label>カテゴリ</Form.Label>

							<Form.Select
								onChange={(e) => {
									onChangeSearchValue("categoryId", e.target.value);
								}}
								value={searchValues.categoryId}
							>
								<option value="" />

								{categoryOptions.map(({ label, value }) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					</Col>

					{/* ブランド */}
					<Col md={4}>
						<Form.Group>
							<Form.Label>ブランド</Form.Label>

							<Form.Select
								onChange={(e) => {
									onChangeSearchValue("brandId", e.target.value);
								}}
								value={searchValues.brandId}
							>
								<option value="" />

								{brandOptions.map(({ label, value }) => (
									<option key={value} value={value}>
										{label}
									</option>
								))}
							</Form.Select>
						</Form.Group>
					</Col>
				</Row>

				<Row style={{ marginTop: "0.8em" }}>
					{/* 確認ステータス */}
					<Col md={2}>
						<Form.Group>
							<Form.Label>確認ステータス</Form.Label>

							<Form.Select
								onChange={(e) => {
									const v = e.target.value;

									onChangeSearchValue("checked", v === "true" ? true : v === "false" ? false : "");
								}}
								value={searchValues.checked === true ? "true" : searchValues.checked === false ? "false" : ""}
							>
								<option value="" />

								<option value="true">確認済</option>

								<option value="false">未確認</option>
							</Form.Select>
						</Form.Group>
					</Col>

					<Col md={4}>
						<Form.Group>
							<Form.Label>調査終了時期</Form.Label>

							<div className="d-flex gap-2 align-items-center">
								<NormSearchDatePicker
									onChange={(date) => {
										onChangeSearchValue("implementationPeriod", {
											...searchValues.implementationPeriod,
											from: date == null ? "" : dayjs(date).format("YYYY-MM-DD"),
										});
									}}
									value={searchValues.implementationPeriod.from}
								/>

								<span>～</span>

								<NormSearchDatePicker
									onChange={(date) => {
										onChangeSearchValue("implementationPeriod", {
											...searchValues.implementationPeriod,
											to: date == null ? "" : dayjs(date).format("YYYY-MM-DD"),
										});
									}}
									value={searchValues.implementationPeriod.to}
								/>
							</div>
						</Form.Group>
					</Col>

					<Col
						md={{
							offset: 2,
							span: 4,
						}}
						style={{
							alignItems: "flex-end",
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<div className="d-flex gap-2">
							<Button
								onClick={onReset}
								style={{
									width: "6rem",
								}}
								variant="outline-secondary"
							>
								リセット
							</Button>

							<Button
								onClick={onSearch}
								style={{
									width: "6rem",
								}}
								variant="secondary"
							>
								検索
							</Button>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
});
