import * as React from "react";

import type { AdCopyActivity } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyJob } from "../../../../../../../../server/models/ad-copy/job";
import type { AdCopySampleSizesByType } from "../../../../../../../../server/types/request/ad-copy/report/outline";
import { AdCopyOutlinePanel, AdCopyOutlinePanelTitle } from "..";
import { AdCopySamplePanel } from "./sample-panel";
import { AdCopyTargetPanel } from "./target-panel";

type Props = {
	activity: AdCopyActivity;
	benchmarkName?: string;
	job: AdCopyJob;
	onViewQuotaPage: () => void;
	sampleSizesByType: AdCopySampleSizesByType;
};

export const AdCopyTestPanel = React.memo((props: Props) => {
	const { activity, benchmarkName, job, onViewQuotaPage, sampleSizesByType } = props;

	return (
		<AdCopyOutlinePanel className="panel">
			<AdCopyOutlinePanelTitle>テスト概要</AdCopyOutlinePanelTitle>

			<AdCopyTargetPanel activity={activity} shouldShowCategoryUser={job.isDemandForecastNeeded ?? false} />

			<AdCopySamplePanel
				benchmarkName={benchmarkName}
				job={job}
				onViewQuotaPage={onViewQuotaPage}
				sampleSizesByType={sampleSizesByType}
			/>
		</AdCopyOutlinePanel>
	);
});
