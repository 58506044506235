import * as React from "react";
import { styled } from "styled-components";

import type { AdCopyResultDetailsResBody } from "../../../../../../../server/types/request/ad-copy/report/resultDetails";
import type { AdCopyResultByTargetAction } from "../../../../../types/actions";
import { ActionStandards } from "./action-standards";
import { AdCopyResultDetail } from "./detail";
import { AdCopyResultSummary } from "./summary";

const StyledDiv = styled.div`
	margin: 0px auto;
	padding: 0px 5px;
	max-width: 1200px;
`;

type Props = AdCopyResultDetailsResBody & {
	dispatch: React.Dispatch<AdCopyResultByTargetAction>;
	navigateSimulationPage: () => void;
};

export const AdCopyResultByTargeReportPage = React.memo((props: Props) => {
	const { activity, data, dispatch, navigateSimulationPage } = props;

	return (
		<StyledDiv>
			{/* アクションスタンダード */}
			<ActionStandards activity={activity} />

			{/* 結果サマリー */}
			<AdCopyResultSummary
				differentiationAndONS={data.differentiationAndONS}
				navigateSimulationPage={navigateSimulationPage}
				simulations={data.simulations}
			/>

			{/* 結果詳細 */}
			<AdCopyResultDetail data={data} dispatch={dispatch} />
		</StyledDiv>
	);
});
