import * as React from "react";
import { FormControl } from "react-bootstrap";

import { adCopySimulationValueLabels } from "../../../../../../../../label/ad-copy/simulation-value";
import { Help } from "../../../../../parts/help";
import { calcDiffPercent } from "../common";
import { RecognitionRateEditableText } from "../editable-text/recognition-rate-editable-text";

type Props = {
	hasEditRole: boolean;
	init: number;
	maxRecognition: number;
	onChangeReason: (value: string) => void;
	onChangeValue: (value: number | undefined) => void;
	reason: string | undefined;
	value: number | undefined;
};

export const AdCopyCpRecognitionRateValueRow = React.memo((props: Props) => {
	const { hasEditRole, init, maxRecognition, onChangeReason, onChangeValue, reason, value } = props;

	const diffPercent = React.useMemo(() => {
		if (value == null) {
			return "-";
		}

		return calcDiffPercent(init, value);
	}, [init, value]);

	return (
		<tr>
			<td className="label-area">
				{adCopySimulationValueLabels.recognitionRate}

				<Help explain={`ブランド知名率の上限：${maxRecognition}以下で入力してください。`} id="max-regognition" />
			</td>

			{/* 初期値 */}
			<td>{init}</td>

			{/* 補整値 */}
			<td>
				{hasEditRole ? (
					<RecognitionRateEditableText
						//
						initValue={init}
						onChange={onChangeValue}
						value={value}
					/>
				) : (
					value ?? "-"
				)}
			</td>

			{/* 補整率（％） */}
			<td className={diffPercent !== "-" && diffPercent < 0 ? "minus" : "plus"}>
				{diffPercent === "-" ? diffPercent : diffPercent.toFixed(1)}
			</td>

			{/* 補整根拠 */}
			<td className="reason-area">
				<FormControl
					as="textarea"
					onChange={(e) => {
						onChangeReason(e.target.value.slice(0, 90));
					}}
					readOnly={!hasEditRole}
					value={reason}
				/>
			</td>
		</tr>
	);
});
