import * as React from "react";
import { categoryTargetGroupLabels } from "../../../../../../label/activity";
import { makeAgeLabel, makePriceLabel } from "../../../../../../server/lib/quota/helpers";
import { CategoryGroup } from "../../../../../../server/models/activity";

export type CategoryConditionsProps = {
	categoryTargetGroup: CategoryGroup;
};

export const CategoryConditions = React.memo((props: CategoryConditionsProps) => {
	const { categoryTargetGroup } = props;
	return (
		<table>
			<tbody>
				<tr>
					<td>{categoryTargetGroupLabels.age}</td>
					<td>：</td>
					<td>{makeAgeLabel(categoryTargetGroup.age)}</td>
				</tr>
				<tr>
					<td>{categoryTargetGroupLabels.price}</td>
					<td>：</td>
					<td>{makePriceLabel(categoryTargetGroup.price)}</td>
				</tr>
			</tbody>
		</table>
	);
});
