import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "../../../../../../react-components/lu-component/src/index";
import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import type { GetAdCopyActivityMemberResponse } from "../../../../../../server/types/request/ad-copy/activity";
import { AdCopyActivityMemberPage } from "../../../../components/pages/ad-copy/activity/member";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import type { SwitchConfirmEvent } from "../../../../components/parts/switch-confirm";
import { SwitchConfirm } from "../../../../components/parts/switch-confirm";
import { get, makeError, put } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/activity/member";
import { adCopyClientEndpoint } from "../../../../routes/adCopyEndpoint";

export const AdCopyActivityMemberContainer = (loginAccount: AccountWithoutPassword) => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const navigate = useNavigate();

	const { activityId } = useParams<{ activityId: string }>();

	const { accounts, activity, activityMembers, hasDemandForecastNeeded, info, loading } = state;

	React.useEffect(() => {
		get<GetAdCopyActivityMemberResponse>(`${adCopyEndpoint.activityMember}/${activityId}`)
			.then((response) => {
				dispatch({ payload: response.data, type: "loadActivity" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId]);

	const events = React.useMemo<SwitchConfirmEvent[]>(() => {
		return [
			{
				func: () => navigate(adCopyClientEndpoint.activity),
				label: "アクティビティ一覧に戻る",
				variant: "outline-secondary",
			},
		];
	}, [navigate]);

	const onSubmit = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		put<GetAdCopyActivityMemberResponse>(`${adCopyEndpoint.activityMember}/${activityId}`, {
			accountIds: activityMembers,
		})
			.then(() => {
				dispatch({ payload: undefined, type: "updateMembers" });
			})
			.catch((error) => {
				dispatch({ payload: makeError(error), type: "changeMessageInfo" });
			});
	}, [activityId, activityMembers]);

	const onChange = React.useCallback((value: string[]) => {
		dispatch({ payload: value, type: "changeMembers" });
	}, []);

	return (
		<>
			<Loading loading={loading} text="processing..." />

			{!info.isSuccess && <ConfirmBox info={info} />}

			<SwitchConfirm
				body={info.message}
				events={events}
				show={info.isSuccess && !!info.message}
				title="アクティビティ登録者の更新"
			/>

			{activity && (
				<AdCopyActivityMemberPage
					accounts={accounts}
					activity={activity}
					activityMembers={activityMembers}
					hasDemandForecastNeeded={hasDemandForecastNeeded}
					loginAccount={loginAccount}
					onChange={onChange}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
};
