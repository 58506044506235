import * as React from "react";

import type { BetweenDateValues } from "../../../../../../react-components/lu-component/src/index";
import { Loading } from "../../../../../../react-components/lu-component/src/index";
import { adCopyEndpoint } from "../../../../../../server/router/ad-copy/endpoint";
import { AdCopyJobDownloadPage } from "../../../../components/pages/ad-copy/job/download";
import { ConfirmBox } from "../../../../components/parts/confirm-box";
import { fileDownload, makeQueies } from "../../../../lib/request";
import { initState, reducer } from "../../../../reducers/ad-copy/job/download";

export const AdCopyJobDownloadContainer = () => {
	const [state, dispatch] = React.useReducer(reducer, initState);

	const { filteredValue, info, loading } = state;

	const onChange = React.useCallback((values: BetweenDateValues) => {
		dispatch({ payload: values, type: "changeFiltered" });
	}, []);

	const onDownload = React.useCallback(() => {
		dispatch({ payload: true, type: "changeLoading" });

		fileDownload(`${adCopyEndpoint.jobDownload}?${makeQueies(filteredValue)}`, "job-list.csv")
			.then(() => {
				dispatch({ payload: false, type: "changeLoading" });
			})
			.catch(() => {
				dispatch({
					payload: { isSuccess: false, message: "終了日は必ず設定してください。" },
					type: "changeMessageInfo",
				});
			});
	}, [filteredValue]);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="ダウンロード" />

			<AdCopyJobDownloadPage
				errors={info.errors}
				filtered={filteredValue}
				onDownload={onDownload}
				onFilteredChange={onChange}
			/>
		</>
	);
};
