type Key = number | string;

type Dict<T> = Record<string, T>;

/**
 * urlを組み立て
 * simplify endpoint construction by automatically attaching / where needed. EG: (buildUrl(["1", "2", "3"]) -> /1/2/3)
 * attaching params made more user friendly - EG: buildUrl(["1", "2", "3"], { limit: 0, page: 1 }) -> "/1/2/3?limit=0&page=1"
 * @param {string[]} frags
 * @param {Dict<Key | undefined>} params
 * @returns
 */
export function buildUrl(
	frags: ({ toString: () => string } | null | string | undefined)[],
	params?: Dict<Key | Key[] | undefined>,
) {
	const withSuffix = (url: string): string => `${/^https?:\/\//.test(url) ? "" : "/"}${url}`;

	const url = frags.map((frag) => frag?.toString().replace(/^\//, "") ?? "").join("/");

	if (params == null) {
		return withSuffix(url);
	}

	const filteredParams = Object.entries(params).filter((param): param is [string, Key] => param[1] != null);

	if (filteredParams.length === 0) {
		return withSuffix(url);
	}

	const queryParams = filteredParams
		.map(([key, value]) => {
			if (Array.isArray(value)) {
				return value.map((v) => `${key}[]=${v}`).join("&");
			}

			return `${key}=${value.toString()}`;
		})
		.join("&");

	return `${withSuffix(url)}?${queryParams}`;
}
