import * as React from "react";
import { Col, FormCheck, FormGroup } from "react-bootstrap";

import type { Choice } from "../../../../../react-components/lu-component/src";

type Props = {
	checkeds: Choice["value"][];
	choices: Choice[];
	disabledChoices?: Choice["value"][];
	name: string;
	onChange: (checkeds: Choice["value"][]) => void;
};

export const ActivityCheckboxes = React.memo((props: Props) => {
	const { checkeds, choices, disabledChoices, name, onChange } = props;

	const onChangeCheckbox = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, value: Choice["value"]) => {
			onChange(e.target.checked ? checkeds.concat(value) : checkeds.filter((ch) => ch !== value));
		},
		[checkeds, onChange],
	);

	return (
		<FormGroup as={Col} className="position-relative w-100 px-3">
			{choices.map((choice) => (
				<FormCheck
					key={`checkbox-${choice.value}`}
					checked={checkeds.includes(choice.value)}
					disabled={disabledChoices?.includes(choice.value)}
					inline={false}
					label={choice.label}
					name={name}
					onChange={(e) => {
						onChangeCheckbox(e, choice.value);
					}}
					type="checkbox"
					value={choice.value.toString()}
				/>
			))}
		</FormGroup>
	);
});
