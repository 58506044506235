import { SensitiveSkinFrequencyRank } from "./v1";

export const sensitiveSkinFrequencyRankOptions = Object.entries(SensitiveSkinFrequencyRank)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});

export const sensitiveSkinFrequencyOptionsWithRank = [
	{ value: 1, label: "ほぼ毎日", rank: 0 },
	{ value: 2, label: "週に２～３回程度", rank: 0 },
	{ value: 3, label: "週に１回程度", rank: 0 },
	{ value: 4, label: "月に２回程度", rank: 1 },
	{ value: 5, label: "月に１回程度", rank: 1 },
	{ value: 6, label: "２か月に１回程度", rank: 2 },
	{ value: 7, label: "３か月に１回程度", rank: 2 },
	{ value: 8, label: "半年に１回程度", rank: 2 },
	{ value: 9, label: "それ以下", rank: 2 },
];
