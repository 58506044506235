/* eslint-disable react/jsx-newline */

import * as React from "react";

import type { AdCopyActivity } from "../../../../../../../../../server/models/ad-copy/activity";
import { adCopyTargetGroupMap } from "../../../../../../../../../server/models/ad-copy/enquete";
import { AdCopyGeneralConditions } from "../../../../../../parts/ad-copy/conditions/general";
import { AdCopyStrategyConditions } from "../../../../../../parts/ad-copy/conditions/strategy";
import {
	AdCopyChildPanel,
	AdCopyIndentArea,
	AdCopyOutlineCard,
	AdCopyOutlinePanel,
	AdCopyOutlinePanelBody,
	AdCopyOutlineSubTitle,
} from "../..";

type Props = {
	activity: AdCopyActivity;
	shouldShowCategoryUser: boolean;
};

export const AdCopyTargetPanel = React.memo((props: Props) => {
	const { activity, shouldShowCategoryUser } = props;

	const { category, strategyTargetGroup } = activity;

	return (
		<AdCopyOutlinePanel className="panel">
			<AdCopyOutlinePanelBody>
				<AdCopyOutlineSubTitle>□ 対象者条件</AdCopyOutlineSubTitle>

				{/* カテゴリユーザー */}
				{/* NOTE: 需要予測しない場合は非表示 */}
				{shouldShowCategoryUser && (
					<AdCopyChildPanel>
						<AdCopyOutlineCard width={370}>
							<AdCopyIndentArea>
								【{adCopyTargetGroupMap.categoryUser}】
								<AdCopyIndentArea indent={0.7}>
									<AdCopyGeneralConditions category={category} />
								</AdCopyIndentArea>
							</AdCopyIndentArea>
						</AdCopyOutlineCard>
					</AdCopyChildPanel>
				)}

				{/* 戦略ターゲット */}
				<AdCopyChildPanel>
					<AdCopyOutlineCard width={600}>
						<AdCopyIndentArea>
							【{adCopyTargetGroupMap.strategyTarget}】
							<AdCopyStrategyConditions strategyTargetGroup={strategyTargetGroup} />
						</AdCopyIndentArea>
					</AdCopyOutlineCard>
				</AdCopyChildPanel>
			</AdCopyOutlinePanelBody>
		</AdCopyOutlinePanel>
	);
});
