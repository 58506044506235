import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import type { Choice } from "../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../react-components/lu-component/src/index";
import { AccordionForm } from "../../../../parts/accodion-form";
import { aestheticMedicineExperienceOptions, StrategyGroup } from "../../../../../../../server/models/activity";
import { strategyTargetGroupLabels } from "../../../../../../../label/activity";

const key = "aestheticMedicineExperience";

type Key = typeof key;

type Value = StrategyGroup[Key];

type Props = {
	onChange: (name: Key, value: any) => void;
	value: Value | undefined;
};

export const AestheticMedicineExperienceCheckboxes = React.memo((props: Props) => {
	const { onChange, value } = props;

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm defaultShow={!!value?.length} eventKey={key} title={strategyTargetGroupLabels[key] ?? ""}>
					<Checkboxes
						checkeds={value ?? []}
						choices={aestheticMedicineExperienceOptions as Choice[]}
						name={key}
						onChange={(value) => {
							onChange(key, value as Value);
						}}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
