import * as React from "react";
import { Col, FormCheck, FormGroup } from "react-bootstrap";

type Value = boolean | number | string;

type Choice<T extends Value> = {
	label: React.ReactNode;
	value: T;
};

type Props<T extends Value> = {
	checked: T | undefined;
	choices: Choice<T>[];
	disabled?: boolean;
	name: string;
	onChange: (v: T) => void;
};

const AdCopyRadiosComponent = <T extends Value>({ checked, choices, disabled, name, onChange }: Props<T>) => {
	return (
		<FormGroup as={Col} className="position-relative w-100 px-3">
			{choices.map((choice) => (
				<FormCheck
					key={choice.value?.toString()}
					checked={checked === choice.value}
					disabled={disabled}
					label={choice.label}
					name={name}
					onChange={(e) => {
						if (e.target.checked) {
							onChange(choice.value);
						}
					}}
					type="radio"
					value={choice.value?.toString()}
				/>
			))}
		</FormGroup>
	);
};

export const AdCopyRadios = React.memo(AdCopyRadiosComponent) as typeof AdCopyRadiosComponent;
