// 参考: https://neko-note.org/react-bootstrap-pagination/197

import * as React from "react";
import { Pagination as BsPagination } from "react-bootstrap";

type Props = {
	currentIndex: number;
	lastIndex: number;
	onPaging: (page: number) => void;
};

export const Pagination = React.memo((props: Props) => {
	const { currentIndex, lastIndex, onPaging } = props;

	return (
		<BsPagination>
			{/* 最初へ */}
			<BsPagination.First
				disabled={currentIndex === 0}
				onClick={() => {
					onPaging(0);
				}}
			/>

			{/* 前へ */}
			<BsPagination.Prev
				disabled={currentIndex === 0}
				onClick={() => {
					onPaging(Math.max(0, currentIndex - 1));
				}}
			/>

			{/* 2つ前 */}
			{2 <= currentIndex && (
				<BsPagination.Item
					onClick={() => {
						onPaging(currentIndex - 2);
					}}
				>
					{currentIndex - 1}
				</BsPagination.Item>
			)}

			{/* 1つ前 */}
			{1 <= currentIndex && (
				<BsPagination.Item
					onClick={() => {
						onPaging(currentIndex - 1);
					}}
				>
					{currentIndex}
				</BsPagination.Item>
			)}

			{/* 現在のページ */}
			<BsPagination.Item active>{currentIndex + 1}</BsPagination.Item>

			{/* 1つ次 */}
			{currentIndex + 1 <= lastIndex && (
				<BsPagination.Item
					onClick={() => {
						onPaging(currentIndex + 1);
					}}
				>
					{currentIndex + 2}
				</BsPagination.Item>
			)}

			{/* 2つ次 */}
			{currentIndex + 2 <= lastIndex && (
				<BsPagination.Item
					onClick={() => {
						onPaging(currentIndex + 2);
					}}
				>
					{currentIndex + 3}
				</BsPagination.Item>
			)}

			{/* 次へ */}
			<BsPagination.Next
				disabled={currentIndex === lastIndex}
				onClick={() => {
					onPaging(Math.min(lastIndex, currentIndex + 1));
				}}
			/>

			{/* 最後へ */}
			<BsPagination.Last
				disabled={currentIndex === lastIndex}
				onClick={() => {
					onPaging(lastIndex);
				}}
			/>
		</BsPagination>
	);
});
