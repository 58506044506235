/* eslint-disable react/jsx-newline */

import * as React from "react";

import { Choice } from "../../../../../../../react-components/lu-component/src";
import { categoryOrAndOptions } from "../../../../../../../server/models/activity";

import { BlueText, RedText } from "./utils";

export const andOrOptions = categoryOrAndOptions.map<Choice>(({ label, value }) => {
	const [_, text1, text2, text3] = label.match(/^(.*)(or|and)(.*)$/);
	return {
		value: value,
		label: (
			<span>
				<span>{text1}</span>
				{text2 === "and" ? <RedText>「{text2}」</RedText> : <BlueText>「{text2}」</BlueText>}
				<span>{text3}</span>
			</span>
		),
	};
});
