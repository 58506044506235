import * as React from "react";
import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";

type Props = {
	onChange: ReactDatePickerProps["onChange"];
	value: string | undefined;
};

export const NormSearchDatePicker = React.memo((props: Props) => {
	const { onChange, value } = props;

	return (
		<ReactDatePicker
			//
			className="form-control"
			dateFormat="yyyy/M/d"
			onChange={onChange}
			selected={value == null || value === "" ? undefined : new Date(value)}
		/>
	);
});
