import * as React from "react";

type Props = {
	cpCorrectionValue: number;
};

export const AdCopyCPCorrectionValueTable = React.memo((props: Props) => {
	const { cpCorrectionValue } = props;

	return (
		<table
			className="correct-table"
			style={{
				display: "inline-table",
				marginLeft: "10%",
				width: "20%",
			}}
		>
			<thead>
				<tr>
					<th>実績補整係数</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>{cpCorrectionValue.toFixed(1)}</td>
				</tr>
			</tbody>
		</table>
	);
});
