import { ObjectId } from "mongodb";
import { CategoryGroup } from "./activity";
import { Choice } from "@muscat/types";
import { Option } from "../types/request";
import { Age, Gender, SurveyChoice } from "./shared";

/**
 * otherSkincare: 狭義スキンケア(洗顔料、メイク落とし除外)
 */
export type CategoryType =
	| "skincare"
	| "eyeShadow"
	| "lipstick"
	| "liquid-foundation"
	| "antiperspirant"
	| "other"
	/** 以下は実際不使用 */
	| "foundation";

type GeneralGroupQuotaBase = {
	age: Age;
	gender: keyof typeof Gender;
	sampleSize: number; // コンセプトテストのカテゴリーREP（全体）のサンプル数
};

export type CategoryUserGeneralGroupQuota = GeneralGroupQuotaBase & {
	adCopySampleSize: number; // コピーテストのカテゴリーユーザー（カテゴリーREP）のサンプル数
	isUser: true;
};

type CategoryNonUserGeneralGroupQuota = GeneralGroupQuotaBase & {
	isUser: false;
};

export type GeneralGroupQuota = CategoryUserGeneralGroupQuota | CategoryNonUserGeneralGroupQuota;

export const BigTitle = {
	menOnly: "男性用",
	womenOnly: "女性用",
	both: "男女共用／女性用",
} as const;

export type CategorySurveyChoice = SurveyChoice & {
	bigTitle?: keyof typeof BigTitle;
	smallTitle?: string; //「あ行、か行」等の小見出し
	/** 自社ブランド対応のnBrand番号 */
	nBrand?: number;
};
export type CategoryBrand = {
	brandName: string;
	surveyChoice: CategorySurveyChoice[];
};

export type CategoryRecognition = {
	nBrand: number;
	nCategoryUPI: number;
	sCategoryUPI: string;
	sBrandUPI2: string;
	gAwareness: number;
	/** もう使わない */
	bShiseido?: boolean;
	/** もう使わない */
	bMajor?: boolean;
};

/**
 * @property {number} nBrand ブランドのユニークコード（共通）
 * @property {number[]} targetSurveyChoiceValues 調査表上過去使用ブランドの選択肢value
 * @property {boolean} isExistingBrand コピーテストにおける ”既存ブランド” かどうか, true -> 既存ブランド
 */
export type AdCopyBrandUserDefinition = {
	nBrand: number;
	targetSurveyChoiceValues: number[];
	isExistingBrand?: boolean;
};

/**
 * @property {ObjectId | string} _id objectId
 * @property {string} name カテゴリ名
 * @property {number} nCategory nCategory, 共通のコード
 * @property {AdCopyBrandUserDefinition[]} adCopyBrandUserDefinitions コピー調査でブランドユーザーG定義。調査表上過去使用ブランドの選択肢value
 * @property {number} order コンセプトアセスメントにおける表示順, ≠ nCategory, 非表示（不使用）は 0
 * @property {number} adCopyOrder コピーテストにおける表示順, ≠ nCategory, 非表示（不使用）は 0
 * @property {keyof typeof Gender} gender 性別コード
 * @property {CategoryBrand[]} brandlist コンセプトアセスメントにおけるブランドリスト（商品リスト）, 調査画面における現使用ブランドで利用
 * @property {CategoryBrand[]} adCopyBrandlist コンセプトアセスメントにおけるブランドリスト（商品リスト）, 調査画面における現使用ブランドで利用
 * @property {CategoryType} type カテゴリタイプ
 * @property {CategoryGroup[]} priceAppearance カテゴリおける価格帯 x 年齢 の出現, コンセプトテストの割付で利用, コピーテストでは利用しない
 * @property {CategoryRecognition[]} categoryRecognition カテゴリにおけるブランドごとの認知率（自社他社問わず）, コンセプトアセスメントとコピーテストで共通
 * @property {GeneralGroupQuota[]} generalGroupQuota カテゴリユーザー/ノンユーザーの年代別クォータ
 * @property {SurveyChoice[]} surveyChoice コンセプトアセスメントにおける調査画面の選択肢情報
 * @property {SurveyChoice[]} adCopySurveyChoice コピーテストにおける調査画面の選択肢情報
 */
export interface Category {
	_id?: ObjectId | string;
	nCategory: number;
	adCopyBrandUserDefinitions: AdCopyBrandUserDefinition[];
	name: string;
	order: number;
	adCopyOrder: number;
	gender: keyof typeof Gender;
	brandlist: CategoryBrand[];
	adCopyBrandlist: CategoryBrand[];
	type: CategoryType;
	priceAppearance: CategoryGroup[];
	categoryRecognition: CategoryRecognition[];
	generalGroupQuota: GeneralGroupQuota[];
	surveyChoice: SurveyChoice[];
	adCopySurveyChoice: SurveyChoice[];
	adCopyCanDemandForecastNeeded: boolean;
}

// export const priceList = [100, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 8000, 10000];
export const priceList = [
	1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 8000, 10000, 15000, 20000, 25000, 30000,
];
export const priceChoices: (Choice & {
	from: number;
	to: number | undefined;
})[] = priceList
	.map((price, index) => {
		if (index === 0) {
			return {
				value: index + 1,
				text: `${price.toLocaleString()}円未満`,
				from: 0,
				to: price - 1,
			};
		}
		return {
			value: index + 1,
			text: `${priceList[index - 1].toLocaleString()}～${price.toLocaleString()}円未満`,
			from: priceList[index - 1],
			to: price - 1,
		};
	})
	.concat({
		value: priceList.length + 1,
		text: `${priceList[priceList.length - 1].toLocaleString()}円以上`,
		from: priceList[priceList.length - 1],
		to: undefined,
	});

export const toPriceOptions: Option[] = priceList
	.map((price) => {
		const p = price - 1;
		return {
			value: p,
			label: `${p.toLocaleString()}円`,
		};
	})
	.concat([{ value: undefined, label: "上限を設定しない" }]);
export const fromPriceOptions: Option[] = [{ value: 1, label: "1円" }].concat(
	priceList.map((price, index) => {
		return {
			value: price,
			label: `${price.toLocaleString()}円${index - 1 === priceList.length ? "以上" : ""}`,
		};
	}),
);

/**
 * 洗顔機能の設問設定可能か
 * warning: 洗顔 or クレンジングの識別が名称だけでしかできない
 * @param {Category} category
 */
export const canSetFacewachFunction = (category: Category): boolean => {
	return isFaceWash(category) || isCleansing(category);
};

export const isFaceWash = (category: Category): boolean => {
	return category.name === "洗顔料";
};

export const isCleansing = (category: Category): boolean => {
	return category.name === "メイク落とし";
};

/**
 * スキンケア系のカテゴリか（美類タイプ設問対象）
 */
export const isSkincareForBeautyType = (categoryType: CategoryType): boolean => {
	return categoryType === "skincare";
};

/**
 * コピー調査用価格リスト
 */
export const adCopyPriceList = [
	1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 8000, 10000, 15000, 20000, 25000, 30000,
];
export const adCopyPriceChoices: (Choice & {
	from: number;
	to: number | undefined;
})[] = adCopyPriceList
	.map((price, index) => {
		if (index === 0) {
			return {
				value: index + 1,
				text: `${price.toLocaleString()}円未満`,
				from: 0,
				to: price - 1,
			};
		}
		return {
			value: index + 1,
			text: `${adCopyPriceList[index - 1].toLocaleString()}～${price.toLocaleString()}円未満`,
			from: adCopyPriceList[index - 1],
			to: price - 1,
		};
	})
	.concat({
		value: adCopyPriceList.length + 1,
		text: `${adCopyPriceList[adCopyPriceList.length - 1].toLocaleString()}円以上`,
		from: adCopyPriceList[adCopyPriceList.length - 1],
		to: undefined,
	});

export const toAdCopyPriceOptions: Option[] = adCopyPriceList
	.map((price) => {
		const p = price - 1;
		return {
			value: p,
			label: `${p.toLocaleString()}円`,
		};
	})
	.concat([{ value: undefined, label: "上限を設定しない" }]);

export const fromAdCopyPriceOptions: Option[] = [{ value: 1, label: "1円" }].concat(
	adCopyPriceList.map((price, index) => {
		return {
			value: price,
			label: `${price.toLocaleString()}円${index - 1 === adCopyPriceList.length ? "以上" : ""}`,
		};
	}),
);
