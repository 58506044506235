import { ConceptKey } from "../../models/concept";
import { Choice, ChoiceGroup } from "@muscat/types";
import { Option } from "../../types/request";

export const choice2Option = (choices: Choice[]): Option[] => {
	return choices.map((choice) => {
		return {
			label: choice.text,
			value: choice.value,
		};
	});
};
export const array2Option = (arr: string[]): Option[] => {
	return arr.map((str) => {
		return {
			label: str,
			value: str,
		};
	});
};
// コンセプトは順番を決めておく。上からtest1、test2、競合品、現行品
export const conceptNumber: { [key in ConceptKey]: number } = {
	test1: 1,
	test2: 2,
	benchmark: 3,
	currentProduct: 4,
};

export const quotaCode = {
	test1: "A",
	test2: "B",
	category: "C",
	strategy: "D",
} as const;

export const countQuotaCode = {
	general: "X",
	category: "Y",
	strategy: "Z",
} as const;

export const occupationChoices: Choice[] = [
	{ value: 1, text: "会社役員・会社員・公務員" },
	{ value: 2, text: "派遣社員" },
	{ value: 3, text: "パート・アルバイト" },
	{ value: 4, text: "自営業" },
	{ value: 5, text: "自由業・フリーランス" },
	{ value: 6, text: "高校生・高専生" },
	{ value: 7, text: "大学（短大・専門学校）生・大学院生" },
	{ value: 8, text: "専業主婦・主夫" },
	{ value: 9, text: "無職（定年後退職を含む）・家事手伝い" },
	{ value: 10, text: "その他", other: {} },
];

export const marriedChoices: Choice[] = [
	{ value: 1, text: "未婚" },
	{ value: 2, text: "既婚" },
	{ value: 3, text: "離別・死別" },
];

export const purchaseIntentChoices: Choice[] = [
	{ value: 1, text: "非常に買いたい" },
	{ value: 2, text: "買いたい" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "買いたくない" },
	{ value: 5, text: "まったく買いたくない" },
];

export const evaluationChoices: Choice[] = [
	{ value: 1, text: "非常にそう思う" },
	{ value: 2, text: "そう思う" },
	{ value: 3, text: "どちらともいえない" },
	{ value: 4, text: "そう思わない" },
	{ value: 5, text: "まったくそう思わない" },
];

export const sensitiveSkinFrequencyOptionsWithRank = [
	{ value: 1, label: "ほぼ毎日", rank: 0 },
	{ value: 2, label: "週に２～３回程度", rank: 0 },
	{ value: 3, label: "週に１回程度", rank: 0 },
	{ value: 4, label: "月に２回程度", rank: 1 },
	{ value: 5, label: "月に１回程度", rank: 1 },
	{ value: 6, label: "２か月に１回程度", rank: 2 },
	{ value: 7, label: "３か月に１回程度", rank: 2 },
	{ value: 8, label: "半年に１回程度", rank: 2 },
	{ value: 9, label: "それ以下", rank: 2 },
] as const;

export const ageOptions = ["15〜19歳", "20〜29歳", "30〜39歳", "40〜49歳", "50〜59歳", "60〜69歳", "70〜79歳"];
export const purchaseIntentOptions = purchaseIntentChoices.map((pc) => pc.text);
export const evaluationOptions = evaluationChoices.map((ec) => ec.text);

export const skinAttributeOptionsWithBlock = [
	{ blockName: "A", value: 1, label: "シミ・そばかす" },
	{ blockName: "B", value: 2, label: "毛穴の目立ち" },
	{ blockName: "B", value: 3, label: "頬のたるみ" },
	{ blockName: "B", value: 4, label: "頬のしわ" },
	{ blockName: "C", value: 5, label: "乾燥・かさつき・うるおいのなさ" },
	{ blockName: "C", value: 6, label: "ごわつき" },
	{ blockName: "C", value: 7, label: "皮むけ" },
	{ blockName: "F", value: 8, label: "ぶつぶつ" },
	{ blockName: "F", value: 9, label: "かゆみ" },
	{ blockName: "F", value: 10, label: "かぶれ" },
	{ blockName: "F", value: 11, label: "湿疹" },
	{ blockName: "F", value: 12, label: "ただれ" },
	{ blockName: "F", value: 13, label: "皮膚炎" },
	{ blockName: "D", value: 14, label: "ニキビ・吹き出物" },
	{ blockName: "A", value: 15, label: "くすみ" },
	{ blockName: "A", value: 16, label: "透明感のなさ" },
	{ blockName: "E", value: 17, label: "目の下のくま" },
	{ blockName: "E", value: 18, label: "目元のしわ・たるみ" },
	{ blockName: "F", value: 19, label: "肌荒れ" },
	{ blockName: "D", value: 20, label: "脂っぽさ・テカリ・べたつき" },
	{ blockName: "C", value: 21, label: "きめの粗さ" },
	{ blockName: "C", value: 22, label: "つやのなさ" },
	{ blockName: "F", value: 23, label: "頬の赤み" },
	{ blockName: "A", value: 24, label: "色むら" },
	{ blockName: "F", value: 25, label: "アトピー/肌のアレルギー" },
	{ blockName: "F", value: 26, label: "花粉/PM2.5/ハウスダストなどによる肌荒れ" },
] as const;

export const makeSkinAttributeChoiceGroups = () => {
	const skinAttributeChoiceGroups: ChoiceGroup[] = [];

	const blockNames = Array.from(new Set(skinAttributeOptionsWithBlock.map((option) => option.blockName)));

	const skinAttributeGroupByBlockName = blockNames.map((blockName) => {
		return skinAttributeOptionsWithBlock.filter((option) => option.blockName === blockName);
	});

	skinAttributeGroupByBlockName.forEach((options, index) => {
		skinAttributeChoiceGroups.push({
			id: index + 1,
			choices: options.map((option) => {
				return { value: option.value, text: option.label };
			}),
		});
	});
	return skinAttributeChoiceGroups;
};
