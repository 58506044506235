/* eslint-disable @typescript-eslint/naming-convention */
export enum GypsyChoice {
	"決まった商品・ブランドがあり愛用している",
	"決まった商品・ブランドはなく、その時々でより効果がありそうなものを探して選んでいる",
	"決まった商品・ブランドはないが、あまり考えずにその時の気持ちで選んでいる",
	"決まった商品・ブランドはなく、その時の価格で選んでいる",
	"わからない／この中にはひとつもない",
}

export type GypsyChoiceType = keyof typeof GypsyChoice;
