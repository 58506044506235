/* eslint-disable react/jsx-newline */

import * as React from "react";

import { adCopySimulationValueLabels } from "../../../../../../../../label/ad-copy/simulation-value";
import type { SimulationData } from "../../../../../../../../server/models/ad-copy/output";
import type { AdCopyCorrectionValue } from "../../../../../../../../server/models/ad-copy/simulation-value";
import { Help } from "../../../../../parts/help";
import { AdCopyValueRow } from "./ad-copy-value-row";

export type AdCopyCorrectionValueSettingProps = {
	correctionValue: AdCopyCorrectionValue;
	hasEditRole: boolean;
	initInboundRatio: number;
	initLocalRatio: number;
	maxRecognition: number;
	onChangeRatio: <K extends keyof AdCopyCorrectionValue>(name: K, value: AdCopyCorrectionValue[K]) => void;
	value: SimulationData;
};

// 補整変数テーブル

export const AdCopyCorrectionValueSetting = React.memo((props: AdCopyCorrectionValueSettingProps) => {
	const { correctionValue, hasEditRole, initInboundRatio, initLocalRatio, maxRecognition, onChangeRatio, value } =
		props;

	return (
		<table className="result-table simulation">
			<thead>
				<tr>
					<th className="subtitle">補整変数</th>

					<th>
						初期値
						<Help explain="初期値は、表示されていない小数点2位以下の数値も計算には参照しています。" id="initial" />
					</th>

					<th>
						補整値
						<Help
							explain="初期値から変更しない場合は、手入力ではなく、必ず「初期値から変更しない」ボタンを押してください。"
							id="correctionValue"
						/>
					</th>

					<th>補整率（％）</th>

					<th>補整根拠（初期値から変更した場合、入力必須）</th>
				</tr>
			</thead>

			<tbody>
				{/* 商品認知率(%) */}
				<AdCopyValueRow
					hasEditRole={hasEditRole}
					init={value.goalRecognitionRate}
					label={
						<>
							{adCopySimulationValueLabels.recognitionRate}

							<Help explain={`ブランド知名率の上限：${maxRecognition}以下で入力してください。`} id="max-regognition" />
						</>
					}
					min={1}
					onChangeReason={(v) => {
						onChangeRatio("recognitionRateReason", v);
					}}
					onChangeValue={(v) => {
						onChangeRatio("recognitionRate", v);
					}}
					reason={correctionValue.recognitionRateReason}
					step={1}
					value={correctionValue.recognitionRate}
				/>

				{/* 購入者あたり年間購入個数 */}
				<AdCopyValueRow
					hasEditRole={hasEditRole}
					init={value.referenceData.gRepeat}
					label={
						<>
							購入者あたり
							<br />
							年間購入個数
							<Help explain="1.0以上で入力ください。" id="max-purchaseQuantity" />
						</>
					}
					min={1}
					onChangeReason={(v) => {
						onChangeRatio("purchaseQuantityReason", v);
					}}
					onChangeValue={(v) => {
						onChangeRatio("purchaseQuantity", v);
					}}
					reason={correctionValue.purchaseQuantityReason}
					value={correctionValue.purchaseQuantity}
				/>

				{/* ローカルその他*比率(％) */}
				<AdCopyValueRow
					hasEditRole={hasEditRole}
					init={initLocalRatio}
					isPercent
					label={
						<>
							{adCopySimulationValueLabels.localRatio}

							<br />

							<span className="small-font">※トライアル+リピートに対する</span>
						</>
					}
					onChangeReason={(v) => {
						onChangeRatio("localRatioReason", v);
					}}
					onChangeValue={(v) => {
						onChangeRatio("localRatio", v);
					}}
					reason={correctionValue.localRatioReason}
					value={correctionValue.localRatio}
				/>

				{/* インバウンド比率(%) */}
				<AdCopyValueRow
					hasEditRole={hasEditRole}
					init={initInboundRatio}
					isPercent
					label={
						<>
							{adCopySimulationValueLabels.inboundRatio}

							<br />

							<span className="small-font">※トライアル+リピートに対する</span>
						</>
					}
					onChangeReason={(v) => {
						onChangeRatio("inboundRatioReason", v);
					}}
					onChangeValue={(v) => {
						onChangeRatio("inboundRatio", v);
					}}
					reason={correctionValue.inboundRatioReason}
					value={correctionValue.inboundRatio}
				/>
			</tbody>
		</table>
	);
});
