import * as React from "react";

import { calcEstimatedValue, hasAdCopyCorrectionValues } from "../../../../../../../../server/lib/ad-copy/common";
import type { SimulationData } from "../../../../../../../../server/models/ad-copy/output";
import type {
	AdCopySimulationBrandData,
	AdCopySimulationPredictionData,
} from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import type { StokedBarGroup } from "../../../../../parts/ad-copy/stocked-bar-graph";
import { AdCopyStockedBarGraph } from "../../../../../parts/ad-copy/stocked-bar-graph";
import { AdCopyCPCorrectionValueTable } from "./cp-correction-value";
import { AdCopyGoalTable } from "./goal-table";
import { AdCopyResultTable } from "./result-table";
import { AdCopyUserTable } from "./user-table";

type Props = {
	constValue: AdCopySimulationBrandData;
	cpSimulationData: SimulationData | undefined;
	currentProductPurchaseRate: number | undefined;
	data: AdCopySimulationPredictionData;
	goalSalesVolume: number;
	hasCurrentProduct: boolean;
	isExistingBrand: boolean;
	onsData: AdCopySimulationBrandData;
	trialSalesVolumeBeforeCorrectionByCurrentProduct: number | undefined;
};

export const AdCopySimulationResult = React.memo((props: Props) => {
	const {
		constValue,
		cpSimulationData,
		currentProductPurchaseRate,
		data,
		goalSalesVolume,
		hasCurrentProduct,
		isExistingBrand,
		onsData,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	} = props;

	const {
		correctionValue,
		cpCurrentProductPurchaseRate,
		cpSimulation,
		cpTrialSalesVolumeBeforeCorrectionByCurrentProduct,
		initSimulationData,
		simulationBaseData,
	} = data;

	const cpCorrectionValue = React.useMemo(() => {
		if (
			cpSimulationData?.currentProductTrialSalesVolume != null &&
			cpSimulationData?.currentProductTrialSalesVolumeBeforeCorrection
		) {
			return (
				cpSimulationData.currentProductTrialSalesVolume /
				cpSimulationData.currentProductTrialSalesVolumeBeforeCorrection
			);
		}

		if (initSimulationData.currentProductTrialSalesVolume != null && trialSalesVolumeBeforeCorrectionByCurrentProduct) {
			return initSimulationData.currentProductTrialSalesVolume / trialSalesVolumeBeforeCorrectionByCurrentProduct;
		}
	}, [
		cpSimulationData?.currentProductTrialSalesVolume,
		cpSimulationData?.currentProductTrialSalesVolumeBeforeCorrection,
		initSimulationData.currentProductTrialSalesVolume,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	]);

	// 初期値(万個)
	const initialSellPotential = React.useMemo(
		() => ({
			// トライアル
			trial: initSimulationData.trialSalesVolumeAddUnreported,

			// リピート
			repeat: initSimulationData.repeatSalesVolumeAddUnreported,

			// ローカルその他
			localOther: initSimulationData.localOtherSalesVolume,

			// インバウンド
			inbound: initSimulationData.inboundBuyerSalesVolume,

			// 合計
			total: initSimulationData.potentialSalesVolume,

			// 目標対比(%)
			toTargetSalesVolume: initSimulationData.ratioToTargetSalesVolume,
		}),
		[initSimulationData],
	);

	const shouldShowCp =
		hasCurrentProduct &&
		cpSimulation != null &&
		(cpSimulation.trial !== initialSellPotential.trial ||
			cpSimulation.repeat !== initialSellPotential.repeat ||
			cpSimulation.localOther !== initialSellPotential.localOther ||
			cpSimulation.inbound !== initialSellPotential.inbound);

	// ①実績値 修正後
	const cpSellPotential = cpSimulation ?? initialSellPotential;

	// ②テスト品 変数補整後
	const testEstimatedValue = React.useMemo(
		() =>
			calcEstimatedValue(
				simulationBaseData,
				correctionValue,
				cpCurrentProductPurchaseRate ?? currentProductPurchaseRate,
				cpTrialSalesVolumeBeforeCorrectionByCurrentProduct ?? trialSalesVolumeBeforeCorrectionByCurrentProduct,
			),
		[
			correctionValue,
			cpCurrentProductPurchaseRate,
			cpTrialSalesVolumeBeforeCorrectionByCurrentProduct,
			currentProductPurchaseRate,
			simulationBaseData,
			trialSalesVolumeBeforeCorrectionByCurrentProduct,
		],
	);

	const shouldShowTest =
		hasAdCopyCorrectionValues(correctionValue) &&
		(testEstimatedValue.trial !== cpSellPotential.trial ||
			testEstimatedValue.repeat !== cpSellPotential.repeat ||
			testEstimatedValue.localOther !== cpSellPotential.localOther ||
			testEstimatedValue.inbound !== cpSellPotential.inbound);

	const testSellPotential = React.useMemo(
		() =>
			shouldShowTest
				? testEstimatedValue
				: {
						inbound: undefined,
						localOther: undefined,
						repeat: undefined,
						total: undefined,
						trial: undefined,
				  },
		[shouldShowTest, testEstimatedValue],
	);

	const salesData = React.useMemo<StokedBarGroup[]>(() => {
		const sellPotentials = [
			{
				label: "初期値（万個）",
				sellPotential: initialSellPotential,
			},
			{
				label: "①実績値 修正後\n（万個）",
				sellPotential: shouldShowCp
					? cpSimulation
					: {
							inbound: undefined,
							localOther: undefined,
							repeat: undefined,
							total: undefined,
							trial: undefined,
					  },
			},
			{
				label: "②テスト品 変数補整後\n（万個）",
				sellPotential: testSellPotential,
			},
		];

		return sellPotentials.map(({ label, sellPotential }) => ({
			bars: [
				{ color: "#f56302", label: "トライアル", value: sellPotential.trial },
				{ color: "#ffc000", label: "リピート", value: sellPotential.repeat },
				{ color: "#83db4d", label: "ローカルその他", value: sellPotential.localOther },
				{ color: "#6492e3", label: "インバウンド", value: sellPotential.inbound },
			],
			label,
			total: sellPotential.total,
		}));
	}, [cpSimulation, initialSellPotential, shouldShowCp, testSellPotential]);

	const graphBase = React.useMemo(() => {
		const tmp = Math.max(...salesData.map((d) => d.bars.reduce((a, b) => a + (b.value || 0), 0)), goalSalesVolume);

		if (tmp < 10) {
			const max = Math.floor(tmp + 1);

			return {
				max,
				step: Math.floor((max / 10) * 100) / 100,
			};
		}

		const max = Math.ceil(Math.round(tmp / 10) + 1) * 10;

		let step = Math.ceil(max / 10);

		if (5 < step && step < 10) {
			step = 10;
		} else if (step % 10 !== 0) {
			step = Math.ceil(step / 10) * 10;
		}

		return {
			max,
			step,
		};
	}, [goalSalesVolume, salesData]);

	return (
		<table className="wrap-table">
			<tbody>
				<tr>
					<td className="table-area">
						{/* 基人口、購入意向ワンナンバースコア、定数テーブル */}
						<AdCopyUserTable
							constValue={constValue}
							isExistingBrand={isExistingBrand}
							onsData={onsData}
							simulationReferenceData={initSimulationData.referenceData}
						/>

						{/* 判定基準テーブル */}
						<AdCopyGoalTable goalSalesVolume={goalSalesVolume} />

						{/* 実績補整係数テーブル */}
						{cpCorrectionValue != null && <AdCopyCPCorrectionValueTable cpCorrectionValue={cpCorrectionValue} />}

						{/* 推定値テーブル */}
						<AdCopyResultTable
							cpValues={cpSellPotential}
							hasCurrentProduct={shouldShowCp}
							initialValues={initialSellPotential}
							testValues={testSellPotential}
						/>
					</td>

					{/* グラフ */}
					<td>
						<AdCopyStockedBarGraph
							barGroups={salesData}
							lineValue={goalSalesVolume}
							max={graphBase.max}
							step={graphBase.step}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
});
