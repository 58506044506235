import { SurveyChoice } from "../../shared";
import { CategoryOrAndType } from "./category-and-or";

/**
 *
 * 過去一年非購入カテゴリ設定
 */
export type NonPurchaseCategoryConfig = {
	surveyChoices: SurveyChoice[];
	categoryOrAnd: CategoryOrAndType;
};
