/* eslint-disable react/jsx-newline */
import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import type { Category } from "../../../../../../../server/models/category";
import { AccordionForm } from "../../../../parts/accodion-form";
import { BrandSelect } from "./brand-select";
import { StrategyGroup, StrategyGroupCategory } from "../../../../../../../server/models/activity";
import { strategyGroupCategoryLabels } from "../../../../../../../label/activity";
import { highlight非 } from "./utils";

type Props = {
	categoryMap: Record<string, Category>;
	onChangeStrategyGroupCategory: (surveyChoice: number, name: keyof StrategyGroupCategory, value: any) => void;
	strategyGroup: StrategyGroup;
	surveyChoiceMap: Map<number, string>;
};

/**
 * 過去1年非購入ブランド
 */
export const UnusedBrandForms = React.memo((props: Props) => {
	const { categoryMap, onChangeStrategyGroupCategory, strategyGroup, surveyChoiceMap } = props;

	return (
		<Row>
			<FormGroup as={Col}>
				<AccordionForm
					defaultShow={strategyGroup.category?.some((cate) => !!cate.unusedBrand && cate.unusedBrand.length !== 0)}
					eventKey="unusedBrand"
					title={highlight非(strategyGroupCategoryLabels.unusedBrand)}
				>
					<Row style={{ paddingLeft: "15px" }}>
						{strategyGroup.category?.map((category) => (
							<Col
								key={`unusedBrand-category-${category.category._id?.toString()}`}
								md={5}
								style={{ paddingBottom: "1em" }}
							>
								{" "}
								{surveyChoiceMap.get(category.surveyChoice)}
								<BrandSelect
									category={categoryMap[category.category._id?.toString() ?? ""]}
									filteredValue={[
										// 過去1年購入＆使用ブランド
										...(category.usedBrand ?? []),

										// NOTE: 本当になくなったら消す
										// 現在使用ブランド
										// ...(category.currentlyUsedBrand ?? []),
										// 現在使用していないブランド
										// ...(category.currentlyUnusedBrand ?? []),
									]}
									onChange={(values) => {
										onChangeStrategyGroupCategory(category.surveyChoice, "unusedBrand", values);
									}}
									values={category.unusedBrand}
								/>
							</Col>
						))}
					</Row>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
