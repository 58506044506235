/* eslint-disable @typescript-eslint/naming-convention */
export enum Channel {
	"化粧品専門店（駅ビルや商業施設・地下街などのお店、町の路面店など）",
	"デパート・百貨店",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）",
	"スーパーマーケット",
	"ドラッグストア・薬局・薬店",
	"ホームセンター・ディスカウントストア",
	"コンビニエンスストア",
	"バラエティストア（PLAZA、ショップイン、東急ハンズ、Loftなど）",
	"ブランド直営店（ザ・ボディショップ、ロクシタン、ファンケル、DHC、オルビスなど）",
	"美容院",
	"訪問販売",
	"インターネット通販",
	"通信販売（インターネットを除く）",
	"エステサロン",
	"皮膚科／美容クリニック",
	"その他",
}
