import * as React from "react";

import { decimalCalc } from "../../../../../../../../../server/lib/ad-copy/aggregate/common";

type Props = {
	cpCorrectionValue: number;
};

// 実数補正係数テーブル

export const AdCopyCPCorrectionValueTable = React.memo((props: Props) => {
	const { cpCorrectionValue } = props;

	const correctionValue = React.useMemo(
		() => decimalCalc(cpCorrectionValue, Math.pow(10, 3)).toFixed(3),
		[cpCorrectionValue],
	);

	return (
		<table
			className="correct-table"
			style={{
				display: "inline-table",
				marginLeft: "10%",
				width: "20%",
			}}
		>
			<thead>
				<tr>
					<th>実数補正係数</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>{correctionValue}</td>
				</tr>
			</tbody>
		</table>
	);
});
