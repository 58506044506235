import * as React from "react";

import { strategyTargetGroupLabels } from "../../../../../../label/activity";
import type { Gypsy } from "../../../../../../server/models/activity";
import { GroupPanel } from "./group-panel";
import { composeGypsySubLabel } from "../../../../../../server/lib/quota/helpers";

type Props = {
	gypsy: Gypsy;
};

export const GypsyGroupPanel = React.memo((props: Props) => {
	const { gypsy } = props;

	const subLabelString = React.useMemo(() => composeGypsySubLabel(gypsy), [gypsy]);

	return (
		<GroupPanel label={strategyTargetGroupLabels.gypsy ?? ""} value={`${gypsy.surveyChoice.label}${subLabelString}`} />
	);
});
