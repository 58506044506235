/* eslint-disable @typescript-eslint/naming-convention */
export enum ChannelV2 {
	"デパート・百貨店（三越、高島屋、伊勢丹、大丸など）にある化粧品フロアのカウンター",
	"デパート・百貨店（三越、高島屋、伊勢丹、大丸など）に出店している化粧品店",
	"大型ショッピングモール（イオンモール、ららぽーと、アウトレットモールなど）に出店している化粧品店",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）のセルフ販売形式の化粧品売場",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）にある化粧品フロアのカウンター",
	"総合スーパー（イオン、イトーヨーカドー、ダイエーなど）に出店している化粧品店",
	"ドラッグストア（マツモトキヨシ、ココカラファイン、ツルハドラッグなどのチェーン店）",
	"薬局・薬店（個人経営や調剤薬局など）",
	"化粧品店①（※駅ビルや商業施設、地下街などの専門店街に入っているお店）",
	"化粧品店②（※町の化粧品店など路面店タイプ）",
	"ブランド直営店（ザ・ボディショップ、ロクシタン、ファンケル、ＤＨＣ、オルビスなど）",
	"バラエティストア（PLAZA、ショップイン、東急ハンズ、Loftなど）",
	"ファッション・インテリア・雑貨系ショップ（無印良品、ZARA、Francfrancなど）",
	"ディスカウントストア／ホームセンター（ドン・キホーテ、カインズホームなど）",
	"コンビニエンスストア（セブンイレブン、ローソン、ファミリーマートなど）",
	"ダイソーやセリア、3コインズなどの●●円均一ショップ",
	"免税店・機内販売",
	"家電量販店",
	"スパ／エステ／美容院",
	"皮膚科／美容クリニック",
	"通信販売（テレビショッピング／カタログ／メールオーダー）（ショップジャパンやディノスなど）",
	"インターネット・ＷＥＢ（メーカーまたはブランドの直販サイト）",
	"インターネット・ＷＥＢ（小売業者サイト［楽天市場、アマゾン、Ｙａｈｏｏ！ショッピング、ネットスーパーなど］、ヤフオク！など）",
	"訪問販売",
	"その他",
}
