/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Button } from "react-bootstrap";

import type { EvaluationResult } from "../../../../../../../../server/ad-copy-analytics/simulation/lib/evaluations";
import type { AdCopyActivityWithBrand } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyTestOnlyConceptKey } from "../../../../../../../../server/models/ad-copy/concept";
import { AdCopyConceptTypeMap } from "../../../../../../../../server/models/ad-copy/concept";
import type { AdCopyCP } from "../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../server/models/ad-copy/job";
import type {
	AdCopySimulationBrandData,
	AdCopySimulationPredictionData,
	GetAdCopySimulationResponse,
} from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import type { AdCopyCorrectionValueSettingProps } from "../correction-value";
import { AdCopyCorrectionValueSetting } from "../correction-value";
import { AdCopyNormTable } from "../norm-table";
import { AdCopySimulationResult } from "../result";

type Props<T extends AdCopyTestOnlyConceptKey> = {
	activity: AdCopyActivityWithBrand;
	constValue: GetAdCopySimulationResponse["constValue"];
	cp: Partial<AdCopyCP> | undefined;
	currentProductPurchaseRate: GetAdCopySimulationResponse["currentProductPurchaseRate"];
	data: AdCopySimulationPredictionData;
	evaluationResult: EvaluationResult<T>;
	goalSalesVolume: number;
	hasCurrentProduct: boolean;
	hasEditRole: boolean;
	job: AdCopyJob;
	maxRecognition: number;
	onChangeRatio: AdCopyCorrectionValueSettingProps["onChangeRatio"];
	onSimulation: () => void;
	onSimulationSave: () => void;
	onsData: AdCopySimulationBrandData;
	targetConceptType: T;
	trialSalesVolumeBeforeCorrectionByCurrentProduct: number | undefined;
};

const AdCopySimulationOneComponent = <T extends AdCopyTestOnlyConceptKey>({
	activity,
	constValue,
	cp,
	currentProductPurchaseRate,
	data,
	evaluationResult,
	goalSalesVolume,
	hasCurrentProduct,
	hasEditRole,
	job,
	maxRecognition,
	onChangeRatio,
	onSimulation,
	onSimulationSave,
	onsData,
	targetConceptType,
	trialSalesVolumeBeforeCorrectionByCurrentProduct,
}: Props<T>) => {
	// 相対パスだとサーバ側でよむことができないので、絶対パスで画像を読み込む必要がある。
	// const baseUrl = location.origin;

	return (
		<>
			{hasEditRole && (
				<>
					<Button onClick={onSimulationSave} style={{ margin: "10px 0px" }} variant="outline-secondary">
						保存
					</Button>

					<Button onClick={onSimulation} style={{ margin: "10px" }} variant="outline-secondary">
						画像Download
					</Button>
				</>
			)}
			<div className="simulation-container">
				<div className="simulation-headline row">
					<div className="concept-title item">
						<div className="content-left">提示コンセプト：{AdCopyConceptTypeMap[targetConceptType]}</div>
					</div>
					<div className="jobno item">
						<div className="content-right">
							JOB#: {job.jobNum}（{activity.category.name}、{activity.brand.name}）
						</div>
					</div>
				</div>

				<div className="simulation-body row">
					<div className="main item">
						<div className="image-area">
							<img src={data.conceptUrl} />
						</div>
					</div>
					<div className="column">
						<div className="key-indicators row">
							<div className="title-area item">
								<div className="rotate-label">主要指標</div>
							</div>
							<div className="graph-area item">
								<div className="content-left">
									<AdCopyNormTable cp={cp} evaluationResult={evaluationResult} targetConceptType={targetConceptType} />
									<div className="explain">＊購入意向はTB,T2Bをウエイト処理したワンナンバースコアで表示/計算</div>
								</div>
							</div>
						</div>
						<div className="separator row">
							<hr className="dashed" />
						</div>
						<div className="estimated-cp row">
							<div className="title-area item">
								<div className="rotate-label">推定CP（発売後12ヶ月）</div>
							</div>
							<div className="graph-area item">
								<div className="content">
									{/* 補整変数 */}
									<AdCopyCorrectionValueSetting
										correctionValue={data.correctionValue ?? {}}
										hasEditRole={hasEditRole}
										initInboundRatio={data.initSimulationData.referenceData.gRatio_IBBY}
										initLocalRatio={data.initSimulationData.referenceData.gRatio_LocalOther}
										maxRecognition={maxRecognition}
										onChangeRatio={onChangeRatio}
										value={data.initSimulationData}
									/>

									{/* 固定変数、判定基準、推定値、グラフ */}
									<AdCopySimulationResult
										constValue={constValue}
										cpSimulationData={cp?.cpSimulation?.[targetConceptType]}
										currentProductPurchaseRate={currentProductPurchaseRate}
										data={data}
										goalSalesVolume={goalSalesVolume}
										hasCurrentProduct={hasCurrentProduct}
										isExistingBrand={activity.isExistingBrand ?? false}
										onsData={onsData}
										trialSalesVolumeBeforeCorrectionByCurrentProduct={trialSalesVolumeBeforeCorrectionByCurrentProduct}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const AdCopySimulationOne = React.memo(AdCopySimulationOneComponent) as typeof AdCopySimulationOneComponent;
