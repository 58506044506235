import * as React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

import type { OutputTableData } from "../../../../../../../server/models/ad-copy/output";
import { TextRed } from "../../../../parts/font";
import { AdCopyReportTitle } from "../parts/report-title";
import { OutputTbody } from "./output-tbody";
import { OutputThead } from "./output-thead";

const StyledTable = styled.table`
	td,
	th {
		border: 1px dotted #888;
		padding: 3px;
	}

	td {
		min-width: 6rem;
	}
`;

type Props = {
	differentiationAndONS: OutputTableData;
	navigateSimulationPage: () => void;
	simulations: OutputTableData | undefined;
};

export const AdCopyResultSummary = React.memo((props: Props) => {
	const { differentiationAndONS, navigateSimulationPage, simulations } = props;

	return (
		<>
			<AdCopyReportTitle>結果サマリー</AdCopyReportTitle>

			{simulations != null && (
				<>
					<div>
						<Button onClick={navigateSimulationPage} size="sm" variant="outline-secondary">
							補整＆提案フォーマット
						</Button>
					</div>

					<p className="mt-1">
						<TextRed>※年間購買個数の数値は変数補整前の初期値</TextRed>
					</p>
				</>
			)}

			<StyledTable>
				{/* 需要予測 */}
				{simulations != null && (
					<>
						<OutputThead data={simulations.tHead} />

						{simulations.tBodies.map((tBody, i) => (
							<OutputTbody key={i} data={tBody} />
						))}
					</>
				)}

				{/* 差別性等 */}
				<OutputThead data={differentiationAndONS.tHead} />

				{differentiationAndONS.tBodies.map((tBody, i) => (
					<OutputTbody key={i} data={tBody} />
				))}
			</StyledTable>
		</>
	);
});
