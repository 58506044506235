import type { AdCopySearchConceptNormResponse } from "../../../../server/types/request/ad-copy/norm";
import type { SearchConceptNormResponse } from "../../../../server/types/request/norm";
import type { NormFlagsAction } from "../../types/actions";
import type { MessageInfo } from "../../types/info";

export type NormFlagsSearchValues = {
	activityNo: string;
	brandId: string;
	categoryId: string;
	checked: boolean | "";
	implementationPeriod: {
		from: string;
		to: string;
	};
	jobNum: string;
};

type State = {
	info: MessageInfo;
	loading: boolean;
	response: AdCopySearchConceptNormResponse | SearchConceptNormResponse;
	searchValues: NormFlagsSearchValues;
};

const initialSearchValues = {
	activityNo: "",
	brandId: "",
	categoryId: "",
	checked: false,
	implementationPeriod: {
		from: "",
		to: "",
	},
	jobNum: "",
} as const satisfies NormFlagsSearchValues;

export function createInitialState({
	activityNo,
	brandId,
	categoryId,
	checked,
	implementationPeriod,
	jobNum,
}: NormFlagsSearchValues): State {
	return {
		info: {
			isSuccess: true,
			message: "",
		},
		loading: true,
		response: {
			brandOptions: [],
			categoryOptions: [],
			limit: 100,
			page: 0,
			pages: 1,
			rows: [],
		},
		searchValues: {
			...initialSearchValues,
			activityNo,
			brandId,
			categoryId,
			checked,
			implementationPeriod,
			jobNum,
		},
	};
}

export const reducer = (state: State, action: NormFlagsAction): State => {
	switch (action.type) {
		case "loadNormFlags":
			return {
				...state,
				loading: false,
				response: action.payload,
			};

		case "changeSearch":
			return {
				...state,
				searchValues: {
					...state.searchValues,
					[action.payload.name]: action.payload.value,
				},
			};

		case "resetSearchValues": {
			return {
				...state,
				searchValues: initialSearchValues,
			};
		}

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
