import { categoryTargetGroupLabels } from "../../../../../../../label/activity";
import { makeAgeLabel, makePriceLabel } from "../../../../../../../server/lib/quota/helpers";
import { CategoryGroup } from "../../../../../../../server/models/activity";

export const categoryTargetGroupToConditionProps: (target: CategoryGroup) => { label: string; value: string }[] = (
	target,
) => {
	return [
		{ label: categoryTargetGroupLabels.age, value: makeAgeLabel(target.age) },
		{ label: categoryTargetGroupLabels.price, value: makePriceLabel(target.price) },
	];
};
