import * as React from "react";
import { Col, FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import Feedback from "react-bootstrap/Feedback";

import type { AdCopyJob } from "../../../../../../../server/models/ad-copy/job";
import type { ErrorObject } from "../../../../../../../server/types/error";
import { getErrorMessage } from "../../../../../lib/error";
import { AlertText } from "../../../../parts/font";

const name = "hasCurrentProduct";

const radioValues = {
	currentProduct: "currentProduct",
	false: "false",
	similarProduct: "similarProduct",
} as const;

type Props = {
	errors: ErrorObject | undefined;
	hasCurrentProduct: boolean | undefined;
	hasSimilarProduct: boolean | undefined;
	isRetest: boolean;
	needCurrentProduct: boolean;
	onChange: <T extends typeof name | "hasSimilarProduct">(name: T, value: AdCopyJob[T]) => void;
};

export const CurrentProductRadios = React.memo((props: Props) => {
	const { errors, hasCurrentProduct, hasSimilarProduct, isRetest, needCurrentProduct, onChange } = props;

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		if (value === radioValues.currentProduct) {
			onChange("hasCurrentProduct", true);
			onChange("hasSimilarProduct", false);

			return;
		}

		if (value === radioValues.similarProduct) {
			onChange("hasCurrentProduct", true);
			onChange("hasSimilarProduct", true);

			return;
		}

		onChange("hasCurrentProduct", false);
		onChange("hasSimilarProduct", false);
	};

	return (
		<FormGroup>
			<FormLabel>
				<span>現行品/類似品</span>

				<br />

				<AlertText>
					※現行品/類似品について1回目調査で実施した場合、再調査せず、1回目調査結果との比較となります。
				</AlertText>
			</FormLabel>

			{/* あり（現行品） */}
			<FormGroup as={Col} className="position-relative w-100 px-3">
				<FormCheck
					checked={(hasCurrentProduct && (hasSimilarProduct == null || !hasSimilarProduct)) ?? false}
					disabled={isRetest}
					label="あり（現行品）"
					name={name}
					onChange={handleChange}
					type="radio"
					value={radioValues.currentProduct}
				/>

				{/* あり（類似品） */}
				<FormCheck
					checked={(hasCurrentProduct && hasSimilarProduct) ?? false}
					disabled={isRetest}
					label="あり（類似品）"
					name={name}
					onChange={handleChange}
					type="radio"
					value={radioValues.similarProduct}
				/>

				{/* なし */}
				<FormCheck
					checked={hasCurrentProduct != null && !hasCurrentProduct}
					disabled={isRetest || needCurrentProduct}
					label="なし"
					name={name}
					onChange={handleChange}
					type="radio"
					value={radioValues.false}
				/>
			</FormGroup>

			{errors && name in errors && (
				<Feedback
					style={{
						display: "block",
					}}
					type="invalid"
				>
					{getErrorMessage(name, errors)}
				</Feedback>
			)}
		</FormGroup>
	);
});
