import * as React from "react";

type Props = {
	appearanceByStrategyTarget: number;
};

// 判定基準テーブル

export const AppearanceByStrategyTargetTable = React.memo((props: Props) => {
	const { appearanceByStrategyTarget } = props;

	return (
		<table className="result-table">
			<thead>
				<tr>
					<th
						style={{
							textAlign: "left",
						}}
					>
						固定変数
					</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td
						className="label-area"
						style={{
							width: "120px",
						}}
					>
						戦略ターゲット出現率
					</td>

					<td
						style={{
							width: "80px",
						}}
					>
						{(appearanceByStrategyTarget * 100).toFixed(1)}％
					</td>

					<td
						style={{
							border: 0,
							minWidth: "12rem",
							textAlign: "left",
						}}
					>
						*調査対象性の15～69歳人口ベース
					</td>
				</tr>
			</tbody>
		</table>
	);
});
