import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Loading } from "../../../../react-components/lu-component/src";
import type { AdCopyConceptNormUpdate } from "../../../../server/models/ad-copy/concept";
import type { ConceptNormUpdate } from "../../../../server/models/concept";
import { adCopyEndpoint as adCopyApiEndpoint } from "../../../../server/router/ad-copy/endpoint";
import { endpoint as conceptApiEndpoint } from "../../../../server/router/endpoint";
import type {
	AdCopyConceptNormUpdateResponse,
	AdCopySearchConceptNormRequest,
	AdCopySearchConceptNormResponse,
} from "../../../../server/types/request/ad-copy/norm";
import type {
	ConceptNormUpdateResponse,
	SearchConceptNormRequest,
	SearchConceptNormResponse,
} from "../../../../server/types/request/norm";
import { NormFlagsPage } from "../../components/pages/norm-flags";
import { ConfirmBox } from "../../components/parts/confirm-box";
import { buildUrl } from "../../lib/build-url";
import { emptyToUndefined } from "../../lib/empty-to-undefined";
import { makeError, post, put } from "../../lib/request";
import type { NormFlagsSearchValues } from "../../reducers/norm-flags";
import { createInitialState, reducer } from "../../reducers/norm-flags";
import { adCopyClientEndpoint } from "../../routes/adCopyEndpoint";
import { clientEndpoint as conceptClientEndpoint } from "../../routes/endpoint";

type Props = {
	surveyType: "ad-copy" | "concept";
};

export const NormFlagsContainer = ({ surveyType }: Props) => {
	const navigate = useNavigate();

	const location = useLocation();

	// クエリストリングを含む現在のURLパス
	const currentUrl = `${location.pathname}${location.search}`;

	const apiEndpoint = surveyType === "concept" ? conceptApiEndpoint : adCopyApiEndpoint;
	const clientEndpoint = surveyType === "concept" ? conceptClientEndpoint : adCopyClientEndpoint;

	const [searchParams] = useSearchParams();

	const searchParamValues = React.useMemo(
		() => ({
			activityNo: searchParams.get("activityNo") ?? undefined,
			jobNum: searchParams.get("jobNum") ?? undefined,

			categoryId: searchParams.get("categoryId") ?? undefined,

			brandId: searchParams.get("brandId") ?? undefined,
			checked:
				searchParams.get("checked") === "true" ? true : searchParams.get("checked") === "false" ? false : undefined,
			implementationPeriod: {
				from: searchParams.get("periodFrom") ?? undefined,
				to: searchParams.get("periodTo") ?? undefined,
			},

			limit: searchParams.get("limit") ?? "100",
			page: searchParams.get("page") ?? "0",
		}),
		[searchParams],
	);

	const [state, dispatch] = React.useReducer(
		reducer,
		{
			activityNo: searchParamValues.activityNo ?? "",
			jobNum: searchParamValues.jobNum ?? "",

			categoryId: searchParamValues.categoryId ?? "",

			brandId: searchParamValues.brandId ?? "",
			checked: searchParamValues.checked ?? "",
			implementationPeriod: {
				from: searchParamValues.implementationPeriod.from ?? "",
				to: searchParamValues.implementationPeriod.to ?? "",
			},
		},
		createInitialState,
	);

	const { info, loading, response, searchValues } = state;

	const postSearch = React.useCallback(
		({
			data,
			limit,
			page,
		}: {
			data: Omit<AdCopySearchConceptNormRequest | SearchConceptNormRequest, "isNormTarget">;
			limit: string;
			page: string;
		}) => {
			const url = `${apiEndpoint.conceptNorm}?limit=${limit}&page=${page}`;

			post<AdCopySearchConceptNormResponse | SearchConceptNormResponse>(url, data)
				.then((response) => {
					dispatch({
						payload: response.data,
						type: "loadNormFlags",
					});
				})
				.catch((error) => {
					dispatch({
						payload: makeError(error),
						type: "changeMessageInfo",
					});
				});
		},
		[apiEndpoint.conceptNorm],
	);

	React.useEffect(() => {
		postSearch({
			data: {
				activityNo: searchParamValues.activityNo == null ? undefined : Number(searchParamValues.activityNo),
				jobNum: searchParamValues.jobNum,

				categoryId: searchParamValues.categoryId,

				brandId: searchParamValues.brandId,
				checked: searchParamValues.checked,
				implementationPeriod: searchParamValues.implementationPeriod,
			},
			limit: searchParamValues.limit,
			page: searchParamValues.page,
		});
	}, [postSearch, searchParamValues]);

	const onPaging = React.useCallback(
		(page: number) => {
			const targetUrl = buildUrl([clientEndpoint.normFlags], {
				activityNo: searchParamValues.activityNo,
				jobNum: searchParamValues.jobNum,

				categoryId: searchParamValues.categoryId,

				brandId: searchParamValues.brandId,
				checked: searchParamValues.checked?.toString(),

				periodFrom: searchParamValues.implementationPeriod.from,
				periodTo: searchParamValues.implementationPeriod.to,

				limit: searchParamValues.limit,
				page: page.toString(),
			});

			dispatch({
				payload: true,
				type: "changeLoading",
			});

			navigate(targetUrl);
		},
		[clientEndpoint.normFlags, navigate, searchParamValues],
	);

	const onChangeSearchValue = React.useCallback(
		<T extends keyof NormFlagsSearchValues>(name: T, value: NormFlagsSearchValues[T]) => {
			dispatch({
				payload: {
					name,
					value,
				},
				type: "changeSearch",
			});
		},
		[],
	);

	const onSearch = React.useCallback(() => {
		const limit = searchParamValues.limit;
		const page = "0";

		const targetUrl = buildUrl([clientEndpoint.normFlags], {
			activityNo: emptyToUndefined(searchValues.activityNo.trim()),
			jobNum: emptyToUndefined(searchValues.jobNum.trim()),

			categoryId: emptyToUndefined(searchValues.categoryId),

			brandId: emptyToUndefined(searchValues.brandId),
			checked: searchValues.checked === true ? "true" : searchValues.checked === false ? "false" : undefined,

			periodFrom: emptyToUndefined(searchValues.implementationPeriod.from),
			periodTo: emptyToUndefined(searchValues.implementationPeriod.to),

			limit,
			page,
		});

		dispatch({
			payload: true,
			type: "changeLoading",
		});

		if (targetUrl === currentUrl) {
			postSearch({
				data: {
					activityNo: searchValues.activityNo === "" ? undefined : Number(searchValues.activityNo.trim()),
					jobNum: emptyToUndefined(searchValues.jobNum.trim()),

					categoryId: emptyToUndefined(searchValues.categoryId),

					brandId: emptyToUndefined(searchValues.brandId),
					checked: emptyToUndefined(searchValues.checked),
					implementationPeriod: {
						from: emptyToUndefined(searchValues.implementationPeriod.from),
						to: emptyToUndefined(searchValues.implementationPeriod.to),
					},
				},
				limit,
				page,
			});
		} else {
			navigate(targetUrl);
		}
	}, [clientEndpoint.normFlags, currentUrl, navigate, postSearch, searchParamValues.limit, searchValues]);

	const onReset = React.useCallback(() => {
		dispatch({
			payload: undefined,
			type: "resetSearchValues",
		});

		const limit = searchParamValues.limit;
		const page = "0";

		const targetUrl = buildUrl([clientEndpoint.normFlags], {
			checked: "false",
			limit,
			page,
		});

		dispatch({
			payload: true,
			type: "changeLoading",
		});

		if (targetUrl === currentUrl) {
			postSearch({
				data: {
					checked: false,
				},
				limit,
				page,
			});
		} else {
			navigate(targetUrl);
		}
	}, [clientEndpoint.normFlags, currentUrl, navigate, postSearch, searchParamValues.limit]);

	// ノーム対象と理由の保存処理
	const onSave = React.useCallback(
		({ conceptId, data }: { conceptId: string; data: AdCopyConceptNormUpdate | ConceptNormUpdate }) => {
			dispatch({
				payload: true,
				type: "changeLoading",
			});

			put<AdCopyConceptNormUpdateResponse | ConceptNormUpdateResponse>(`${apiEndpoint.conceptNorm}/${conceptId}`, data)
				.then(() => {
					dispatch({
						payload: {
							isSuccess: true,
							message: "保存が完了しました。",
						},
						type: "changeMessageInfo",
					});
				})
				.catch((error) => {
					dispatch({
						payload: makeError(error),
						type: "changeMessageInfo",
					});
				});
		},
		[apiEndpoint.conceptNorm],
	);

	return (
		<>
			<Loading loading={loading} />

			<ConfirmBox info={info} titleLabel="処理" />

			<NormFlagsPage
				brandOptions={response.brandOptions}
				categoryOptions={response.categoryOptions}
				concepts={response.rows}
				currentPageIndex={response.page}
				lastPageNum={response.pages}
				onChangeSearchValue={onChangeSearchValue}
				onPaging={onPaging}
				onReset={onReset}
				onSave={onSave}
				onSearch={onSearch}
				searchValues={searchValues}
			/>
		</>
	);
};
