/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import type { Choice } from "../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../react-components/lu-component/src/index";

import { AccordionForm } from "../../../../parts/accodion-form";
import {
	SensitiveSkin,
	sensitiveSkinFrequencyRankOptions,
	SensitiveSkinType,
	StrategyGroup,
} from "../../../../../../../server/models/activity";
import { strategyTargetGroupLabels } from "../../../../../../../label/activity";

type Props = {
	onChange: (name: keyof StrategyGroup, value: any) => void;
	strategyGroup: StrategyGroup;
};

export const SensitiveSkinFrequencyCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldHide =
		strategyGroup.sensitiveSkin == null ||
		strategyGroup.sensitiveSkin.length === 0 ||
		strategyGroup.sensitiveSkin.includes(SensitiveSkin.敏感肌ではない.toString() as SensitiveSkinType);

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={strategyGroup.sensitiveSkinFrequency != null && 0 < strategyGroup.sensitiveSkinFrequency.length}
					eventKey="sensitiveSkinFrequency"
					title={strategyTargetGroupLabels.sensitiveSkinFrequency ?? ""}
				>
					{shouldHide ? (
						<></>
					) : (
						<Checkboxes
							checkeds={strategyGroup.sensitiveSkinFrequency ?? []}
							choices={sensitiveSkinFrequencyRankOptions as Choice[]}
							name="sensitiveSkinFrequency"
							onChange={(value) => {
								onChange("sensitiveSkinFrequency", value);
							}}
						/>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
