/* eslint-disable react/jsx-newline */

import * as React from "react";

import type { AdCopyActivity } from "../../../../../../../server/models/ad-copy/activity";
import { AdCopyReportTitle } from "../parts/report-title";

type Props = {
	activity: AdCopyActivity;
};

export const ActionStandards = React.memo((props: Props) => {
	const { activity } = props;

	return (
		<>
			<AdCopyReportTitle>アクションスタンダード</AdCopyReportTitle>

			{activity.actionStandards?.map((x, i) => (
				<div key={i}>
					アクションスタンダード{i + 1}. {x}
				</div>
			))}
		</>
	);
});
