export const noSettingStrategyGroupReason = {
	noCorrespondingItem: "Testitに対応する項目がない",
	sameAsCategoryTargetGroup: "カテゴリターゲットグループと同じでよい（※提案時に説明必須）",
	other: "その他",
} as const;

export type NoSettingStrategyGroupReason = keyof typeof noSettingStrategyGroupReason;

export const noSettingStrategyGroupReasonKeys = Object.keys(
	noSettingStrategyGroupReason,
) as NoSettingStrategyGroupReason[];

export type NoSettingStrategyGroup = {
	reasons: NoSettingStrategyGroupReason[];
	specifically?: string;
};
