/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Button } from "react-bootstrap";

import type { EvaluationResult } from "../../../../../../../../server/ad-copy-analytics/simulation/lib/evaluations";
import type { AdCopyActivityWithBrand } from "../../../../../../../../server/models/ad-copy/activity";
import type { AdCopyTestOnlyConceptKey } from "../../../../../../../../server/models/ad-copy/concept";
import { AdCopyConceptTypeMap } from "../../../../../../../../server/models/ad-copy/concept";
import type { AdCopyCP } from "../../../../../../../../server/models/ad-copy/cp";
import type { AdCopyJob } from "../../../../../../../../server/models/ad-copy/job";
import type {
	AdCopySimulationBrandData,
	AdCopySimulationPredictionData,
	GetAdCopySimulationResponse,
} from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import type { AdCopyCorrectionValueSettingProps } from "../correction-value";
import { AdCopyCorrectionValueSetting } from "../correction-value";
import { AdCopyNormTable } from "../norm-table";
import { AdCopySimulationResult } from "../result";

type Props<T extends AdCopyTestOnlyConceptKey> = {
	activity: AdCopyActivityWithBrand;
	appearanceByStrategyTarget: number;
	constValue: GetAdCopySimulationResponse["constValue"];
	cp: Partial<AdCopyCP> | undefined;
	data: AdCopySimulationPredictionData;
	evaluationResult: EvaluationResult<T>;
	goalSalesVolume: number;
	hasEditRole: boolean;
	job: AdCopyJob;
	maxRecognition: number;
	onChangeRatio: AdCopyCorrectionValueSettingProps["onChangeRatio"];
	onSimulation: () => void;
	onSimulationSave: () => void;
	onsData: AdCopySimulationBrandData;
	targetConceptType: T;
	trialSalesVolumeBeforeCorrectionByCurrentProduct: number | undefined;
};

const AdCopySimulationOneComponent = <T extends AdCopyTestOnlyConceptKey>({
	activity,
	appearanceByStrategyTarget,
	constValue,
	cp,
	data,
	evaluationResult,
	goalSalesVolume,
	hasEditRole,
	job,
	maxRecognition,
	onChangeRatio,
	onSimulation,
	onSimulationSave,
	onsData,
	targetConceptType,
	trialSalesVolumeBeforeCorrectionByCurrentProduct,
}: Props<T>) => {
	// 相対パスだとサーバ側でよむことができないので、絶対パスで画像を読み込む必要がある。
	const baseUrl = location.origin;

	return (
		<>
			{hasEditRole && (
				<>
					<Button onClick={onSimulationSave} style={{ margin: "10px 0px" }} variant="outline-secondary">
						保存
					</Button>

					<Button onClick={onSimulation} style={{ margin: "10px" }} variant="outline-secondary">
						画像Download
					</Button>
				</>
			)}

			<table className="simulation-table">
				<tr>
					<td className="concept-title">提示コンセプト：{AdCopyConceptTypeMap[targetConceptType]}</td>

					<td className="jobno">
						JOB#: {job.jobNum}（{activity.category.name}、{activity.brand.name}）
					</td>
				</tr>

				<tr>
					<td className="main">
						<div className="image-area">
							<img src={data.conceptUrl} />
						</div>
					</td>

					<td className="main all-result">
						<table className="sub-table">
							<tbody>
								<tr>
									<td className="title-area">
										<img src={`${baseUrl}/images/title1-2.jpg`} />
									</td>

									<td>
										<div className="graph-area">
											<AdCopyNormTable
												//
												cp={cp}
												evaluationResult={evaluationResult}
												targetConceptType={targetConceptType}
											/>

											<div className="explain">＊購入意向はTB,T2Bをウエイト処理したワンナンバースコアで表示/計算</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<hr className="dashed" />

						<table className="sub-table">
							<tbody>
								<tr>
									<td className="title-area">
										<img src={`${baseUrl}/images/title2.png`} />
									</td>

									<td>
										<div className="graph-area">
											{/* 補整変数 */}
											<AdCopyCorrectionValueSetting
												correctionValue={data.correctionValue ?? {}}
												hasEditRole={hasEditRole}
												initInboundRatio={data.initSimulationData.referenceData.gRatio_IBBY}
												initLocalRatio={data.initSimulationData.referenceData.gRatio_LocalOther}
												maxRecognition={maxRecognition}
												onChangeRatio={onChangeRatio}
												value={data.initSimulationData}
											/>

											{/* 固定変数、判定基準、推定値、グラフ */}
											<AdCopySimulationResult
												appearanceByStrategyTarget={appearanceByStrategyTarget}
												constValue={constValue}
												data={data}
												goalSalesVolume={goalSalesVolume}
												hasCP={!!cp?._id && !!cp.correctionValues?.result}
												isExistingBrand={activity.isExistingBrand ?? false}
												onsData={onsData}
												trialSalesVolumeBeforeCorrectionByCurrentProduct={
													trialSalesVolumeBeforeCorrectionByCurrentProduct
												}
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
			</table>
		</>
	);
};

export const AdCopySimulationOne = React.memo(AdCopySimulationOneComponent) as typeof AdCopySimulationOneComponent;
