import { PageConfig } from "@muscat/types";
import { makeAdCopyEnquetePoint } from "../enquete/point";
import { AdCopyActivityWithBrand } from "../../../models/ad-copy/activity";
import { AdCopyJob } from "../../../models/ad-copy/job";
import { adCopyMaxAnsweredLimit, adCopySampleSizes, mainQuestionCountForPureREP } from "../enquete/config";
import { aggregationCost, calCost } from "./common";
import { hasBrandUserQuota } from "../enquete/helpers";
import { makeQuestionnaireForCost } from "./question";

// 戦略ターゲット出現率 1%
const strategyAppearance = 0.01;
// カテゴリユーザー出現率 100% とみなす
// const categoryUserAppearance = 1;
// ブランドユーザーを考慮しない
// const brandUserAppearance = 1;

/**
 * 対象の金額をoverするか。
 * @param total 合計金額
 * @return {boolean}
 */
export const isOverCost = (total: number, target = 1_000_000): boolean => {
	return total > target;
};

/**
 * 金額を計算する
 * システム利用日を含んだ合計金額。
 * @param {number} pointMax
 * @param {number} [pointMin = undefined]
 * @returns {string}
 */
export const makeAdCopyEstimateCost = (pointMax: number, pointMin?: number): string => {
	const total = pointMax + aggregationCost;
	const alert = isOverCost(total) ? "\n最大お見積金額が100万円を超えているため稟議が必要になります。" : "";
	const maxPointText = `最大金額：${total.toLocaleString()}円`;
	if (pointMin === undefined) return maxPointText + alert;
	return `${(pointMin + aggregationCost).toLocaleString()}円\n（${maxPointText}）${alert}`;
};

export const makeAdCopyMaxCost = (
	activity: AdCopyActivityWithBrand,
	job: AdCopyJob,
	questionnaire: PageConfig[],
): number => {
	// 割付グループ数、テスト品+現行品
	const quotaGroupCount = job.numOfTests + (job.hasCurrentProduct ? 1 : 0);
	// 各回収数
	const generalTotal = adCopySampleSizes.categoryUser * job.numOfTests;
	const currentProductTotal = job.hasCurrentProduct ? adCopySampleSizes.categoryUser : 0;

	const boostTotal = hasBrandUserQuota(activity, job)
		? (adCopySampleSizes.brandUser + adCopySampleSizes.strategyTarget) * quotaGroupCount
		: adCopySampleSizes.strategyTarget * quotaGroupCount;

	const { max, sc_max: min } = makeAdCopyEnquetePoint(makeQuestionnaireForCost(questionnaire));
	// boost対象は全設問回答、boost対象でないREPは、テスト品セルは、Q4まで、現行品セルは、Q3まで
	const honPoint =
		(max - min) * boostTotal +
		generalTotal * mainQuestionCountForPureREP.test +
		mainQuestionCountForPureREP.currentProduct * currentProductTotal;
	const maxAnsweredLimit = adCopyMaxAnsweredLimit(new Date(job.createdAt));
	const maxScreeningPoint = min * maxAnsweredLimit;
	return calCost(honPoint, "main") + calCost(maxScreeningPoint, "sc");
};

export const makeAdCopyEstimate = (
	activity: AdCopyActivityWithBrand,
	job: AdCopyJob,
	questionnaire: PageConfig[],
	categoryUserAppearance: number = 1,
): string => {
	// 割付グループ数、テスト品+現行品
	const quotaGroupCount = job.numOfTests + (job.hasCurrentProduct ? 1 : 0);
	// 各回収数
	const generalTotal = adCopySampleSizes.categoryUser * job.numOfTests;
	const currentProductTotal = job.hasCurrentProduct ? adCopySampleSizes.categoryUser : 0;
	const boostTotal = hasBrandUserQuota(activity, job)
		? (adCopySampleSizes.brandUser + adCopySampleSizes.strategyTarget) * quotaGroupCount
		: adCopySampleSizes.strategyTarget * quotaGroupCount;

	const { max, sc_max: min } = makeAdCopyEnquetePoint(makeQuestionnaireForCost(questionnaire));
	// boost対象は全設問回答、boost対象でないREPは、テスト品セルは、Q4まで、現行品セルは、Q3まで
	const honPoint =
		(max - min) * boostTotal +
		generalTotal * mainQuestionCountForPureREP.test +
		mainQuestionCountForPureREP.currentProduct * currentProductTotal;
	const maxAnsweredLimit = adCopyMaxAnsweredLimit(new Date(job.createdAt));
	const maxScreeningPoint = min * maxAnsweredLimit;

	const maxTotal = makeAdCopyMaxCost(activity, job, questionnaire);

	// ブランドユーザーはboost段階回収なしと想定
	const strategyScreeningCount = Math.round((adCopySampleSizes.strategyTarget / strategyAppearance) * quotaGroupCount);

	const categoryScreeningCount = Math.round(
		(adCopySampleSizes.categoryUser / categoryUserAppearance) * quotaGroupCount,
	);
	const screeningCount = strategyScreeningCount + categoryScreeningCount;
	const scPoint = min * screeningCount;
	const total = calCost(honPoint, "main") + calCost(scPoint, "sc");
	console.log(`
		----------------
		jobNum: ${job.jobNum}
		----------------
		point
		sc_max: ${min}
		max: ${max}
		----------------
		回収数想定
		screeningCount(戦略TG出現率 1%で計算):${screeningCount.toLocaleString()}
		テスト品REP Count:${generalTotal.toLocaleString()}
		現行品REP Count:${currentProductTotal.toLocaleString()}
		boostCount(戦略+ブランド):${boostTotal.toLocaleString()}
		----------------
		point
		screening: ${scPoint.toLocaleString()}
		maxScreening : ${maxScreeningPoint.toLocaleString()}
		hon: ${honPoint.toLocaleString()}
		うち：
		boost本ポイント:${((max - min) * boostTotal).toLocaleString()}
		テスト品REP本ポイント:${(generalTotal * mainQuestionCountForPureREP.test).toLocaleString()}
		現行品REP本ポイント:${(mainQuestionCountForPureREP.currentProduct * currentProductTotal).toLocaleString()}
		----------------
		invoice
		total: ${total.toLocaleString()}
		maxTotal: ${maxTotal.toLocaleString()}
		----------------
	`);
	return makeAdCopyEstimateCost(maxTotal, total);
};
