import {
	CategoryGroup,
	StrategyGroup,
	Married,
	Occupation,
	SensitiveSkin,
	BeautyType,
	Fragrance,
	FoundationType,
	FacewashFunction,
	getSkinAttributeEnum,
	SensitiveSkinFrequencyRank,
	SkinProblemFrequency,
	LipstickType,
	EyeshadowType,
	Gypsy,
	AestheticMedicineExperience,
	getChannelEnum,
} from "../../models/activity";
import { priceChoices } from "../../models/category";

import {
	categoryTargetGroupLabels,
	strategyTargetGroupLabels,
	strategyGroupCategoryLabels,
} from "../../../label/activity";

import { Age, Gender, Price } from "../../models/shared";
import { isDefined } from "../common";

export const makePriceChoice = (price: Price) => {
	const { to, from } = price;
	return priceChoices.filter((choice) => {
		// toがundefinedは以上の判定。選択肢のfromよりもターゲットのfromが大きければ有効。
		if (to === undefined) {
			return from <= choice.from;
		}
		// fromがundefinedは以下の判定toよりも大きければ有効。
		if (from === undefined) {
			return to >= choice.to;
		}
		return choice.from <= to && from <= choice.to;
	}, []);
};

export const CategorySampleSize = 200;
export const StrategySampleSize = 100;

export const isSetStrategy = (data?: any[]) => {
	return !!data && data.length;
};

export const makePriceLabel = (price?: Price): string => {
	if (!price) return "設定なし";
	// if (price.label) return price.label;
	if (price.from !== undefined && price.to === undefined) {
		return price.from.toLocaleString() + "円以上";
	}
	if (price.from === undefined && price.to !== undefined) {
		return price.to.toLocaleString() + "円以下";
	}
	if (price.from !== undefined && price.to !== undefined) {
		return `${price.from.toLocaleString()}〜${price.to.toLocaleString()}円`;
	}
	return "設定なし";
};
export const makeAgeLabel = (age: Age) => {
	return `${age.from}〜${age.to}歳`;
};

export const makeCategoryGroupText = (categoryTargetGroup: CategoryGroup, gender: keyof typeof Gender): string => {
	const { age, price } = categoryTargetGroup;
	return [
		`性別：${Gender[gender]}`,
		`${categoryTargetGroupLabels.age}：${makeAgeLabel(age)}`,
		`${categoryTargetGroupLabels.price}：${makePriceLabel(price)}`,
	].join("、");
};

export function composeGypsySubLabel(gypsy: Gypsy) {
	const subLabels = [
		...(gypsy.beautyType ? [BeautyType[gypsy.beautyType]] : []),
		...(gypsy.facewashFunction ? [FacewashFunction[gypsy.facewashFunction]] : []),
		...(gypsy.foundationType ? [FoundationType[gypsy.foundationType]] : []),
		...(gypsy.lipstickType ? [LipstickType[gypsy.lipstickType]] : []),
		...(gypsy.eyeshadowType ? [EyeshadowType[gypsy.eyeshadowType]] : []),
	];

	return subLabels.length <= 0 ? "" : `（${subLabels.join("/")}）`;
}

export const makeStrategyGroupText = (
	strategyTargetGroup: StrategyGroup,
	gender: keyof typeof Gender,
	version?: string,
): string => {
	const {
		age,
		married,
		children,
		occupation,
		skinAttribute,
		sensitiveSkinFrequency,
		skinProblemFrequency,
		sensitiveSkin,
		categoryOrAnd,
		category,
		channel,
		fragrance,
		facewashFunction,
		foundationType,
		lipstickType,
		eyeshadowType,
		gypsy,
		aestheticMedicineExperience,
		nonPurchaseCategoryConfig,
	} = strategyTargetGroup;
	const tmp = [`性別：${Gender[gender]}、${strategyTargetGroupLabels.age}：${makeAgeLabel(age)}`];
	const channelEnum = getChannelEnum(version);
	if (isSetStrategy(married)) {
		tmp.push(`${strategyTargetGroupLabels.married}：${married.map((target) => Married[target]).join()}`);
	}
	if (isSetStrategy(children)) {
		tmp.push(`${strategyTargetGroupLabels.children}：${children.map((target) => channelEnum[target as any]).join()}`);
	}
	if (isSetStrategy(occupation)) {
		tmp.push(`${strategyTargetGroupLabels.occupation}：${occupation.map((target) => Occupation[target]).join()}`);
	}
	if (isSetStrategy(skinAttribute)) {
		tmp.push(
			`${strategyTargetGroupLabels.skinAttribute}：${skinAttribute
				.map((target) => getSkinAttributeEnum(version)[target as any])
				.join()}`,
		);
	}
	if (isSetStrategy(sensitiveSkin)) {
		tmp.push(
			`${strategyTargetGroupLabels.sensitiveSkin}：${sensitiveSkin.map((target) => SensitiveSkin[target]).join()}`,
		);
	}

	if (isSetStrategy(sensitiveSkinFrequency)) {
		tmp.push(
			`${strategyTargetGroupLabels.sensitiveSkinFrequency}：${sensitiveSkinFrequency
				.map((target) => SensitiveSkinFrequencyRank[target])
				.join()}`,
		);
	}
	if (isSetStrategy(channel)) {
		tmp.push(`${strategyTargetGroupLabels.channel}：${channel.map((target) => channelEnum[target as any])}`);
	}
	if (isSetStrategy(fragrance)) {
		tmp.push(`${strategyTargetGroupLabels.fragrance}：${fragrance.map((target) => Fragrance[target])}`);
	}
	if (isDefined(facewashFunction)) {
		tmp.push(`${strategyTargetGroupLabels.facewashFunction}：${FacewashFunction[facewashFunction]}`);
	}
	if (isSetStrategy(foundationType)) {
		tmp.push(`${strategyTargetGroupLabels.foundationType}：${foundationType.map((target) => FoundationType[target])}`);
	}
	if (isSetStrategy(lipstickType)) {
		tmp.push(`${strategyTargetGroupLabels.lipstickType}：${lipstickType.map((target) => LipstickType[target])}`);
	}
	if (isSetStrategy(eyeshadowType)) {
		tmp.push(`${strategyTargetGroupLabels.eyeshadowType}：${eyeshadowType.map((target) => EyeshadowType[target])}`);
	}
	if (gypsy) {
		tmp.push(`${strategyTargetGroupLabels.gypsy}：${gypsy.surveyChoice.label}${composeGypsySubLabel(gypsy)}`);
	}
	if (isSetStrategy(skinProblemFrequency)) {
		tmp.push(
			`${strategyTargetGroupLabels.skinProblemFrequency}：${skinProblemFrequency
				.map((target) => SkinProblemFrequency[target])
				.join()}`,
		);
	}
	if (isSetStrategy(aestheticMedicineExperience)) {
		tmp.push(
			`${strategyTargetGroupLabels.aestheticMedicineExperience}：${aestheticMedicineExperience
				.map((target) => AestheticMedicineExperience[target])
				.join()}`,
		);
	}
	const categoryTexts: string[] = [];
	for (const c of category) {
		const { price, beautyType, usedBrand, unusedBrand, surveyChoice: surveyChoiceNuber } = c;
		const targetCategory = c.category;
		const surveyChoice = targetCategory.surveyChoice.find((sc) => sc.value === surveyChoiceNuber);
		const categoryTmp = [`${strategyGroupCategoryLabels.price}：${makePriceLabel(price)}`];
		if (isSetStrategy(beautyType)) {
			categoryTmp.push(
				`${strategyGroupCategoryLabels.beautyType}：${beautyType.map((target) => BeautyType[target]).join("、")}`,
			);
		}
		/*/
		if (isSetStrategy(fragrance)) {
			categoryTmp.push(
				`${strategyGroupCategoryLabels.fragrance}：${fragrance.map((target) => Fragrance[target]).join("、")}`
			);
		}
		/*/
		// ブランド名のリストになる。
		if (isSetStrategy(usedBrand)) {
			categoryTmp.push(`${strategyGroupCategoryLabels.usedBrand}：${usedBrand.join("、")}`);
		}
		if (isSetStrategy(unusedBrand)) {
			categoryTmp.push(`${strategyGroupCategoryLabels.unusedBrand}：${unusedBrand.join("、")}`);
		}
		categoryTexts.push(`＜${surveyChoice.label}＞\n${categoryTmp.join("\n")}`);
	}
	if (categoryTexts.length) {
		tmp.push(`\n■ 以下カテゴリ間の条件は${categoryOrAnd}で設定する\n${categoryTexts.join(`\n`)}`);
	}
	// 過去1年非購入カテゴリ
	if (isDefined(nonPurchaseCategoryConfig) && isSetStrategy(nonPurchaseCategoryConfig.surveyChoices)) {
		const { categoryOrAnd, surveyChoices } = nonPurchaseCategoryConfig;
		const nonPurchaseCategoryEnqueteChoicesLabel = `\n■ 以下${
			strategyTargetGroupLabels.nonPurchaseCategoryConfig
		}は${categoryOrAnd}で設定する: \n${surveyChoices.map((choice) => choice.label).join(", ")}\n`;
		tmp.push(nonPurchaseCategoryEnqueteChoicesLabel);
	}
	return tmp.join("\n");
};
