import type { AccountWithoutPassword } from "../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../server/models/ad-copy/activity";
import type { AdCopyActivityMemberAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = {
	accounts: AccountWithoutPassword[];
	activity?: AdCopyActivityWithBrand;
	activityMembers: string[];
	hasDemandForecastNeeded: boolean;
	info: MessageInfo;
	loading: boolean;
};

export const initState: State = {
	accounts: [],
	activityMembers: [],
	hasDemandForecastNeeded: false,
	info: {
		isSuccess: true,
		message: "",
	},
	loading: true,
};

export const reducer = (state: State, action: AdCopyActivityMemberAction): State => {
	switch (action.type) {
		case "loadActivity":
			return {
				...state,
				...action.payload,
				loading: false,
				...action.payload,
				activityMembers: action.payload.activity.accountIds as string[],
			};

		case "changeMembers":
			return {
				...state,
				activityMembers: action.payload,
			};

		case "updateMembers":
			return {
				...state,
				info: {
					isSuccess: true,
					message: "アクティビティ登録者を更新しました。",
				},
				loading: false,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: action.payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: action.payload,
			};
	}
};
