/**
 *  現行品上市後1年間トライアル売上数量(SLI,SCI)) (万個)を算出する
 * @param purchaseRate 現行品の購入率
 * @param repPopulation REP人口（万人）
 */

export const calcCurrentProductTrialSalesVolume = (purchaseRate: number, repPopulation: number) =>
	(purchaseRate / 100) * repPopulation;

/** 補正前のトライアル購入数量を算出する */
export const calcTrialSalesVolumeBeforeCorrection = (
	brandUserTrialSalesVolume: number,
	brandNonuserTrialSalesVolume: number,
	categoryNonuserTrialSalesVolume: number,
): number => {
	return brandUserTrialSalesVolume + brandNonuserTrialSalesVolume + categoryNonuserTrialSalesVolume;
};

/** 対象のカテゴリ(brandUser, BrandNonuser, categoryNonuser)内のトライアル購入数量を算出する */
export const calcTrialSalesVolumeByTarget = (userTotal: number, trialRate: number): number => {
	return userTotal * trialRate;
};

/** トライアル率を算出する */
export const calcTrialRate = (purchaseIntent: number, recognitionRate: number, constant: number): number => {
	return purchaseIntent * recognitionRate * constant;
};

/** ブランドユーザの購入意向を算出する */
export const calcPurchaseIntentBrandUser = (
	segment1PurchaseIntent: number,
	segment2PurchaseIntent: number,
	brandUserStrategyTarget: number,
): number => {
	return segment1PurchaseIntent * brandUserStrategyTarget + segment2PurchaseIntent * (1 - brandUserStrategyTarget);
};

/** ブランドノンユーザの購入意向を算出する */
export const calcPurchaseIntentBrandNonuser = (
	segment3PurchaseIntent: number,
	segment4PurchaseIntent: number,
	brandNonUserStrategyTarget: number,
): number => {
	return (
		segment3PurchaseIntent * brandNonUserStrategyTarget + segment4PurchaseIntent * (1 - brandNonUserStrategyTarget)
	);
};

/**
 * 新規ブランドにおける各ユーザの認知率を算出する
 * @param categoryUserRate カテゴリユーザ率
 * @param categoryNonuserRate カテゴリノンユーザ率
 * @param productRecognition 商品認知率
 * Note: 資料にはカテゴリユーザとあるが、おそらくブランドノンユーザのため、ブランドノンユーザで計算している。要確認
 */
export const calcRecognitionRateByCategory = (
	brandNonuser: number,
	categoryNonuserRate: number,
	recognitionRate: number,
): { brandUser: 0; brandNonuser: number; categoryNonuser: number } => {
	const rate = recognitionRate / 100;
	const categoryUserRecognitionRate = Math.max(
		rate,
		1 / (1 + Math.exp(-(1.19 + 1.206 * Math.log(rate / (1 - rate)) - 1.265 * brandNonuser))),
	);
	const categoryNonuserRecognitionRate = (rate - categoryUserRecognitionRate * brandNonuser) / categoryNonuserRate;
	return { brandUser: 0, brandNonuser: categoryUserRecognitionRate, categoryNonuser: categoryNonuserRecognitionRate };
};

/**
 * 既存ブランドにおける各ユーザの認知率を算出する
 * @param brandUserRate ブランドユーザ率
 * @param brandNonuserRate ブランドノンユーザ率
 * @param categoryNonuserRate カテゴリノンユーザ率
 * @param productRecognition 商品認知率
 */
export const calcRecognitionRateByBrand = (
	brandUserRate: number,
	brandNonuserRate: number,
	categoryNonuserRate: number,
	recognitionRate: number,
): { brandUser: number; brandNonuser: number; categoryNonuser: number } => {
	const rate = recognitionRate / 100;
	const categoryUserRecognitionRate = Math.max(
		rate,
		1 / (1 + Math.exp(-(1.19 + 1.206 * Math.log(rate / (1 - rate)) - 1.265 * (brandUserRate + brandNonuserRate)))),
	);
	const brandUserRecognitionRate = Math.max(
		categoryUserRecognitionRate,
		1 / (1 + Math.exp(-(5.331 + 2.668 * Math.log(rate / (1 - rate)) - 39.089 * brandUserRate))),
	);
	const brandNonuserRecognitionRate =
		(categoryUserRecognitionRate * (brandUserRate + brandNonuserRate) - brandUserRecognitionRate * brandUserRate) /
		brandNonuserRate;
	const categoryNonuserRecognitionRate =
		(rate - categoryUserRecognitionRate * (brandUserRate + brandNonuserRate)) / categoryNonuserRate;
	return {
		brandUser: brandUserRecognitionRate,
		brandNonuser: brandNonuserRecognitionRate,
		categoryNonuser: categoryNonuserRecognitionRate,
	};
};

/**
 * トライアル購入数量の算出
 * @param trialSalesVolumeBeforeCorrection 補正前のトライアル購入数量
 * @param coefficient [現行刺激トライアル売上数量]/[現行刺激のトライアル購入数量(補正前)]
 * NOTE: 現行刺激が存在しない場合、`correction`には`1`を渡すこと
 */
export const calcTrialSalesVolume = (trialSalesVolumeBeforeCorrection: number, coefficient: number): number => {
	return trialSalesVolumeBeforeCorrection * coefficient;
};

/**
 * リピート購入数量の算出
 * @param trialSaleVolume トライアル購入数量
 * @param gRepeat 購入者購入数量
 */
export const calcRepeatSaleVolume = (trialSaleVolume: number, gRepeat: number): number => {
	return trialSaleVolume * (gRepeat - 1);
};

/**
 * 申告漏れの算出
 * @param salesVolume 購入数量
 * @param gUnreport SLCI申告漏れ補正係数
 */
export const calcUnreport = (salesVolume: number, gUnreport: number): number => {
	return salesVolume * (gUnreport - 1);
};

/**
 * ローカルその他数量の算出
 * @param trialSalesVolume トライアル購入数量
 * @param repeatSalesVolume リピート購入数量
 * @param gRatioLocalOther ローカルその他比率
 */
export const calcLocalOther = (
	trialSalesVolume: number,
	repeatSalesVolume: number,
	gRatioLocalOther: number,
): number => {
	return (trialSalesVolume + repeatSalesVolume) * gRatioLocalOther;
};

/**
 * IBBY数量の算出
 * @param trialSalesVolume トライアル購入数量
 * @param repeatSalesVolume リピート購入数量
 * @param gRatioIBBY IB比率
 * @returns
 */
export const calcIBBYVolume = (trialSalesVolume: number, repeatSalesVolume: number, gRatioIBBY: number): number => {
	return (trialSalesVolume + repeatSalesVolume) * gRatioIBBY;
};

/** 対目標値の算出 */
export const calcRatioToTargetSalesVolume = (total: number, goalSalesVolume: number) => {
	return (total / goalSalesVolume) * 100 - 100;
};
