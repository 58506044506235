import * as React from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
	onClose: () => void;
	onSubmit: () => void;
	show: boolean;
};

export const CurrentProductAlertModal = React.memo((props: Props) => {
	const { onClose, onSubmit, show } = props;

	return (
		<Modal onHide={onClose} show={show}>
			<Modal.Body>
				現行品が設定されていないため、現行品実績による補整は需要予測に反映されません。現行品は設定しなくてよいですか？
			</Modal.Body>

			<Modal.Footer>
				<Button onClick={onClose} variant="outline-secondary">
					キャンセル
				</Button>

				<Button
					onClick={() => {
						onClose();

						onSubmit();
					}}
				>
					登録する
				</Button>
			</Modal.Footer>
		</Modal>
	);
});
