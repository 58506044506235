import * as React from "react";
import { Column, Option, ListTable } from "../../../../../react-components/lu-component/src/index";
import { Button, Dropdown, Alert } from "react-bootstrap";
import { Job, SurveyMethodOptions, JobWithSurveyStatus } from "../../../../../server/models/job";
import styled from "styled-components";
import { JobStatus, SurveyStatus } from "../../../../../server/models/status";
import { ConfirmDropdownItem } from "../../parts/confirm-dropdown-item";
import { AccountWithoutPassword } from "../../../../../server/models/account";
import { Role } from "../../../../../server/lib/permission/role";
import { ActivityWithBrand } from "../../../../../server/models/activity";
import dayjs from "dayjs";
import { stopSurvey } from "../../../lib/build-options";
import { TextRed } from "../../parts/font";

const ButtonArea = styled.div`
	max-width: 120px;
`;

export type JobPageType =
	| "quota"
	| "concept"
	| "approval"
	| "application"
	| "preview"
	| "job-status"
	| "invoice"
	| "question"
	| "job-detail"
	| "enquete-test";
export type JobPageProps = {
	activity: ActivityWithBrand;
	jobs: JobWithSurveyStatus[];
	loginAccount: AccountWithoutPassword;
	accountOptions: Option[];
	onEditPage: (id: string) => void;
	onOutlinePage: (id: string) => void;
	onChangePage: (id: string, page: JobPageType) => void;
	onJobStatusChange: (id: string, status: keyof typeof JobStatus) => void;
	onRetest: (id: string) => void;
	hideTitle?: boolean;
	onAggregatePage?: (id: string) => void;
};

export const JobPage = React.memo((props: JobPageProps) => {
	const {
		jobs,
		accountOptions,
		activity,
		onChangePage,
		onEditPage,
		onJobStatusChange,
		onRetest,
		loginAccount,
		onOutlinePage,
		hideTitle,
		onAggregatePage,
	} = props;
	const hasEditRole = React.useMemo(() => Role.hasAcitivityEditRole(loginAccount, activity), [loginAccount, activity]);
	const hasQuotaViewRole = React.useMemo(() => Role.hasViewQuotaRole(loginAccount, activity), [loginAccount, activity]);
	const hasViewInvoiceRole = React.useMemo(
		() => Role.hasViewInvoiceRole(loginAccount, activity),
		[loginAccount, activity],
	);
	const hasCustomRole = React.useMemo(() => Role.hasCustomRole(loginAccount, activity), [loginAccount, activity]);
	/**
	 * 削除可能
	 * - システム
	 * - 申請者
	 * - アクティビティメンバ
	 */
	const hasDeleteActivityRole = React.useMemo(() => {
		if (Role.hasOperarorManageRole(loginAccount)) return true;
		if (Role.isActivityMember(loginAccount, activity)) return true;
		return false;
	}, [loginAccount, activity]);
	const hasApplicationRole = React.useMemo(
		() => Role.hasApplicationRole(loginAccount, activity),
		[loginAccount, activity],
	);
	const canSurvey = React.useMemo(() => !stopSurvey(), []);
	const retestJobIds = React.useMemo(() => {
		const ids = new Set<string>();
		for (const job of jobs) {
			if (job.retestJobId) ids.add(job.retestJobId.toString());
		}
		return ids;
	}, [jobs]);
	const hasVersion = !!activity?.version;
	const columns = React.useMemo<Column[][]>(() => {
		const tmp: Column[][] = [
			[
				{
					name: "jobNum",
					type: "text",
					label: "ジョブNo.",
				},
			],
			[
				{
					name: "accountId",
					type: "select",
					label: "作成者",
					options: accountOptions,
				},
			],
			[
				{
					name: "surveyDatetime",
					type: "custom",
					label: "調査日時",
					view: (job: Job) => {
						return (
							<>
								<div>{job.startDatetime}</div>
								<div>{dayjs(job.endDatetime).add(5, "minute").format("YYYY-MM-DDTHH:mm")}</div>
							</>
						);
					},
				},
			],
			[
				{
					name: "surveyStatus",
					type: "custom",
					label: "調査ステータス",
					view: (job: JobWithSurveyStatus) => {
						const { surveyStatus, inspection } = job;
						return (
							<>
								{surveyStatus === "complete" ? (
									<Button
										size={"sm"}
										variant={"outline-secondary"}
										onClick={() => onOutlinePage(job._id.toString())}
										className="w-100"
									>
										結果閲覧({inspection ? "検収済" : "未検収"})
									</Button>
								) : (
									SurveyStatus[surveyStatus]
								)}
							</>
						);
					},
					//options: SurveyStatusOptions,
				},
			],
			[
				{
					name: "method",
					type: "select",
					label: "調査手法",
					options: SurveyMethodOptions,
				},
			],
			[
				{
					name: "setting",
					type: "custom",
					label: "基本設定",
					view: (job: JobWithSurveyStatus) => {
						return (
							<ButtonArea>
								<Button
									size={"sm"}
									variant={"outline-secondary"}
									onClick={() => onEditPage(job._id.toString())}
									className="w-100"
								>
									基本設定
								</Button>
								{job.method === "concept-assessment" && (
									<Button
										size={"sm"}
										variant={"outline-secondary"}
										onClick={() => onChangePage(job._id.toString(), "concept")}
										className="w-100 mt-2"
									>
										コンセプト設定
									</Button>
								)}
							</ButtonArea>
						);
					},
				},
			],
			[
				{
					type: "custom",
					name: "otherSetting",
					label: "その他設定",
					view: (job: JobWithSurveyStatus) => {
						const id = job._id.toString();
						if (job.surveyStatus === "conceptSetting" && !hasDeleteActivityRole) return <></>;
						return (
							<Dropdown>
								<Dropdown.Toggle id={`dropdown-${id}`} variant={"secondary"}>
									設定
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{job.surveyStatus !== "conceptSetting" && (
										<>
											<Dropdown.Item style={{ fontSize: "90%" }} onClick={() => onChangePage(id, "preview")}>
												プレビュー
											</Dropdown.Item>
											{(job.surveyStatus === "beforeApplication" ||
												job.surveyStatus === "applicationReject" ||
												job.surveyStatus === "applying") && (
												<Dropdown.Item style={{ fontSize: "90%" }} onClick={() => onChangePage(id, "enquete-test")}>
													動作画面
												</Dropdown.Item>
											)}
											<Dropdown.Item style={{ fontSize: "90%" }} onClick={() => onChangePage(id, "job-detail")}>
												ジョブ詳細
											</Dropdown.Item>
										</>
									)}

									{hasCustomRole &&
										(job.surveyStatus === "conceptSetting" ||
											job.surveyStatus === "beforeApplication" ||
											job.surveyStatus === "applicationReject") && (
											<Dropdown.Item style={{ fontSize: "90%" }} onClick={() => onChangePage(id, "question")}>
												追加設問
											</Dropdown.Item>
										)}
									{hasVersion &&
										canSurvey &&
										hasApplicationRole &&
										(job.surveyStatus === "beforeApplication" ||
											job.surveyStatus === "applying" ||
											job.surveyStatus === "applicationReject") && (
											<Dropdown.Item style={{ fontSize: "90%" }} onClick={() => onChangePage(id, "application")}>
												配信申請
											</Dropdown.Item>
										)}
									{hasVersion &&
										canSurvey &&
										(job.surveyStatus === "applying" || job.surveyStatus === "applicationReject") && (
											<Dropdown.Item onClick={() => onChangePage(id, "approval")}>承認状況</Dropdown.Item>
										)}
									{(job.surveyStatus === "progress" || job.surveyStatus === "complete") && hasQuotaViewRole && (
										<Dropdown.Item onClick={() => onChangePage(id, "quota")}>回収状況</Dropdown.Item>
									)}
									{job.surveyStatus === "complete" && hasViewInvoiceRole && (
										<Dropdown.Item onClick={() => onChangePage(id, "invoice")}>請求情報</Dropdown.Item>
									)}
									{hasCustomRole && job.surveyStatus === "stopping" && (
										<ConfirmDropdownItem
											title={"調査再開"}
											body={"調査を再開しようとしています。よろしいですか？"}
											onOk={() => onJobStatusChange(id, "progress")}
										/>
									)}
									{hasCustomRole && job.surveyStatus === "progress" && (
										<ConfirmDropdownItem
											title={"調査完了"}
											body={"調査を完了しようとしています。よろしいですか？"}
											onOk={() => onJobStatusChange(id, "complete")}
										/>
									)}
									{hasCustomRole &&
										job.surveyStatus !== "progress" &&
										job.surveyStatus !== "complete" &&
										job.surveyStatus !== "stopping" && (
											<ConfirmDropdownItem
												title={"調査中止"}
												body={"調査を中止しようとしています。よろしいですか？"}
												onOk={() => onJobStatusChange(id, "stopping")}
											/>
										)}
									{hasDeleteActivityRole &&
										job.surveyStatus !== "progress" &&
										job.surveyStatus !== "complete" &&
										job.surveyStatus !== "applying" &&
										job.surveyStatus !== "waitingToStart" && (
											<ConfirmDropdownItem
												title={"ジョブ削除"}
												body={
													<>
														<div>ジョブNo. {job.jobNum} を削除してよろしいでしょうか。</div>
														<div>
															<TextRed>※一度削除すると、データ復旧できませんので再度ご確認ください。</TextRed>
														</div>
													</>
												}
												onOk={() => onJobStatusChange(id, "deleted")}
											/>
										)}
								</Dropdown.Menu>
							</Dropdown>
						);
					},
				},
			],
			[
				{
					type: "custom",
					label: "再調査",
					name: "resurvey",
					view: (job: JobWithSurveyStatus) => {
						const { retestJobId } = job;
						const targetJob = retestJobId ? jobs.find((job) => job._id === retestJobId) : undefined;
						return (
							<ButtonArea>
								{targetJob && `${targetJob.jobNum}の再調査`}
								{hasVersion &&
									hasEditRole &&
									!retestJobIds.has(job._id.toString()) &&
									job.surveyStatus === "complete" && (
										<Button
											size={"sm"}
											variant={"outline-secondary"}
											onClick={() => onRetest(job._id.toString())}
											className="w-100"
										>
											再調査登録
										</Button>
									)}
							</ButtonArea>
						);
					},
				},
			],
		];
		if (onAggregatePage) {
			tmp.push([
				{
					type: "custom",
					label: "集計",
					name: "aggregate",
					view: (job: JobWithSurveyStatus) => {
						if (job.surveyStatus !== "complete") return <></>;
						return (
							<Button
								className="w-100"
								size={"sm"}
								variant={"outline-secondary"}
								onClick={() => onAggregatePage(job._id.toString())}
							>
								集計
							</Button>
						);
					},
				},
			]);
		}
		return tmp;
	}, [
		accountOptions,
		onAggregatePage,
		onOutlinePage,
		onEditPage,
		onChangePage,
		hasDeleteActivityRole,
		hasCustomRole,
		canSurvey,
		hasApplicationRole,
		hasQuotaViewRole,
		hasViewInvoiceRole,
		onJobStatusChange,
		jobs,
		hasEditRole,
		retestJobIds,
		onRetest,
	]);
	return (
		<>
			{!hideTitle && (
				<div className="m-3 clearfix">
					<div className="h3 float-start">■ジョブ一覧</div>
					{jobs.length === 0 && (
						<div className="col-md-auto float-end">
							{hasEditRole && (
								<Button
									variant="secondary"
									onClick={() => onEditPage("new")}
									size={"lg"}
									style={{ borderWidth: "1.6px" }}
								>
									ジョブ新規作成
								</Button>
							)}
						</div>
					)}
				</div>
			)}
			<div className="m-3 ">
				<Alert variant={"secondary"}>
					{activity &&
						`${hideTitle ? `No.${activity.no}　` : ""}${activity.releaseYear}年${activity.releaseMonth}月 ${
							activity.brand.name
						} ${activity.category.name} ${activity.memo || ""}`}
				</Alert>
				<ListTable columns={columns} data={jobs} />
			</div>
		</>
	);
});
