/**
 * - コンセプトテスト、コピーテスト共に停止
 */
export const stopSurvey = (): boolean => {
	return process.env.BUILD_OPTION === "stop-survey";
};

/**
 * - コンセプトテストのみ停止
 */
export const stopSurveyConcept = (): boolean => {
	return process.env.BUILD_OPTION_CONCEPT === "stop-survey";
};

/**
 * - コピーテストのみ停止
 */
export const stopSurveyAdCopy = (): boolean => {
	return process.env.BUILD_OPTION_AD_COPY === "stop-survey";
};
