/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import type { Choice } from "../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../react-components/lu-component/src/index";
import { adCopyLipstickTypeOptions } from "../../../../../../../server/models/ad-copy/activity";
import { AccordionForm } from "../../../../parts/accodion-form";
import { StrategyGroup } from "../../../../../../../server/models/activity";
import { strategyTargetGroupLabels } from "../../../../../../../label/activity";

type Props = {
	onChange: (name: keyof StrategyGroup, value: any) => void;
	strategyGroup: StrategyGroup;
};

export const LipstickTypeCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldShow = React.useMemo(
		() => strategyGroup.category?.some((c) => c.category.type === "lipstick"),
		[strategyGroup.category],
	);

	return (
		<Row>
			<FormGroup as={Col} md={5}>
				<AccordionForm
					defaultShow={strategyGroup.lipstickType != null && 0 < strategyGroup.lipstickType.length}
					eventKey="lipstickType"
					title={strategyTargetGroupLabels.lipstickType ?? ""}
				>
					{shouldShow ? (
						<Checkboxes
							checkeds={strategyGroup.lipstickType ?? []}
							choices={adCopyLipstickTypeOptions.filter((x) => x.label !== "その他") as Choice[]}
							name="lipstickType"
							onChange={(value) => {
								onChange("lipstickType", value);
							}}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
