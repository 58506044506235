import { CategoryGroup } from "./category-group";
import { NoSettingStrategyGroup } from "./no-setting";
import { StrategyGroup } from "./strategy-group";

import { ObjectId } from "mongodb";
import { ActivityStatus } from "../status";
import { SalesScale } from "./sales-scale";
import { ProductType } from "./strategy-group";
import { Grp } from "./grp";
import { Category } from "../category";
import { Brand } from "../brand";

export type SimpleActivity = {
	_id?: ObjectId | string;
	no?: number;
	version?: string; // リリースバージョン（新しいアクティビティ空付けられる）
	accountIds: (string | ObjectId)[];
	brandId: ObjectId | string;
	status: keyof typeof ActivityStatus;
	releaseYear: number;
	releaseMonth: number;
	salesScale: keyof typeof SalesScale;
	grp: keyof typeof Grp;
	goalRecognitionRate: number;
	goalSalesVolume: number;
	category: Category;
	productType: keyof typeof ProductType;
	memo?: string;
	createdAt?: string;
	updatedAt?: string;
};

export type Activity = SimpleActivity & {
	categoryTargetGroup?: CategoryGroup;
	strategyTargetGroup?: StrategyGroup;
	noSettingStrategyGroup?: NoSettingStrategyGroup;
};

export type ActivityWithBrand = Activity & {
	brand: Brand;
};
