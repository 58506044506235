import type { AdCopyJob } from "../../../../../../server/models/ad-copy/job";
import type { GetAdCopyJobResponse } from "../../../../../../server/types/request/ad-copy/job";
import type { AdCopyJobCreateAction } from "../../../../types/actions";
import type { MessageInfo } from "../../../../types/info";

type State = Pick<GetAdCopyJobResponse, "adCopyCanDemandForecastNeeded" | "cantEditMessage" | "needCurrentProduct"> & {
	evaluationItem: string[];
	info: MessageInfo;
	job: Partial<AdCopyJob>;
	loading: boolean;
	prompt: boolean;
};

export const initState: State = {
	adCopyCanDemandForecastNeeded: false,
	evaluationItem: [],
	info: {
		isSuccess: true,
		message: "",
	},
	job: {},
	loading: true,
	needCurrentProduct: false,
	prompt: false,
};

export const reducer = (state: State, { payload, type }: AdCopyJobCreateAction): State => {
	switch (type) {
		case "loadJob": {
			return {
				...state,
				...payload,
				job: {
					...state.job,
					...payload.job,
					benchmarkName: payload.job?.benchmarkName ?? payload.benchmarkName,
					currentProductPurchaseRate:
						payload.job?.currentProductPurchaseRate ?? payload.originCurrentProductPurchaseRate,
					currentProductPurchaseRateReason:
						payload.job?.currentProductPurchaseRateReason ?? payload.originCurrentProductPurchaseRateReason,
					currentProductRecognitionRate:
						payload.job?.currentProductRecognitionRate ?? payload.originCurrentProductRecognitionRate,
					hasBenchmark: payload.job?.hasBenchmark ?? (payload.benchmarkName == null ? state.job.hasBenchmark : true),
					hasCurrentProduct: payload.job?.hasCurrentProduct ?? state.job.hasCurrentProduct,
					hasKeyVisual: payload.job?.hasKeyVisual ?? payload.hasKeyVisual ?? state.job.hasKeyVisual,
					isDemandForecastNeeded:
						payload.job?.isDemandForecastNeeded ??
						payload.originDemandForecastNeeded ??
						state.job.isDemandForecastNeeded,
				},
				loading: false,
			};
		}

		case "updateJob":
			return {
				...state,
				...payload,
				info: {
					isSuccess: true,
					message: `${state.job._id ? "更新" : "作成"}が完了しました。`,
				},
				loading: false,
				prompt: false,
			};

		case "changeJob": {
			const { name, value } = payload;

			// 需要予測が不要の場合、現行品の関連する値を削除
			if ((name === "isDemandForecastNeeded" || name === "hasCurrentProduct") && (value == null || value === false)) {
				return {
					...state,
					job: {
						...state.job,
						// NOTE: undefined だと Optional のフィールドが更新されないので、null で更新する
						currentProductPurchaseRate: null,
						currentProductPurchaseRateReason: null,
						currentProductRecognitionRate: null,
						[name]: false,
					},
					prompt: true,
				};
			}

			return {
				...state,
				job: {
					...state.job,
					[name]: value,
				},
				prompt: true,
			};
		}

		case "changeEvaluationItem":
			return {
				...state,
				evaluationItem: payload,
				prompt: true,
			};

		case "changeMessageInfo":
			return {
				...state,
				info: payload,
				loading: false,
			};

		case "changeLoading":
			return {
				...state,
				loading: payload,
			};
	}
};
