export enum ProductType {
	newItem = "新商品",
	renewal1 = "リニューアル（外装強化＆中味強化）",
	renewal2 = "リニューアル（外装強化のみ）",
	existingProductAdd = "既存品の色調/香調/容量等追加",
	existingProductOnlyComminication = "既存品のコミュニケーションのみ開発",
	other = "その他",
}

export const productTypeOptions = Object.entries(ProductType)
	.filter(([, value]) => {
		return isNaN(Number(value));
	})
	.map(([value, label]) => {
		return { value, label };
	});
