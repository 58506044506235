import * as React from "react";

import { SubTitle } from "../../../../../react-components/lu-component/src";
import type { AdCopyConceptWithInformation } from "../../../../../server/models/ad-copy/concept";
import type { ConceptNormUpdate, ConceptWithInformation } from "../../../../../server/models/concept";
import type { NormFlagsSearchValues } from "../../../reducers/norm-flags";
import { NormFlagsTable } from "../../parts/norm-flags-table";
import { NormSearchForm } from "../../parts/norm-search-form";
import { Pagination } from "../../parts/pagination";

type Option = {
	label: string;
	value: string;
};

type Props = {
	brandOptions: Option[];
	categoryOptions: Option[];
	concepts: (AdCopyConceptWithInformation | ConceptWithInformation)[];
	currentPageIndex: number;
	lastPageNum: number;
	onChangeSearchValue: <T extends keyof NormFlagsSearchValues>(name: T, value: NormFlagsSearchValues[T]) => void;
	onPaging: (page: number) => void;
	onReset: () => void;
	onSave: ({ conceptId, data }: { conceptId: string; data: ConceptNormUpdate }) => void;
	onSearch: () => void;
	searchValues: NormFlagsSearchValues;
};

export const NormFlagsPage = React.memo((props: Props) => {
	const {
		brandOptions,
		categoryOptions,
		concepts,
		currentPageIndex,
		lastPageNum,
		onChangeSearchValue,
		onPaging,
		onReset,
		onSave,
		onSearch,
		searchValues,
	} = props;

	return (
		<>
			<div className="h3">■ノームフラグ管理</div>

			<div>
				<SubTitle>検索</SubTitle>

				<div className="my-3">
					<NormSearchForm
						brandOptions={brandOptions}
						categoryOptions={categoryOptions}
						onChangeSearchValue={onChangeSearchValue}
						onReset={onReset}
						onSearch={onSearch}
						searchValues={searchValues}
					/>
				</div>
			</div>

			<Pagination
				//
				currentIndex={currentPageIndex}
				lastIndex={lastPageNum - 1}
				onPaging={onPaging}
			/>

			<div className="mt-3">
				<NormFlagsTable
					//
					concepts={concepts}
					onSave={onSave}
				/>
			</div>

			<Pagination
				//
				currentIndex={currentPageIndex}
				lastIndex={lastPageNum - 1}
				onPaging={onPaging}
			/>
		</>
	);
});
