import * as React from "react";

import type { AdCopyCorrectedValue } from "../../../../../../../../../server/models/ad-copy/cp";
import { AdCopyResultTableRow } from "./ad-copy-result-table-row";

// 推定値テーブル

export type AdCopySellPotentialValue = {
	label: string;
	sellPotential: AdCopyCorrectedValue;
};

type Props = {
	// goalSalesVolume: number;
	sellPotentials: AdCopySellPotentialValue[];
};

export const AdCopyResultTable = React.memo((props: Props) => {
	const {
		// goalSalesVolume,
		sellPotentials,
	} = props;

	const initValues = sellPotentials[0].sellPotential;
	const targetValues = sellPotentials.at(1)?.sellPotential;
	const correctionValues = sellPotentials.at(2)?.sellPotential;

	const hasCP = !!correctionValues;

	return (
		<table className="result-table">
			<thead>
				<tr>
					<th className="subtitle" rowSpan={2}>
						推定値
					</th>

					<th rowSpan={2}>初期値(万個)</th>

					<th colSpan={2}>①変数補整</th>

					{hasCP && <th colSpan={2}>②実績補整</th>}
				</tr>

				<tr>
					<th>補整値(万個)</th>

					<th>補整率(%)</th>

					{hasCP && (
						<>
							<th>補整値(万個)</th>

							<th>補整率(%)</th>
						</>
					)}
				</tr>
			</thead>

			<tbody>
				<AdCopyResultTableRow
					correctionValue={correctionValues?.trial}
					initValue={initValues.trial}
					label="トライアル"
					name="trial"
					targetValue={targetValues?.trial}
				/>

				<AdCopyResultTableRow
					correctionValue={correctionValues?.repeat}
					initValue={initValues.repeat}
					label="リピート"
					name="repeat"
					targetValue={targetValues?.repeat}
				/>

				<AdCopyResultTableRow
					correctionValue={correctionValues?.localOther}
					initValue={initValues.localOther}
					label="ローカルその他"
					name="localOther"
					targetValue={targetValues?.localOther}
				/>

				<AdCopyResultTableRow
					correctionValue={correctionValues?.inbound}
					initValue={initValues.inbound}
					label="インバウンド"
					name="inbound"
					targetValue={targetValues?.inbound}
				/>

				<AdCopyResultTableRow
					correctionValue={correctionValues?.total}
					initValue={initValues.total}
					label="合計"
					name="total"
					targetValue={targetValues?.total}
				/>

				{/* NOTE: コンセプトテストのやり方。コピーテストでは、目標対比もserver側の関数を使用して算出することとする */}
				{/* <AdCopyResultTableRow
					correctionValue={
						correctionValues?.total == null ? undefined : (correctionValues.total / goalSalesVolume) * 100 - 100
					}
					initValue={(initValues.total / goalSalesVolume) * 100 - 100}
					label="目標対比(%)"
					name="goal"
					targetValue={targetValues?.total == null ? undefined : (targetValues.total / goalSalesVolume) * 100 - 100}
				/> */}

				<AdCopyResultTableRow
					correctionValue={correctionValues?.toTargetSalesVolume}
					initValue={initValues.toTargetSalesVolume ?? 0}
					label="目標対比(%)"
					name="goal"
					targetValue={targetValues?.toTargetSalesVolume}
				/>
			</tbody>
		</table>
	);
});
