import { Category } from "../../category";
import { Price } from "../../shared";
import { BeautyTypeType } from "./beauty-type";

export type StrategyGroupCategory = {
	category: Category;
	surveyChoice: number;
	price?: Price;
	beautyType?: BeautyTypeType[];
	usedBrand?: string[]; // brandName
	unusedBrand?: string[]; // brandName
};
