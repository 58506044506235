/* eslint-disable react/jsx-no-useless-fragment */

import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import { strategyTargetGroupLabels } from "../../../../../../../label/activity";

import type { Option } from "../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../react-components/lu-component/src/index";
import { AccordionForm } from "../../../../parts/accodion-form";
import { foundationTypeOptions, StrategyGroup } from "../../../../../../../server/models/activity";

type Props = {
	onChange: (name: keyof StrategyGroup, value: any) => void;
	strategyGroup: StrategyGroup;
};

export const FoundationTypeCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	const shouldShow = React.useMemo(
		() => strategyGroup.category?.some((c) => c.category.type === "liquid-foundation"),
		[strategyGroup.category],
	);

	return (
		<Row>
			<FormGroup as={Col} md={5}>
				<AccordionForm
					defaultShow={strategyGroup.foundationType != null && 0 < strategyGroup.foundationType.length}
					eventKey="foundationType"
					title={strategyTargetGroupLabels.foundationType ?? ""}
				>
					{shouldShow ? (
						<Checkboxes
							checkeds={strategyGroup.foundationType ?? []}
							choices={
								// その他を除外する
								foundationTypeOptions.filter(({ value }) => !["4"].includes(value.toString())) as Option[]
							}
							name="foundationType"
							onChange={(value) => {
								onChange("foundationType", value);
							}}
						/>
					) : (
						<></>
					)}
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
