import * as React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";

import type { Choice } from "../../../../../../../react-components/lu-component/src/index";
import { Checkboxes } from "../../../../../../../react-components/lu-component/src/index";
import { AccordionForm } from "../../../../parts/accodion-form";
import { skinProblemFrequencyOptions, StrategyGroup } from "../../../../../../../server/models/activity";
import { strategyTargetGroupLabels } from "../../../../../../../label/activity";

type Props = {
	onChange: (name: keyof StrategyGroup, value: any) => void;
	strategyGroup: StrategyGroup;
};

export const SkinProblemFrequencyCheckboxes = React.memo((props: Props) => {
	const { onChange, strategyGroup } = props;

	return (
		<Row>
			<FormGroup as={Col} md={6}>
				<AccordionForm
					defaultShow={!!strategyGroup.skinProblemFrequency && strategyGroup.skinProblemFrequency.length !== 0}
					eventKey="skinProblemFrequency"
					title={strategyTargetGroupLabels.skinProblemFrequency ?? ""}
				>
					<Checkboxes
						checkeds={strategyGroup.skinProblemFrequency ?? []}
						choices={skinProblemFrequencyOptions as Choice[]}
						name="skinProblemFrequency"
						onChange={(value) => onChange("skinProblemFrequency", value)}
					/>
				</AccordionForm>
			</FormGroup>
		</Row>
	);
});
