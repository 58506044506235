import * as React from "react";

type Props = {
	goalSalesVolume: number;
};

// 判定基準テーブル

export const AdCopyGoalTable = React.memo((props: Props) => {
	const { goalSalesVolume } = props;

	return (
		<table
			className="result-table"
			style={{
				display: "inline-table",
				width: "56%",
			}}
		>
			<thead>
				<tr>
					<th>判定基準</th>

					<th>目標値(万個)</th>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td className="label-area">目標値</td>

					<td>{goalSalesVolume.toFixed(1)}</td>
				</tr>
			</tbody>
		</table>
	);
});
