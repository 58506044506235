import * as React from "react";

import { decimalCalc } from "../../../../../../../../../server/lib/ad-copy/aggregate/common";
import type { AdCopyCorrectedValue } from "../../../../../../../../../server/models/ad-copy/cp";
import { calcDiffPercent } from "../../common";

type Props = {
	correctionValue: number | undefined;
	initValue: number;
	label: string;
	name: keyof AdCopyCorrectedValue | "goal";
	targetValue: number | undefined;
};

export const AdCopyResultTableRow = React.memo((props: Props) => {
	const { correctionValue, initValue, label, name, targetValue } = props;

	const diff = targetValue == null ? NaN : calcDiffPercent(initValue, targetValue);

	const diffCorrectionValue = React.useMemo(() => {
		// NOTE: 呼び出す場所の関係で、NaN になることはない
		if (correctionValue == null) {
			return NaN;
		}

		if (targetValue != null) {
			return calcDiffPercent(targetValue, correctionValue);
		}

		return calcDiffPercent(initValue, correctionValue);
	}, [initValue, targetValue, correctionValue]);

	return (
		<tr>
			<td className={name}>{label}</td>

			{/* 初期値(万個) */}
			<td>{decimalCalc(initValue, 10).toFixed(1)}</td>

			{/* ①変数補整 */}
			{/* 補整値(万個) */}
			<td>{targetValue == null ? "-" : decimalCalc(targetValue, 10).toFixed(1)}</td>

			{/* 補整率(%) */}
			{name === "goal" ? (
				<td />
			) : (
				<td className={diff < 0 ? "minus" : "plus"}>{targetValue == null ? "-" : diff.toFixed(1)}</td>
			)}

			{/* ②実績補整 */}
			{correctionValue != null && (
				<>
					{/* 補整値(万個) */}
					<td>{decimalCalc(correctionValue, 10).toFixed(1)}</td>

					{/* 補整率(%) */}
					{name === "goal" ? (
						<td />
					) : (
						<td className={diffCorrectionValue < 0 ? "minus" : "plus"}>
							{isNaN(diffCorrectionValue) ? "-" : diffCorrectionValue.toFixed(1)}
						</td>
					)}
				</>
			)}
		</tr>
	);
});
