import dayjs from "dayjs";
import * as React from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import { AdCopyConceptTypeMap, type AdCopyConceptWithInformation } from "../../../../../server/models/ad-copy/concept";
import type { ConceptNormUpdate, ConceptWithInformation } from "../../../../../server/models/concept";
import { replaceEndpointUrl } from "../../../../../server/router/endpoint";
import { adCopyClientEndpoint } from "../../../routes/adCopyEndpoint";
import { clientEndpoint as conceptClientEndpoint } from "../../../routes/endpoint";

type Props = {
	concept: AdCopyConceptWithInformation | ConceptWithInformation;
	onSave: ({ conceptId, data }: { conceptId: string; data: ConceptNormUpdate }) => void;
};

export const NormFlagsTableRow = React.memo((props: Props) => {
	const { concept, onSave } = props;

	const activityId = concept.activity._id?.toString() ?? "";
	const jobId = concept.job._id?.toString() ?? "";

	const [disabled, setDisabled] = React.useState(
		concept.isNormTarget != null || (concept.normUpdateReason != null && concept.normUpdateReason !== ""),
	);

	const [formValues, setFormValues] = React.useState<ConceptNormUpdate>({
		isNormTarget: concept.isNormTarget,
		normUpdateReason: concept.normUpdateReason,
	});

	const clientEndpoint = concept.surveyType === "concept" ? conceptClientEndpoint : adCopyClientEndpoint;

	return (
		<tr className="align-middle text-center">
			{/* アクティビティNo. */}
			<td>
				<Link
					to={replaceEndpointUrl(clientEndpoint.activityCreate, {
						activityId,
					})}
				>
					{concept.activity.no}
				</Link>
			</td>

			{/* ジョブNo. */}
			<td>
				<Link
					to={replaceEndpointUrl(
						concept.surveyType === "concept"
							? conceptClientEndpoint.exectiveReport
							: adCopyClientEndpoint.resultByTargetReport,
						{
							activityId,
							jobId,
						},
					)}
				>
					{concept.job.jobNum}
				</Link>
			</td>

			{/* 調査終了日 */}
			<td>{dayjs(concept.job.endDatetime).format("YYYY/M/D")}</td>

			{/* 再調査 */}
			<td>{concept.job.retestJobId ? "1" : "0"}</td>

			{/* 商品 */}
			<td>
				<Link
					to={replaceEndpointUrl(clientEndpoint.jobDetail, {
						activityId,
						jobId,
					})}
				>
					{AdCopyConceptTypeMap[concept.type]}
				</Link>
			</td>

			{/* カテゴリ */}
			<td>{concept.categoryName}</td>

			{/* ブランド */}
			<td>{concept.brandName}</td>

			{/* ノーム対象 */}
			<td>
				<Form.Check
					checked={formValues.isNormTarget ?? false}
					disabled={disabled}
					onChange={() => {
						setFormValues({
							...formValues,
							isNormTarget: formValues.isNormTarget == null ? true : formValues.isNormTarget ? undefined : true,
						});
					}}
					style={{
						transform: "scale(1.5)",
					}}
				/>
			</td>

			{/* ノーム対象外 */}
			<td>
				<Form.Check
					checked={formValues.isNormTarget == null ? false : !formValues.isNormTarget}
					disabled={disabled}
					onChange={() => {
						setFormValues({
							...formValues,
							isNormTarget: formValues.isNormTarget == null ? false : formValues.isNormTarget ? false : undefined,
						});
					}}
					style={{
						transform: "scale(1.5)",
					}}
				/>
			</td>

			{/* 理由 */}
			<td>
				<Form.Control
					as="textarea"
					disabled={disabled}
					onChange={(e) => {
						setFormValues({
							...formValues,
							normUpdateReason: e.target.value,
						});
					}}
					size="sm"
					value={formValues.normUpdateReason}
				/>
			</td>

			{/* 保存 */}
			<td
				style={{
					minWidth: "4rem",
				}}
			>
				{disabled ? (
					<Button
						onClick={() => {
							setDisabled(false);
						}}
						size="sm"
						variant="outline-secondary"
					>
						編集
					</Button>
				) : (
					<Button
						onClick={() => {
							onSave({
								conceptId: concept._id?.toString() ?? "",
								data: formValues,
							});

							if (
								formValues.isNormTarget != null ||
								(formValues.normUpdateReason != null && formValues.normUpdateReason !== "")
							) {
								setDisabled(true);
							}
						}}
						size="sm"
						variant="outline-secondary"
					>
						保存
					</Button>
				)}
			</td>
		</tr>
	);
});
