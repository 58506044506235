/* eslint-disable @typescript-eslint/naming-convention */

/**
 * 202411より利用する情報
 */
export enum SkinAttributeV2 {
	"シミ・そばかす",
	"毛穴の目立ち",
	"頬のたるみ",
	"頬のしわ",
	"乾燥・かさつき・うるおいのなさ",
	"ごわつき",
	"皮むけ",
	"ぶつぶつ",
	"かゆみ",
	"かぶれ",
	"湿疹",
	"ただれ",
	"皮膚炎",
	"ニキビ・吹き出物",
	"くすみ",
	"透明感のなさ",
	"目の下のくま",
	"目元のしわ・たるみ",
	"肌荒れ",
	"脂っぽさ・テカリ・べたつき",
	"きめの粗さ",
	"つやのなさ",
	"頬の赤み",
	"色むら",
	"アトピー/肌のアレルギー",
	"花粉/PM2.5/ハウスダストなどによる肌荒れ",

	"その他",
}
