import * as React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import styled from "styled-components";

import type { ErrorObject } from "../../../../../../../../react-components/lu-component/src/index";
import type { AdCopyStrategyGroupCategory } from "../../../../../../../../server/models/ad-copy/activity";
import type { Category } from "../../../../../../../../server/models/category";
import type { Gender } from "../../../../../../../../server/models/shared";
import type { TrueAdCopyStrategyGroup } from "../../../../../../types/ad-copy/activity";
import { AdCopyAestheticMedicineExperienceCheckboxes } from "./aesthetic-medicine-experience-checkboxes";
import { AdCopyAgeSelect } from "./age-select";
import { AdCopyBeautyTypeForms } from "./beauty-type-forms";
import { AdCopyCategoryForm } from "./category-form";
import { AdCopyChannelCheckboxes } from "./channel-checkboxes";
import { AdCopyEyeshadowTypeCheckboxes } from "./eyeshadow-type-checkboxes";
import { AdCopyFacewashFunctionRadios } from "./facewash-function-radios";
import { AdCopyFoundationTypeCheckboxes } from "./foundation-type-checkboxes";
import { AdCopyGypsyForms } from "./gypsy-forms";
import { AdCopyLipstickTypeCheckboxes } from "./lipstick-type-checkboxes";
import { AdCopyMarriedChildrenOccupationForm } from "./married-children-occupation-form";
import { AdCopyNonPurchaseCategoryConfigForm } from "./non-purchase-category-config-form";
import { AdCopyPriceForms } from "./price-forms";
import { AdCopySensitiveSkinCheckboxes } from "./sensitive-skin-checkboxes";
import { AdCopySensitiveSkinFrequencyCheckboxes } from "./sensitive-skin-frequency-checkboxes";
import { AdCopySkinAttributeCheckboxes } from "./skin-attribute-checkboxes";
import { AdCopySkinProblemFrequencyCheckboxes } from "./skin-problem-frequency-checkboxes";
import { AdCopyUnusedBrandForms } from "./unused-brand-forms";
import { AdCopyUsedBrandForms } from "./used-brand-forms";
import { BlueText, RedText } from "./utils";

const FormArea = styled.div`
	& .form-label {
		margin-bottom: 0.1em;
	}
	& .form-group {
		margin-bottom: 0.2em;
	}
`;

type Props = {
	categories: Category[];
	errors: ErrorObject | undefined;
	gender: keyof typeof Gender | undefined;
	onChange: <T extends keyof TrueAdCopyStrategyGroup>(name: T, value: TrueAdCopyStrategyGroup[T]) => void;
	strategyGroup: TrueAdCopyStrategyGroup;
};

export const AdCopyStrategicTargetForm = React.memo((props: Props) => {
	const { categories, errors, gender, onChange, strategyGroup } = props;

	const surveyChoiceOptions = React.useMemo(
		() => categories.flatMap((c) => (c.gender === gender ? c.adCopySurveyChoice : [])),
		[categories, gender],
	);

	const categoryMap = React.useMemo<Record<string, Category>>(
		() => Object.fromEntries(categories.map((c) => [c._id?.toString(), c])),
		[categories],
	);

	const surveyChoiceMap = React.useMemo(
		() =>
			new Map<number, string>(
				categories
					.filter((c) => c.gender === gender)
					.flatMap((c) => c.adCopySurveyChoice)
					.map((sc) => [sc.value, sc.label]),
			),
		[categories, gender],
	);

	const onChangeStrategyGroupCategory = React.useCallback(
		<T extends keyof AdCopyStrategyGroupCategory>(
			surveyChoice: number,
			name: T,
			value: AdCopyStrategyGroupCategory[T],
		) => {
			onChange(
				"category",
				strategyGroup.category?.map((c) => (c.surveyChoice === surveyChoice ? { ...c, [name]: value } : c)),
			);
		},
		[onChange, strategyGroup.category],
	);

	return (
		<FormArea>
			<Row>
				<Col md={{ offset: 2, span: 8 }}>
					<Alert variant="warning">
						<li>未選択の場合は、条件不問になります。</li>

						<li>
							設問間はすべて<RedText>and</RedText>条件になります。
						</li>

						<li>
							選択肢間はすべて<BlueText>or</BlueText>条件になります。
						</li>

						<span>※ 「過去１年購入&使用カテゴリ」「過去1年非購入カテゴリ」は</span>

						<BlueText>or</BlueText>

						<span>/</span>

						<RedText>and</RedText>

						<span>を選べます</span>
					</Alert>
				</Col>
			</Row>

			{/* 年齢 */}
			<AdCopyAgeSelect errors={errors} onChange={onChange} value={strategyGroup.age} />

			{/* 未既婚、子ども年齢、職業 */}
			<AdCopyMarriedChildrenOccupationForm onChange={onChange} strategyGroup={strategyGroup} />

			{/* 肌悩み */}
			<AdCopySkinAttributeCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 敏感肌① */}
			<AdCopySensitiveSkinCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 敏感肌② */}
			{/* 敏感肌頻度 */}
			<AdCopySensitiveSkinFrequencyCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 肌トラブル頻度 */}
			<AdCopySkinProblemFrequencyCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 過去１年購入&使用カテゴリ */}
			<AdCopyCategoryForm
				categories={categories}
				errors={errors}
				gender={gender}
				onChange={onChange}
				strategyGroup={strategyGroup}
				surveyChoiceOptions={surveyChoiceOptions}
			/>

			{/* 過去1年非購入カテゴリ */}
			<AdCopyNonPurchaseCategoryConfigForm
				errors={errors}
				onChange={onChange}
				strategyGroup={strategyGroup}
				surveyChoiceOptions={surveyChoiceOptions}
			/>

			<Row style={{ fontSize: "1.1em", marginTop: "10px" }}>
				<Col md={12}>
					以下項目は「過去１年購入&使用カテゴリ」を選択すると設定可能です。
					<hr />
				</Col>
			</Row>

			{/* 価格帯（税抜き） */}
			<AdCopyPriceForms
				errors={errors}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/>

			{/* 購入チャネル */}
			<AdCopyChannelCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* カテゴリタイプ（スキンケアのみ） */}
			{/* 美類タイプ */}
			<AdCopyBeautyTypeForms
				categoryMap={categoryMap}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/>

			{/* 洗顔機能 */}
			{/* face wash or cleansing */}
			<AdCopyFacewashFunctionRadios categoryMap={categoryMap} onChange={onChange} strategyGroup={strategyGroup} />

			{/* ファンデーションタイプ */}
			{/* liquid foundation type */}
			<AdCopyFoundationTypeCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 口紅・リップグロス使用カテゴリ */}
			<AdCopyLipstickTypeCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* アイシャドー使用カテゴリ */}
			<AdCopyEyeshadowTypeCheckboxes onChange={onChange} strategyGroup={strategyGroup} />

			{/* 過去1年購入＆使用ブランド */}
			<AdCopyUsedBrandForms
				categoryMap={categoryMap}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/>

			{/* 過去1年非購入ブランド */}
			<AdCopyUnusedBrandForms
				categoryMap={categoryMap}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/>

			{/* NOTE: 本当になくなったら消す */}
			{/* 現在使用ブランド */}
			{/* <AdCopyCurrentlyUsedBrandForms
				categoryMap={categoryMap}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/> */}

			{/* 現在使用していないブランド */}
			{/* <AdCopyCurrentlyUnusedBrandForms
				categoryMap={categoryMap}
				onChangeStrategyGroupCategory={onChangeStrategyGroupCategory}
				strategyGroup={strategyGroup}
				surveyChoiceMap={surveyChoiceMap}
			/> */}

			{/* ジプシー */}
			<AdCopyGypsyForms onChange={onChange} strategyGroup={strategyGroup} surveyChoiceOptions={surveyChoiceOptions} />

			{/* 美容医療利用経験 */}
			<AdCopyAestheticMedicineExperienceCheckboxes
				onChange={onChange}
				value={strategyGroup.aestheticMedicineExperience}
			/>
		</FormArea>
	);
});
