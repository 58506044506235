import * as React from "react";

import { adCopyCategoryOrAndOptions } from "../../../../../../../../server/models/ad-copy/activity";
import { BlueText, RedText } from "./utils";

export const adCopyOrAndOptions = adCopyCategoryOrAndOptions.map(({ label, value }) => {
	const orOrAnd = value.toLowerCase();

	const affixes = label.split(orOrAnd);

	const ColorSpan = value === "Or" ? BlueText : RedText;

	return {
		label: (
			<>
				<span>{affixes.at(0)}</span>

				<ColorSpan>「{orOrAnd}」</ColorSpan>

				<span>{affixes.at(1)}</span>
			</>
		),
		value,
	};
});
