/* eslint-disable react/jsx-newline */

import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";

import type { Option } from "../../../../../../../react-components/lu-component/src/index";
import { AdCopyRole } from "../../../../../../../server/lib/ad-copy/permission/role";
import type { AccountWithoutPassword } from "../../../../../../../server/models/account";
import type { AdCopyActivityWithBrand } from "../../../../../../../server/models/ad-copy/activity";
import { Help } from "../../../../parts/help";
import { MultipleSelect } from "../../../../parts/select";
import { AdCopyActivityPanel } from "../../report/outline/activity-panel";
import { AdCopyTargetPanel } from "../../report/outline/test-panel/target-panel";

type Props = {
	accounts: AccountWithoutPassword[];
	activity: AdCopyActivityWithBrand;
	activityMembers: string[];
	hasDemandForecastNeeded: boolean;
	loginAccount: AccountWithoutPassword;
	onChange: (values: string[]) => void;
	onSubmit: () => void;
};

export const AdCopyActivityMemberPage = React.memo((props: Props) => {
	const { accounts, activity, activityMembers, hasDemandForecastNeeded, loginAccount, onChange, onSubmit } = props;

	// 担当アカウントで絞り込み。
	const brandholders = React.useMemo<Option[]>(() => {
		if (!activity.brandId) {
			return [];
		}

		return accounts
			.filter(
				(account) =>
					!!account.brand &&
					account.brand.includes(activity.brandId) &&
					// loginAccount._id !== account._id && 自分を表示しないと外れてしまうので、コメントアウト
					AdCopyRole.isBrander(account),
			)
			.map((account) => {
				return {
					label: account.name,
					value: account._id?.toString() ?? "",
				};
			});
	}, [activity.brandId, accounts]);

	const hasEditRole = React.useMemo(
		() => AdCopyRole.hasAcitivityEditRole(loginAccount, activity),
		[loginAccount, activity],
	);

	return (
		<>
			<div className="m-3">
				<div className="h3">■アクティビティ詳細</div>
			</div>

			<AdCopyActivityPanel activity={activity} />

			<AdCopyTargetPanel activity={activity} shouldShowCategoryUser={hasDemandForecastNeeded} />

			<div className="m-3">
				<div className="h3">■アクティビティ登録者</div>
			</div>

			<Row className="m-3">
				<Col md={{ offset: 1, span: 4 }}>
					アクティビティ登録者（複数選択可能）
					<Help
						explain="当アクティビティとそれに紐づくJOBを登録、更新、申請できるメンバーを選択してください。"
						id="accountIds"
					/>
					<MultipleSelect
						onChange={onChange as React.ComponentProps<typeof MultipleSelect>["onChange"]}
						options={brandholders}
						value={activityMembers}
					/>
				</Col>
			</Row>

			<Row className="m-4">
				<Col md={{ offset: 2, span: 2 }}>
					{hasEditRole ? (
						<Button className="w-100" onClick={onSubmit} variant="secondary">
							更新
						</Button>
					) : (
						"アクティビティ登録者を更新できません。"
					)}
				</Col>
			</Row>
		</>
	);
});
