import * as React from "react";

import {
	calcEstimatedValue,
	calcEstimatedValueCP,
	hasAdCopyCorrectionValues,
} from "../../../../../../../../server/lib/ad-copy/common";
import type {
	AdCopySimulationBrandData,
	AdCopySimulationPredictionData,
} from "../../../../../../../../server/types/request/ad-copy/report/simulation";
import type { StandardLine, StokedBarGroup } from "../../../../../parts/graph/stocked-bar-graph";
import { StockedBarGraph } from "../../../../../parts/graph/stocked-bar-graph";
import { AppearanceByStrategyTargetTable } from "./appearance-by-strategy-target-table";
import { AdCopyCPCorrectionValueTable } from "./cp-correction-value";
import { AdCopyGoalTable } from "./goal-table";
import type { AdCopySellPotentialValue } from "./result-table";
import { AdCopyResultTable } from "./result-table";
import { AdCopyUserTable } from "./user-table";

type Props = {
	appearanceByStrategyTarget: number;
	constValue: AdCopySimulationBrandData;
	data: AdCopySimulationPredictionData;
	goalSalesVolume: number;
	hasCP: boolean;
	isExistingBrand: boolean;
	onsData: AdCopySimulationBrandData;
	trialSalesVolumeBeforeCorrectionByCurrentProduct: number | undefined;
};

export const AdCopySimulationResult = React.memo((props: Props) => {
	const {
		appearanceByStrategyTarget,
		constValue,
		data,
		goalSalesVolume,
		hasCP,
		isExistingBrand,
		onsData,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	} = props;

	const { correctionValue, cpCorrectionValue, initSimulationData } = data;

	const sellPotentials = React.useMemo<AdCopySellPotentialValue[]>(() => {
		const correctedValue = calcEstimatedValue(
			data.simulationBaseData,
			correctionValue,
			trialSalesVolumeBeforeCorrectionByCurrentProduct,
		);

		const ret: AdCopySellPotentialValue[] = [
			{
				label: "初期値（万個）",
				sellPotential: {
					// トライアル
					trial: initSimulationData.trialSalesVolumeAddUnreported,

					// リピート
					repeat: initSimulationData.repeatSalesVolumeAddUnreported,

					// ローカルその他
					localOther: initSimulationData.localOtherSalesVolume,

					// インバウンド
					inbound: initSimulationData.inboundBuyerSalesVolume,
					// 合計
					total: initSimulationData.potentialSalesVolume,

					// 目標対比(%)
					toTargetSalesVolume: initSimulationData.ratioToTargetSalesVolume,
				},
			},
			{
				label: "補整値（万個）",
				sellPotential: hasAdCopyCorrectionValues(correctionValue)
					? correctedValue
					: ({
							inbound: undefined,
							localOther: undefined,
							repeat: undefined,
							total: undefined,
							trial: undefined,
					  } as any),
			},
		];

		if (hasCP) {
			ret.push({
				label: "実績補正",
				sellPotential: calcEstimatedValueCP(correctedValue, cpCorrectionValue, goalSalesVolume),
			});
		}

		return ret;
	}, [
		correctionValue,
		cpCorrectionValue,
		data.simulationBaseData,
		goalSalesVolume,
		hasCP,
		initSimulationData.inboundBuyerSalesVolume,
		initSimulationData.localOtherSalesVolume,
		initSimulationData.potentialSalesVolume,
		initSimulationData.ratioToTargetSalesVolume,
		initSimulationData.repeatSalesVolumeAddUnreported,
		initSimulationData.trialSalesVolumeAddUnreported,
		trialSalesVolumeBeforeCorrectionByCurrentProduct,
	]);

	const salesData = React.useMemo<StokedBarGroup[]>(() => {
		return sellPotentials.map(({ label, sellPotential }) => {
			return {
				bars: [
					{ color: "#f56302", label: "トライアル", value: sellPotential.trial },
					{ color: "#ffc000", label: "リピート", value: sellPotential.repeat },
					{ color: "#83db4d", label: "ローカルその他", value: sellPotential.localOther },
					{ color: "#6492e3", label: "インバウンド", value: sellPotential.inbound },
				],
				label,
				total: sellPotential.total,
			};
		});
	}, [sellPotentials]);

	const lines = React.useMemo<StandardLine[]>(() => {
		return [
			{
				color: "red",
				label: "目標値（万個）",
				value: goalSalesVolume,
				width: 2,
			},
		];
	}, [goalSalesVolume]);

	const graphBase = React.useMemo(() => {
		const tmp = Math.max(...salesData.map((d) => d.bars.reduce((a, b) => a + (b.value || 0), 0)), goalSalesVolume);

		if (tmp < 10) {
			const max = Math.floor(tmp + 1);
			const step = Math.floor((max / 10) * 100) / 100;

			return { max, step };
		}
		const max = Math.ceil(Math.round(tmp / 10) + 1) * 10;
		let step = Math.ceil(max / 10);

		if (step > 5 && step < 10) {
			step = 10;
		} else if (step % 10 !== 0) {
			step = Math.ceil(step / 10) * 10;
		}

		return { max, step };
	}, [goalSalesVolume, salesData]);

	return (
		<table className="wrap-table">
			<tbody>
				<tr>
					<td className="table-area">
						{/* 戦略ターゲット出現率テーブル */}
						<AppearanceByStrategyTargetTable appearanceByStrategyTarget={appearanceByStrategyTarget} />

						{/* 基人口、購入意向ワンナンバースコア、定数テーブル */}
						<AdCopyUserTable
							constValue={constValue}
							isExistingBrand={isExistingBrand}
							onsData={onsData}
							simulationReferenceData={data.initSimulationData.referenceData}
						/>

						{/* 判定基準テーブル */}
						<AdCopyGoalTable goalSalesVolume={goalSalesVolume} />

						{/* 実数補正係数テーブル */}
						{hasCP && <AdCopyCPCorrectionValueTable cpCorrectionValue={data.cpCorrectionValue} />}

						{/* 推定値テーブル */}
						<AdCopyResultTable
							//
							// goalSalesVolume={goalSalesVolume}
							sellPotentials={sellPotentials}
						/>
					</td>

					{/* グラフ */}
					<td>
						<StockedBarGraph
							barGroups={salesData}
							height={410}
							hidePrecedent
							lines={lines}
							max={graphBase.max}
							step={graphBase.step}
							unit="（年間数量：単位　万個）"
							width={500}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
});
